import { createRouter, createWebHistory } from "vue-router";
import HomePage from "../views/HomePage.vue";
import LoginPage from "../views/LoginPage.vue";
import WelinePage from "../views/WelinePage.vue";
import LotteryPage from "../views/LotteryPage.vue";
import LotteryKillRatePage from "../views/LotteryKillRatePage.vue";
import ManagementPage from "../views/ManagementPage.vue";
import LogDataPage from "../views/WelineLogPage.vue";
import NotFoundPage from "../views/NotFoundPage.vue";
import LotteryToolPage from "../views/LotteryToolPage.vue";
import AddManagerPage from "../views/AddManagerPage.vue";
import WelineToolPage from "../views/WelineToolPage.vue";
import PushPlanManagementPage from "../views/PushPlanManagementPage.vue";
import ErrorImagePage from "../views/ErrorImagePage.vue";
import VerifyLotteryOpenResultPage from "../views/VerifyLotteryOpenResultPage.vue";
import FrontPage from "../views/FrontPage.vue";
import GamePage from "../views/GamePage.vue";

// 路由設定
const routes = [
  // 處理不存在的 router path 重新轉跳頁面
  {
    path: "/:catchAll(.*)",
    redirect: "/404",
  },
  {
    path: "/",
    redirect: "/front/home",
  },
  // redirect 404 page
  {
    path: "/404",
    name: "/404",
    component: NotFoundPage,
  },
  {
    path: "/login",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/home",
    name: "HomePage",
    component: HomePage,
    meta: {
      requiresAuth: true, // 添加需要验证登录的标记
      title: "首頁",
    },
  },
  {
    path: "/weline",
    name: "WelinePage",
    component: WelinePage,
    meta: {
      requiresAuth: true, // 添加需要验证登录的标记
      title: "微聊自動化",
    },
  },
  {
    path: "/lottery",
    name: "LotteryPage",
    component: LotteryPage,
    meta: {
      requiresAuth: true, // 添加需要验证登录的标记
    },
  },
  {
    path: "/lotteryKill",
    name: "LotteryKillRatePage",
    component: LotteryKillRatePage,
    meta: {
      requiresAuth: true, // 添加需要验证登录的标记
      title: "彩票殺率",
    },
  },
  {
    path: "/lotteryTool",
    name: "LotteryToolPage",
    component: LotteryToolPage,
    meta: {
      requiresAuth: true, // 添加需要验证登录的标记
      title: "XX工具",
    },
  },
  {
    path: "/verifyLotteryOpenResult",
    name: "VerifyLotteryOpenResultPage",
    component: VerifyLotteryOpenResultPage,
    meta: {
      requiresAuth: true, // 添加需要验证登录的标记
      title: "驗證彩票開獎結果",
    },
  },
  {
    path: "/management",
    name: "ManagementPage",
    component: ManagementPage,
    meta: {
      requiresAuth: true, // 添加需要验证登录的标记
      title: "管理列表",
    },
  },
  {
    path: "/addManager",
    name: "AddManagerPage",
    component: AddManagerPage,
    meta: {
      requiresAuth: true, // 添加需要验证登录的标记
      title: "添加人員管理",
    },
  },
  {
    path: "/welineLog",
    name: "LogDataPage",
    component: LogDataPage,
    meta: {
      requiresAuth: true, // 添加需要验证登录的标记
    },
  },
  {
    path: "/welineTool",
    name: "WelineToolPage",
    component: WelineToolPage,
    meta: {
      requiresAuth: true, // 添加需要验证登录的标记
      title: "微聊工具",
    },
  },
  {
    path: "/pushPlanManagement",
    name: "PushPlanManagementPage",
    component: PushPlanManagementPage,
    meta: {
      requiresAuth: true, // 添加需要验证登录的标记
      title: "推送計畫",
    },
  },
  {
    path: "/game",
    name: "GamePage",
    component: GamePage,
    meta: {
      requiresAuth: true, // 添加需要验证登录的标记
      title: "遊戲自動化",
    },
  },
  {
    path: "/front/home",
    name: "FrontPage",
    component: FrontPage,
    meta: {
      title: "最美河川",
    },
  },
  {
    path: "/errorImage",
    name: "ErrorImagePage",
    component: ErrorImagePage,
    meta: {
      requiresAuth: true, // 添加需要验证登录的标记
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// 驗證登入 token 失效方法
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isAuthenticated = localStorage.getItem("token"); // 假设用户登录状态保存在 localStorage 中
  if (requiresAuth && !isAuthenticated) {
    // 如果需要验证登录，但用户未登录，则跳转到登录页面
    localStorage.setItem("token", "");
    next("/login");
  } else {
    document.title = to.meta.title || "自動化測試後台";
    next(); // 否则正常跳转到目标页面
  }
});

export default router;
