<template>
  <Sidebar />
  <div class="title">XX工具</div>
  <NavBar />
  <div class="container-row">
    <div class="card setting-height-650 setting-width-505">
      <div class="header">
        <div class="title-h2 setting-proxy-mgn">代理線註冊工具</div>
        <button class="btn-gear" @click="displayShowRebateConfig">
          <i class="fa fa-gear"></i>
        </button>
      </div>

      <div class="card-body">
        <div class="submit-form-inner setting-height-570 setting-widt-480">
          <form>
            <div class="container-row">
              <div class="form-group">
                <input
                  type="text"
                  class="form-util-control"
                  id="domain"
                  placeholder="域名"
                  v-model="domain"
                />
              </div>
              <small class="lable-text">域名</small>
            </div>
            <div class="container-row">
              <div class="form-group">
                <input
                  type="text"
                  class="form-util-control"
                  id="father_username"
                  placeholder="上層帳號"
                  v-model="father_username"
                />
              </div>
              <small class="lable-text">上層帳號</small>
            </div>
            <div class="container-row">
              <div class="form-group">
                <input
                  type="text"
                  class="form-util-control"
                  id="father_password"
                  placeholder="上層密碼"
                  v-model="father_password"
                />
              </div>
              <small class="lable-text">上層密碼</small>
            </div>
            <div class="container-row">
              <div class="form-group">
                <input
                  type="text"
                  class="form-util-control"
                  id="username"
                  placeholder="帳號 (長度介於4-6之間)"
                  v-model="username"
                  @input="checkUsername"
                />
              </div>
              <small class="lable-text">帳號</small>
            </div>
            <div class="container-row">
              <div class="form-group">
                <input
                  type="text"
                  class="form-util-control"
                  id="password"
                  placeholder="密碼"
                  v-model="password"
                />
              </div>
              <small class="lable-text">密碼</small>
            </div>
            <div class="container-row">
              <div class="form-group">
                <input
                  type="text"
                  class="form-util-control"
                  id="invite-code"
                  placeholder="上層邀請碼"
                  v-model="inviteCode"
                  @input="checkInvite"
                />
              </div>
              <small class="lable-text">上層邀請碼</small>
            </div>
            <div class="container-row">
              <div class="form-group">
                <input
                  type="text"
                  class="form-util-control"
                  id="rebate"
                  placeholder="每層代理返點 (ex: 8,7,6,5)"
                  v-model="rebate"
                />
              </div>
              <small class="lable-text">返點</small>
            </div>
            <div class="container-row">
              <div class="form-group">
                <input
                  type="text"
                  class="form-util-control"
                  id="agent-layers"
                  placeholder="代理層數 (ex: 4)"
                  v-model="agentLayers"
                />
              </div>
              <small class="lable-text">代理層數</small>
            </div>
            <div class="container-row">
              <div class="form-group">
                <input
                  type="text"
                  class="form-util-control"
                  id="branch"
                  placeholder="個別分支同層代理數量"
                  v-model="branch"
                  @focus="showLabel.branch = true"
                  @blur="showLabel.branch = false"
                />
              </div>
              <small class="lable-text">分支層數</small>
            </div>
            <div class="container2">
              <button
                type="submit"
                class="btn btn-confirm btn-margin-3"
                @click.prevent="createProxyLine"
              >
                確認
              </button>
              <button
                class="btn btn-info btn-margin-4"
                @click.prevent="create_proxy_status"
              >
                狀態
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="card setting-height-570 setting-width-505">
      <div class="header">
        <div class="title-h2 setting-proxy-mgn">說明</div>
      </div>
      <div class="card-body">
        <div class="submit-form-inner setting-height-490 setting-widt-480">
          <ul class="list-group">
            <li class="list-group-item">
              1.帳號長度不超過 6, 原因是產出帳號會有後贅詞, 舉例 jojobb 為帳號,
              創建出來的帳號會長成 jojobb01colbr1
            </li>
            <li class="list-group-item">
              2."代理層數"需與"每層代理返點"數量一致, 例如: 每層代理返點=8,7,6,5
              你的代理層數=4
            </li>
            <li class="list-group-item">3."代理層數"代表垂直的代理深度</li>
            <li class="list-group-item">
              4."每層代理返點"返點數必須由大到小遞減, 不可出現 8,4,6,3
              這種奇怪類型
            </li>
            <li class="list-group-item">
              5."域名"欄位開頭須為 http , 結尾 com
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <transition name="modal-showRebateConfig">
    <div class="modal" v-if="showRebateConfig">
      <div>
        <div v-if="showRebateConfig" class="modal">
          <div class="modal2-content modal-content-size-36">
            <span
              @click="closeDisplayShowRebateConfig"
              class="btn-right-top-close"
              >&times;</span
            >
            <h3>返點設定檔</h3>
            <table class="rebate-config-table">
              <thead>
                <tr>
                  <th>彩種</th>
                  <th>返點</th>
                  <th>刪除</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="lottery_rebate in lottery_rebate_ls"
                  :key="lottery_rebate.id"
                >
                  <td>
                    <input
                      class="rebate-box"
                      name="lotteryName"
                      type="text"
                      v-model="lottery_rebate.name"
                    />
                  </td>
                  <td>
                    <input
                      class="times-box"
                      name="rebate"
                      type="text"
                      v-model="lottery_rebate.rebate"
                    />
                  </td>
                  <td>
                    <button
                      class="btn-delete"
                      @click="deleteProxyConfig(lottery_rebate.id)"
                    >
                      刪除
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

            <button
              class="btn-tips-close"
              @click="closeDisplayShowRebateConfig"
            >
              關閉
            </button>
            <button class="btn-tips-update" @click="settingProxyConfig">
              更新
            </button>
            <button class="btn-tips-update" @click="displayShowAddRebateConfig">
              新增
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal-showAddRebateConfig">
    <div class="modal" v-if="showAddRebateConfig">
      <div>
        <div v-if="showAddRebateConfig" class="modal">
          <div class="modal-content">
            <span @click="closeAddRebateConfig" class="btn-right-top-close"
              >&times;</span
            >
            <h3>新增彩種返點</h3>
            <div class="addPushPlan-container">
              <div class="filter">
                <label for="lottery_name">彩種</label>
                <input
                  class="search-box"
                  type="text"
                  placeholder="彩種名稱"
                  id="lottery_name"
                  v-model="lottery_name"
                />
              </div>
              <div class="filter">
                <label for="rebate_config">返點</label>
                <input
                  class="search-box"
                  type="text"
                  id="rebate_config"
                  v-model="rebate_config"
                />
              </div>
            </div>

            <button class="btn-tips-close" @click="closeAddRebateConfig">
              關閉
            </button>
            <button class="btn-tips-submit" @click="updateProxyConfig">
              新增
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <div class="loading-overlay-two" v-if="loading">
    <div class="spinner"></div>
  </div>
</template>

<script>
import Sidebar from "@/components/sidebar/SidebarCom";
import NavBar from "@/components/navBar/ComNavButtons";
import Swal from "sweetalert2";
import {
  handelSuccessAlertUtil,
  handelErrorAlertUtil,
  handelFailAlertUtil,
} from "@/commonUtils.js";

export default {
  // 綁定元件
  components: { Sidebar, NavBar },
  data() {
    return {
      loading: false,
      domain: "",
      father_username: "",
      father_password: "",
      username: "",
      password: "",
      inviteCode: "",
      rebate: "",
      rebate_config: "",
      agentLayers: "",
      branch: "",
      lottery_rebate_ls: [],
      showRebateConfig: false,
      showAddRebateConfig: false,
      showLabel: {
        domain: false,
        username: false,
        password: false,
        inviteCode: false,
        rebate: false,
        agentLayers: false,
        branch: false,
      },
    };
  },
  created() {},
  methods: {
    checkDomain() {
      if (this.domain === "") return;
      if (!/.*com$/.test(this.domain)) {
        Swal.fire({
          title: "warning",
          text: "domain 欄位內容必須是開頭 http:// , 結尾 com",
          icon: "warning",
          confirmButtonText: "確定",
        });
        // 重置 domain 的值為空字串
        this.domain = "";
        return;
      }
    },

    checkUsername() {
      if (this.username === "") return;
      if (this.username.length > 6) {
        Swal.fire({
          title: "warning",
          text: "帳號長度不可超過 6",
          icon: "warning",
          confirmButtonText: "OK",
        });
        // 重置 username 的值為空字串
        this.username = "";
        return;
      }
    },

    checkInvite() {
      if (this.inviteCode === "") return;
      const reg = /^\d+$/;
      if (!reg.test(this.inviteCode)) {
        // 若不是純數字，彈出提示框
        Swal.fire({
          title: "warning",
          text: "邀請碼只能輸入數字",
          icon: "warning",
          confirmButtonText: "OK",
        });
        // 重置 inviteCode 的值為空字串
        this.inviteCode = "";
        return;
      }
    },
    // 確認代理線狀態
    create_proxy_status() {
      const task_id = localStorage.getItem("task_id");
      this.axios
        .post("/v1/api/create_proxy_status", {
          task_id: task_id,
        })
        .then((response) => {
          const status = response.data.status;
          const task_id = response.data.task_id;

          // 在這裡處理成功回應的邏輯
          if (status === "1") {
            Swal.fire({
              title: "Done !",
              text: "創建代理線完成", // 顯示回應內容
              icon: "success",
              confirmButtonText: "OK",
            });
          } else if (status === "-1") {
            localStorage.setItem("task_id", task_id);
            Swal.fire({
              title: "Fail !",
              text: JSON.stringify(response.data), // 顯示回應內容
              icon: "error",
              confirmButtonText: "OK",
            });
          } else if (status === "0") {
            localStorage.setItem("task_id", task_id);
            Swal.fire({
              title: "Pending !",
              text: "處理中, 請稍候", // 顯示回應內容
              icon: "info",
              confirmButtonText: "OK",
            });
          } else {
            // localStorage.removeItem("task_id");
            Swal.fire({
              title: "Exception !",
              text: JSON.stringify(response.data, null, 2), // 顯示回應內容
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        })
        .catch((error) => {
          // 在這裡處理錯誤回應的邏輯
          Swal.fire({
            title: "Exception !",
            text: JSON.stringify(error.data), // 顯示回應內容
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    },

    // 創建代理線
    createProxyLine() {
      const url = "/v1/api/create_proxy_account";
      const dict = {
        platform_front_domain: this.domain,
        father_username: this.father_username,
        father_password: this.father_password,
        new_username: this.username,
        password: this.password,
        inviteCode: this.inviteCode,
        rebate: this.rebate,
        start_index: "1", // default
        end_index: this.agentLayers,
        branch: this.branch,
        type: "col",
      };
      this.axios
        .post(url, dict)
        .then((response) => {
          const status = response.data.status;
          const task_id = response.data.task_id;

          // 在這裡處理成功回應的邏輯
          if (status === "1") {
            localStorage.setItem("task_id", task_id);
            Swal.fire({
              title: "Success !",
              text: "收到你的任務請求", // 顯示回應內容
              icon: "success",
              confirmButtonText: "OK",
            });
          }
          // 在這裡處理失敗回應的邏輯
          else if (status === "-1") {
            localStorage.setItem("task_id", task_id);
            handelFailAlertUtil(response);
          } else {
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          // 在這裡處理錯誤回應的邏輯
          handelErrorAlertUtil(error);
        });
    },
    // 獲取代理線設定檔
    fetchProxyConfig() {
      this.loading = true;
      const url = "/v1/api/fetch_proxy_config";
      const dict = {};
      this.axios
        .post(url, dict)
        .then((response) => {
          const status = response.data.status;
          const result = response.data.result;

          // 在這裡處理成功回應的邏輯
          if (status === "1") {
            this.lottery_rebate_ls = result;
          }
          // 在這裡處理失敗回應的邏輯
          else if (status === "-1") {
            handelFailAlertUtil(response);
          } else {
            // localStorage.removeItem("task_id");
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          // 在這裡處理錯誤回應的邏輯
          handelErrorAlertUtil(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    submitUtil(url, dict) {
      this.axios
        .post(url, dict)
        .then((response) => {
          const status = response.data.status;

          // 在這裡處理成功回應的邏輯
          if (status === "1") {
            handelSuccessAlertUtil(response);
          } else if (status === "-1") {
            handelFailAlertUtil(response);
          } else {
            // localStorage.removeItem("task_id");
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          // 在這裡處理錯誤回應的邏輯
          handelErrorAlertUtil(error);
        });
    },
    settingProxyConfig() {
      const dict = {
        update_info: this.lottery_rebate_ls,
      };
      const url = "/v1/api/update_lottery_rebate_config";
      this.submitUtil(url, dict);
    },
    deleteProxyConfig(config_id) {
      const dict = {
        config_id: config_id,
      };
      const url = "/v1/api/delete_lottery_rebate_config";
      this.axios
        .post(url, dict)
        .then((response) => {
          const status = response.data.status;

          // 在這裡處理成功回應的邏輯
          if (status === "1") {
            this.fetchProxyConfig();
          } else if (status === "-1") {
            handelFailAlertUtil(response);
          } else {
            // localStorage.removeItem("task_id");
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          // 在這裡處理錯誤回應的邏輯
          handelErrorAlertUtil(error);
        });
    },
    // 設定彩種反點設定
    addProxyConfig() {
      this.loading = true;
      const url = "/v1/api/add_proxy_config";
      const dict = {
        lottery_name: this.lottery_name,
        rebate: this.rebate_config,
      };
      this.axios
        .post(url, dict)
        .then((response) => {
          const status = response.data.status;

          // 在這裡處理成功回應的邏輯
          if (status === "1") {
            this.fetchProxyConfig();
          }
          // 在這裡處理失敗回應的邏輯
          else if (status === "-1") {
            handelFailAlertUtil(response);
          } else {
            // localStorage.removeItem("task_id");
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          // 在這裡處理錯誤回應的邏輯
          handelErrorAlertUtil(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    updateProxyConfig() {
      this.addProxyConfig();
      this.displayShowRebateConfig();
    },
    // 導航列跳轉
    navLotteryTool() {
      this.$router.push({ path: "/lotteryTool" });
    },
    goToPushPlan() {
      this.$router.push({ path: "/pushPlanManagement" });
    },
    goToCreateProxy() {
      this.$router.push({ path: "/lotteryTool" });
    },
    goToDisbandSilder() {
      this.$router.push({ path: "/disbandSilderTool" });
    },
    goToVerifyLotteryOpenResult() {
      this.$router.push({ path: "/verifyLotteryOpenResult" });
    },
    displayShowRebateConfig() {
      this.fetchProxyConfig();
      this.showRebateConfig = true;
    },
    // 關閉補漏設定視窗
    closeDisplayShowRebateConfig() {
      this.showRebateConfig = false;
    },

    displayShowAddRebateConfig() {
      this.showAddRebateConfig = true;
    },

    closeAddRebateConfig() {
      this.showAddRebateConfig = false;
    },
  },
};
</script>
