<template>
  <Sidebar />
  <div class="title">管理列表</div>
  <div class="management-table-container">
    <table class="management-table">
      <thead>
        <tr>
          <th v-for="(item, index) in columns" :key="index">
            {{ item }}
            <button class="sort-btn" @click="sortData(item)">
              <i class="fas fa-sort"></i>
            </button>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, rowIndex) in sortedData" :key="rowIndex">
          <td v-for="(item, cellIndex) in row" :key="cellIndex">{{ item }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- 导航按钮 -->
  <!-- <div class="container-row-center">
    <button class="carousel-button" @click="prev">＜</button>
    <div class="carousel-container">
      <div class="carousel-track" :style="trackStyle">
        <div
          class="carousel-item"
          v-for="product in products"
          :key="product.id"
        >
          <img :src="product.image" :alt="product.name" />
        </div>
      </div>
    </div>
    <button class="carousel-button" @click="next">＞</button>
  </div> -->
</template>

<script>
import Sidebar from "@/components/sidebar/SidebarCom";
import { handelErrorAlertUtil, handelFailAlertUtil } from "@/commonUtils.js";

export default {
  components: { Sidebar },
  data() {
    return {
      columns: ["ID", "管理者", "創建日期"],
      data: null,
      sortColumn: null,
      sortOrder: "asc",
      intervalId: null,
      switchState: false,
      isTimerRunning: false,
      products: [
        {
          id: 1,
          name: "Blue Band",
          image:
            "https://www.mymypic.net/data/attachment/forum/202403/01/1700358k5jkfsuw55rbfl5.jpg", // 实际图片路径或URL
          price: "$79",
        },
        {
          id: 2,
          name: "Blue T-Shirt",
          image:
            "https://www.mymypic.net/data/attachment/forum/202403/07/120812l48zrairkclotpzk.jpg",
        },
        {
          id: 3,
          name: "Bracelet",
          image:
            "https://www.mymypic.net/data/attachment/forum/202403/01/162829n87pen244oo4oe7a.jpg",
        },
        {
          id: 4,
          name: "Bracelet",
          image:
            "https://www.mymypic.net/data/attachment/forum/202403/04/1011145tj3y30q22j2k2we.jpg",
        },
        {
          id: 5,
          name: "Bracelet",
          image:
            "https://www.mymypic.net/data/attachment/forum/202402/23/164116yin5573e575ut573.jpg",
        },
      ],
      currentIndex: 0,
    };
  },
  created() {
    this.fetch_manager_info();
  },
  computed: {
    trackStyle() {
      // 计算轨道的偏移量
      return {
        transform: `translateX(-${this.currentIndex * 100}%)`,
        transition: "transform 0.5s ease",
      };
    },
    sortedData() {
      if (!this.sortColumn) {
        return this.data;
      }

      const columnIndex = this.columns.indexOf(this.sortColumn);
      const sortedData = [...this.data];
      sortedData.sort((a, b) => {
        if (a[columnIndex] < b[columnIndex]) {
          return this.sortOrder === "asc" ? -1 : 1;
        } else if (a[columnIndex] > b[columnIndex]) {
          return this.sortOrder === "asc" ? 1 : -1;
        } else {
          return 0;
        }
      });
      return sortedData;
    },
  },
  methods: {
    fetch_manager_info() {
      const url = "/v2/api/fetch_manager_info";
      this.axios
        .post(url)
        .then((response) => {
          const status = response.data.status;
          const result = response.data.result;

          // 在這裡處理成功回應的邏輯
          if (status === "1") {
            this.data = result;
          }
          // 在這裡處理失敗回應的邏輯
          else if (status === "-1") {
            handelFailAlertUtil(response);
          } else {
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          handelErrorAlertUtil(error);
        });
    },
    next() {
      // 循环到下一张图片
      this.currentIndex = (this.currentIndex + 1) % this.products.length;
    },
    prev() {
      // 循环到上一张图片
      this.currentIndex =
        (this.currentIndex - 1 + this.products.length) % this.products.length;
    },
    sortData(column) {
      if (column === this.sortColumn) {
        this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
      } else {
        this.sortColumn = column;
        this.sortOrder = "asc";
      }
    },
  },
};
</script>
