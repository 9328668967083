<template>
  <Sidebar />
  <div class="container">
    <div class="title">錯誤圖片管理</div>
    <div class="nav-container">
      <!-- <button class="btn-navigation" @click="goToWelineLog">
        微聊錯誤日誌
      </button> -->
      <button class="btn-navigation" @click="goToErrorImage">
        錯誤圖片管理
      </button>
    </div>
    <div class="table-container">
      <table class="table">
        <thead>
          <tr>
            <th>圖片</th>
            <th>管理</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="result in results" :key="result.id">
            <td>
              <img
                :src="result.image_path"
                @click="watchImageWindows(result.image_path)"
                style="cursor: pointer"
              />
            </td>
            <td>
              <div class="management-btn">
                <button
                  class="btn-delete btn-margin"
                  @click="delete_image(result.image_path)"
                >
                  刪除
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <transition name="modal-showImageWindows">
    <div class="modal" v-if="showImageWindows">
      <div>
        <div v-if="showImageWindows" class="modal">
          <div class="modal-content">
            <span @click="closeShowImageWindows" class="btn-right-top-close"
              >&times;</span
            >
            <img :src="showImagePath" />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Sidebar from "@/components/sidebar/SidebarCom";
import Swal from "sweetalert2";

export default {
  components: { Sidebar },
  data() {
    return {
      page: 1, // 當前頁數
      showImagePath: "",
      results: [],
      showImageWindows: false,
    };
  },
  created() {
    this.axios
      .post("/v1/api/fetch_error_images", {})
      .then((response) => {
        const status = response.data.status;

        // 在這裡處理成功回應的邏輯
        if (status === "1") {
          this.results = response.data.result;
        }
        // 在這裡處理失敗回應的邏輯
        else if (status === "-1") {
          Swal.fire({
            title: "Fail !",
            text: JSON.stringify(response.data), // 顯示回應內容
            icon: "error",
            confirmButtonText: "OK",
          });
        } else {
          // localStorage.removeItem("task_id");
          Swal.fire({
            title: "Exception !",
            text: JSON.stringify(response.data), // 顯示回應內容
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      })
      .catch((error) => {
        // 在這裡處理錯誤回應的邏輯
        Swal.fire({
          title: "Exception !",
          text: JSON.stringify(error.data), // 顯示回應內容
          icon: "error",
          confirmButtonText: "OK",
        });
      });
  },
  methods: {
    watchImageWindows(showImagePath) {
      this.showImageWindows = true;
      this.showImagePath = showImagePath;
    },
    closeShowImageWindows() {
      this.showImageWindows = false;
    },
    delete_image(path) {
      const url = "/request/images";
      const dict = { path: path };
      this.submitUtil(url, dict);
    },
  },
};
</script>

<style scoped>
/* 欄位管理 */
table {
  width: 50%; /* 欄位寬度 */
  margin-top: 10px;
  margin-bottom: 30px;
  border-collapse: collapse;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}
/* 欄位圖片管理 */
td img {
  margin: auto; /* 使用 margin:auto 來置中圖片 */
  max-height: 400px; /* 設定圖片高度 */
  max-height: 400px; /* 設定圖片高度 */
  display: block; /* 設置圖片為 block 元素 */
}

th,
td {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
  font-weight: 600;
}

td:nth-child(1) {
  width: 55%;
  text-align: left;
}

td:nth-child(2) {
  width: 10%;
}
</style>
