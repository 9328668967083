<!-- eslint-disable vue/require-v-for-key -->
<template>
  <Sidebar />
  <div class="title">微聊錯誤日誌</div>
  <div class="table-container">
    <table class="table">
      <thead>
        <tr>
          <th v-for="(item, index) in columns" :key="index">
            {{ item }}
            <button class="sort-btn" @click="sortData(item)">
              <i class="fas fa-sort"></i>
            </button>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, rowIndex) in sortedData" :key="rowIndex">
          <td v-for="(item, cellIndex) in row" :key="cellIndex">
            <div class="cell-content">
              <div class="cell-text">{{ item }}</div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="pagination">
    <template v-for="page in pageCount">
      <button v-if="isPageVisible(page)" @click="getData(page)">
        {{ page }}
      </button>
      <span v-else>...</span>
    </template>
  </div>
</template>

<script>
import Sidebar from "@/components/sidebar/SidebarCom";

export default {
  components: { Sidebar },
  data() {
    return {
      columns: ["ID", "日誌內容", "創建日期"],
      data: [],
      sortColumn: null,
      sortOrder: "asc",
      currentPage: 1,
      pageSize: 20, // 控制每頁顯示數量
      totalItemCount: 100, // 總共有多少 row data
      total: 0,
      intervalId: null,
      switchState: false,
      isTimerRunning: false,
    };
  },
  created() {
    this.stopSchedule();
    this.getData(this.currentPage);
  },
  computed: {
    pageCount() {
      return Math.ceil(this.totalItemCount / this.pageSize);
    },
    visiblePageCount() {
      return 10; // 可自訂每頁最多顯示的按鈕數量
    },
    firstVisiblePage() {
      const half = Math.floor(this.visiblePageCount / 2);
      const first = Math.max(1, this.currentPage - half);
      const last = Math.min(first + this.visiblePageCount - 1, this.pageCount);
      return Math.max(1, last - this.visiblePageCount + 1);
    },
    sortedData() {
      if (!this.sortColumn) {
        return this.data;
      }

      const columnIndex = this.columns.indexOf(this.sortColumn);
      const sortedData = [...this.data];
      sortedData.sort((a, b) => {
        if (a[columnIndex] < b[columnIndex]) {
          return this.sortOrder === "asc" ? -1 : 1;
        } else if (a[columnIndex] > b[columnIndex]) {
          return this.sortOrder === "asc" ? 1 : -1;
        } else {
          return 0;
        }
      });
      return sortedData;
    },
  },
  methods: {
    sortData(column) {
      if (column === this.sortColumn) {
        this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
      } else {
        this.sortColumn = column;
        this.sortOrder = "asc";
      }
    },
    stopSchedule() {
      const task = localStorage.getItem("task");
      clearInterval(task);
      this.intervalId = null;
      this.switchState = false;
      this.isTimerRunning = false;
    },
    async getData(page) {
      const response = await this.axios.get(
        `/v1/api/weline_log?page=${page}&pageSize=${this.pageSize}`
      );
      this.data = response.data.result;
      this.total = response.data.total;
      this.currentPage = page;
    },
    isPageVisible(page) {
      return (
        page >= this.firstVisiblePage &&
        page < this.firstVisiblePage + this.visiblePageCount
      );
    },
  },
};
</script>
