<template>
  <Sidebar />
  <div class="container-row">
    <div class="card setting-mgn-top setting-height-370 setting-width-1410">
      <div class="left-container">
        <div class="title-home">系統狀態</div>
        <button
          class="rotate-btn"
          :class="{ 'is-rotating': isRotating }"
          @click="rotate"
        >
          <i class="fas fa-arrows-rotate" style="font-size: 1.2rem"></i>
        </button>
      </div>

      <div class="card-body">
        <div class="container-row-center">
          <div class="card">
            <div class="header">
              <div class="title-h2 setting-mgn-top-50">機器負載</div>
            </div>
            <div class="card-body">
              <div class="display-base btn-default base-margin">
                <div class="dashboard-text">{{ machineLoading }}</div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="header">
              <div class="title-h2 setting-mgn-top-50">記憶體使用量</div>
            </div>
            <div class="card-body">
              <div class="display-base btn-default base-margin">
                <div class="dashboard-text">{{ memoryCapacity }}</div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="header">
              <div class="title-h2 setting-mgn-top-50">CPU</div>
            </div>
            <div class="card-body">
              <div class="display-base btn-default base-margin">
                <div class="dashboard-text">{{ cpu }}</div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="header">
              <div class="title-h2 setting-mgn-top-50">IP 位址</div>
            </div>
            <div class="card-body">
              <div class="display-base btn-default base-margin">
                <div class="dashboard-text">{{ ipAddress }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="loading-overlay-two" v-if="loading">
    <div class="spinner"></div>
  </div>
</template>

<script>
import Sidebar from "@/components/sidebar/SidebarCom";
import { handelFailAlertUtil, handelErrorAlertUtil } from "@/commonUtils.js";

export default {
  // 綁定元件
  components: { Sidebar },
  data() {
    return {
      isRotating: false,
      intervalId: null,
      switchState: false,
      isTimerRunning: false,
      loading: true,
      machineLoading: "",
      memoryCapacity: "",
      cpu: "",
      ipAddress: "",
    };
  },
  created() {
    this.stopSchedule();
    this.fetch_machine_status();
  },
  methods: {
    rotate() {
      this.isRotating = true;
      setTimeout(() => {
        this.isRotating = false;
      }, 1000);
      this.fetch_machine_status();
    },
    // 關閉排程
    stopSchedule() {
      const task = localStorage.getItem("task");
      clearInterval(task);
      this.intervalId = null;
      this.switchState = false;
      this.isTimerRunning = false;
    },
    fetch_machine_status() {
      this.loading = true;
      const dict = {};
      const url = "/v2/api/fetch_machine_status";
      this.axios
        .post(url, dict)
        .then((response) => {
          const status = response.data.status;

          // 在這裡處理成功回應的邏輯
          if (status === "1") {
            const result = response.data.result;
            this.machineLoading = result.machineLoading;
            this.memoryCapacity = result.memoryCapacity;
            this.ipAddress = result.ipAddress;
            this.cpu = result.cpu;
          } else if (status === "-1") {
            handelFailAlertUtil(response);
          } else {
            handelFailAlertUtil(response);
          }
        })
        .catch((error) => {
          // 在這裡處理錯誤回應的邏輯
          handelErrorAlertUtil(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
