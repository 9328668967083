import Swal from "sweetalert2";

export function getCurrentDomain() {
  // 在這裡放置獲取當前域名的邏輯
  const hostname = window.location.hostname;
  if (hostname === "172.31.44.19") {
    return "domestic";
  } else if (hostname === "54.254.64.244") {
    return "international";
  } else {
    return "domestic";
  }
}

export function submitFormRequest(VueInstance, url, dict) {
  VueInstance.axios
    .post(url, dict)
    .then((response) => {
      const status = response.data.status;

      // 在這裡處理成功回應的邏輯
      if (status === "1") {
        const msg = response.data.msg;
        Swal.fire({
          title: "Success !",
          text: msg, // 顯示回應內容
          icon: "success",
          confirmButtonText: "OK",
        });
      } else if (status === "-1") {
        Swal.fire({
          title: "Fail !",
          text: JSON.stringify(response.data, null, 2), // 顯示回應內容
          icon: "error",
          confirmButtonText: "OK",
        });
      } else {
        Swal.fire({
          title: "Exception !",
          text: JSON.stringify(response.data, null, 2), // 顯示回應內容
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    })
    .catch((error) => {
      // 在這裡處理錯誤回應的邏輯
      Swal.fire({
        title: "Exception !",
        text: JSON.stringify(error.data, null, 2), // 顯示回應內容
        icon: "error",
        confirmButtonText: "OK",
      });
    });
}

export function RequestUtil(VueInstance, url, dict) {
  VueInstance.axios
    .post(url, dict)
    .then((response) => {
      const status = response.data.status;

      // 在這裡處理成功回應的邏輯
      if (status === "1") {
        const msg = response.data.msg;
        Swal.fire({
          title: "Success !",
          text: msg, // 顯示回應內容
          icon: "success",
          confirmButtonText: "OK",
        });
      } else if (status === "-1") {
        Swal.fire({
          title: "Fail !",
          text: JSON.stringify(response.data, null, 2), // 顯示回應內容
          icon: "error",
          confirmButtonText: "OK",
        });
      } else {
        // localStorage.removeItem("task_id");
        Swal.fire({
          title: "Exception !",
          text: JSON.stringify(response.data, null, 2), // 顯示回應內容
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    })
    .catch((error) => {
      // 在這裡處理錯誤回應的邏輯
      Swal.fire({
        title: "Exception !",
        text: JSON.stringify(error.data, null, 2), // 顯示回應內容
        icon: "error",
        confirmButtonText: "OK",
      });
    });
}

export function handelResponseUtil(status, response) {
  // 在這裡處理成功回應的邏輯
  if (status === "1") {
    this.searchPushPlan();
  }
  // 在這裡處理失敗回應的邏輯
  else if (status === "-1") {
    Swal.fire({
      title: "Fail !",
      text: JSON.stringify(response.data, null, 2), // 顯示回應內容
      icon: "error",
      confirmButtonText: "OK",
    });
  } else {
    Swal.fire({
      title: "Exception !",
      text: JSON.stringify(response.data, null, 2), // 顯示回應內容
      icon: "error",
      confirmButtonText: "OK",
    });
  }
}

export function handelSuccessAlertUtil(response) {
  Swal.fire({
    title: "Success !",
    text: response.data.msg, // 顯示回應內容
    icon: "success",
    confirmButtonText: "OK",
  });
}

export function handelFailAlertUtil(response) {
  Swal.fire({
    title: "Exception !",
    text: JSON.stringify(response.data, null, 2), // 顯示回應內容
    icon: "error",
    confirmButtonText: "OK",
  });
}

export function handelErrorAlertUtil(error) {
  Swal.fire({
    title: "Exception !",
    text: JSON.stringify(error.data, null, 2), // 顯示回應內容
    icon: "error",
    confirmButtonText: "OK",
  });
}
