<template>
  <div class="nav-container">
    <button class="btn-navigation" @click="goToPushPlan">推送計畫管理</button>
    <button class="btn-navigation" @click="goToCreateProxy">創建代理線</button>
    <button class="btn-navigation" @click="goToDisbandSilder">
      解除滑塊工具
    </button>
    <button class="btn-navigation-middle" @click="goToVerifyLotteryOpenResult">
      驗證彩票開獎結果
    </button>
  </div>
</template>

<script>
export default {
  methods: {
    // 跳轉推送計畫管理路由
    goToPushPlan() {
      this.$router.push({ path: "/pushPlanManagement" });
    },
    // 跳轉創建代理線路由
    goToCreateProxy() {
      this.$router.push({ path: "/lotteryTool" });
    },
    goToDisbandSilder() {
      this.$router.push({ path: "/disbandSilderTool" });
    },
    goToVerifyLotteryOpenResult() {
      this.$router.push({ path: "/verifyLotteryOpenResult" });
    },
  },
};
</script>
