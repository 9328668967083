<template>
  <Sidebar />
  <ul class="nav nav-tabs">
    <li :class="{ active: activeTab === 'weline_encode' }">
      <a @click="changeTab('weline_encode')">加解密工具</a>
    </li>
    <li :class="{ active: activeTab === 'proxy_request' }">
      <a @click="changeTab('proxy_request')">代理請求工具</a>
    </li>
    <li :class="{ active: activeTab === 'add_friends_tool' }">
      <a @click="changeTab('add_friends_tool')">自動加好友工具</a>
    </li>
    <li :class="{ active: activeTab === 'create_group_tool' }">
      <a @click="changeTab('create_group_tool')">自動創建推送群組</a>
    </li>
  </ul>
  <div class="tab-content">
    <div
      class="tab-pane fade"
      :class="{ in: activeTab === 'weline_encode' }"
      id="weline_encode"
    >
      <div class="container-row">
        <div class="submit-form setting-height-370">
          <div class="submit-form-title">微聊解密</div>
          <div class="submit-form-inner">
            <form>
              <div class="container-row">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-util-control"
                    id="random"
                    placeholder="random"
                    v-model="random"
                  />
                </div>
                <small class="lable-text">輸入random</small>
              </div>
              <div class="container-row">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-util-control"
                    id="path"
                    placeholder="path"
                    v-model="path1"
                  />
                </div>
                <small class="lable-text">request path</small>
              </div>
              <div class="container-row">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-util-control"
                    id="param"
                    placeholder="param"
                    v-model="param"
                  />
                </div>
                <small class="lable-text">回應的值</small>
              </div>

              <button
                type="submit"
                class="btn btn-confirm btn-margin-1"
                @click.prevent="decodeParam"
              >
                確認
              </button>
            </form>
          </div>
        </div>
        <div class="submit-form setting-height-370">
          <div class="submit-form-title">微聊加密</div>
          <div class="submit-form-inner">
            <form>
              <div class="container-row">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-util-control"
                    id="payload"
                    placeholder="payload"
                    v-model="payload"
                  />
                </div>
                <small class="lable-text">輸入payload</small>
              </div>
              <div class="container-row">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-util-control"
                    id="path2"
                    placeholder="path"
                    v-model="path2"
                  />
                </div>
                <small class="lable-text">請求路徑</small>
              </div>

              <button
                type="submit"
                class="btn btn-confirm btn-margin-2"
                @click.prevent="encodeParam"
              >
                確認
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div
      class="tab-pane fade"
      :class="{ in: activeTab === 'proxy_request' }"
      id="proxy_request"
    >
      <div class="container-row">
        <div class="submit-form setting-height-370">
          <div class="submit-form-title">代理發送請求</div>
          <div class="submit-form-inner">
            <form>
              <div class="container-row">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-util-control"
                    id="host"
                    placeholder="host"
                    v-model="host"
                  />
                </div>
                <small class="lable-text">微聊域名</small>
              </div>
              <div class="container-row">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-util-control"
                    id="payload"
                    placeholder="payload"
                    v-model="payload"
                  />
                </div>
                <small class="lable-text">輸入payload</small>
              </div>
              <div class="container-row">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-util-control"
                    id="path2"
                    placeholder="path"
                    v-model="path2"
                    @keyup.enter="proxySendRequest"
                  />
                </div>
                <small class="lable-text">request path</small>
              </div>
            </form>
            <div class="container2">
              <button
                type="submit"
                class="btn btn-confirm btn-margin-3"
                @click.prevent="proxySendRequest"
              >
                確認
              </button>
              <button
                type="submit"
                class="btn btn-settings btn-margin-4"
                @click="displayWelineLoginPage"
              >
                設定
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="tab-pane fade"
      :class="{ in: activeTab === 'add_friends_tool' }"
      id="add_friends_tool"
    >
      <div class="container-row">
        <div class="container-column">
          <div class="select-container">
            <div class="setting-mgn-right-780">
              <button class="btn-environment" @click="autoInputArgs('test1')">
                第一套
              </button>
              <button class="btn-environment" @click="autoInputArgs('test2')">
                第二套
              </button>
            </div>
          </div>
          <div class="container-row">
            <div class="submit-form setting-height-490">
              <div class="submit-form-title">獲取使用者 info</div>
              <div class="submit-form-inner setting-height-410">
                <form>
                  <div class="container-row">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-util-control"
                        id="host"
                        placeholder="lottery domain"
                        v-model="lottery_domain"
                      />
                    </div>
                    <small class="lable-text">彩票域名</small>
                  </div>
                  <div class="container-row">
                    <input
                      type="text"
                      class="form-util-control"
                      id="weline_domain"
                      placeholder="weline domain"
                      v-model="weline_domain"
                    />
                    <small class="lable-text">微聊域名</small>
                  </div>
                  <div class="container-row">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-util-control"
                        id="username"
                        placeholder="username"
                        v-model="username"
                      />
                    </div>
                    <small class="lable-text">輸入帳號</small>
                  </div>
                  <div class="container-row">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-util-control"
                        id="password"
                        placeholder="password"
                        v-model="password"
                      />
                    </div>
                    <small class="lable-text">輸入密碼</small>
                  </div>
                </form>

                <button
                  type="submit"
                  class="btn btn-confirm btn-margin-6"
                  @click.prevent="fetchWelineUserInfoRequest"
                >
                  確認
                </button>
              </div>
            </div>
            <div class="submit-form setting-height-490">
              <div class="submit-form-title">自動加好友</div>
              <div class="submit-form-inner setting-height-410">
                <form>
                  <div class="container-row">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-util-control"
                        id="host"
                        placeholder="lottery domain"
                        v-model="friends_lottery_domain"
                      />
                    </div>
                    <small class="lable-text">彩票域名</small>
                  </div>
                  <div class="container-row">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-util-control"
                        id="weline_domain"
                        placeholder="weline domain"
                        v-model="friends_weline_domain"
                      />
                    </div>
                    <small class="lable-text">微聊域名</small>
                  </div>
                  <div class="container-row">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-util-control"
                        id="user_id"
                        placeholder="user id"
                        v-model="user_id"
                      />
                    </div>
                    <small class="lable-text">輸入user id</small>
                  </div>
                  <div class="container-row">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-util-control"
                        id="ml_id"
                        placeholder="ml id"
                        v-model="ml_id"
                      />
                    </div>
                    <small class="lable-text">輸入ml_id</small>
                  </div>
                  <div class="container-row">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-util-control"
                        id="usernameList"
                        placeholder="username1,username2,username3..."
                        v-model="usernameList"
                      />
                    </div>
                    <small class="lable-text">輸入帳號</small>
                  </div>
                  <div class="container-row">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-util-control"
                        id="password"
                        placeholder="password"
                        v-model="friends_password"
                      />
                    </div>
                    <small class="lable-text">輸入密碼</small>
                  </div>
                </form>

                <button
                  type="submit"
                  class="btn btn-confirm btn-margin-5"
                  @click.prevent="addWelineFriendsRequest"
                >
                  確認
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="tab-pane fade"
      :class="{ in: activeTab === 'create_group_tool' }"
      id="create_group_tool"
    >
      <div class="container-row">
        <div class="container-column">
          <div class="select-container"></div>
          <div class="container-row">
            <div class="submit-form setting-height-490">
              <div class="submit-form-title">自動創建推送群組</div>
              <div class="submit-form-inner setting-height-410">
                <form>
                  <div class="container-row">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-util-control"
                        id="domain"
                        placeholder="lottery domain"
                        v-model="weline_group_lottery_domain"
                      />
                    </div>
                    <small class="lable-text">彩票域名</small>
                  </div>
                  <div class="container-row">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-util-control"
                        id="weline_domain"
                        placeholder="weline domain"
                        v-model="weline_group_weline_domain"
                      />
                    </div>
                    <small class="lable-text">微聊域名</small>
                  </div>
                  <div class="container-row">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-util-control"
                        id="usernameList"
                        placeholder="username1,username2,username3..."
                        v-model="weline_group_username"
                      />
                    </div>
                    <small class="lable-text">平台帳號</small>
                  </div>
                  <div class="container-row">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-util-control"
                        id="user_id"
                        placeholder="測試群組001,測試群組002,測試群組003..."
                        v-model="weline_group_name"
                      />
                    </div>
                    <small class="lable-text">群組名稱</small>
                  </div>
                  <div class="container-row">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-util-control"
                        id="member_id"
                        placeholder="群成員 id"
                        v-model="member_id"
                      />
                    </div>
                    <small class="lable-text">群組成員</small>
                  </div>
                </form>

                <button
                  type="submit"
                  class="btn btn-confirm btn-margin-9"
                  @click.prevent="createWelineGroupRequest"
                >
                  確認
                </button>
                <div class="tips-color"><p>備註: 平台密碼默認 123qwe</p></div>
              </div>
            </div>
            <div class="submit-form setting-height-490">
              <div class="submit-form-title">自動產生序列字串</div>
              <div class="submit-form-inner setting-height-410">
                <form>
                  <div class="container-row">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-genrate-word-control"
                        id="genrate_word"
                        placeholder="ex: test"
                        v-model="genrate_word"
                      />
                    </div>
                    <small class="lable-text">前綴字串</small>
                  </div>
                  <div class="container-row">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-genrate-word-control"
                        id="start_index"
                        placeholder="1"
                        v-model="start_index"
                      />
                    </div>
                    <small class="lable-text">開始序號</small>
                  </div>

                  <div class="container-row">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-genrate-word-control"
                        id="start_index"
                        placeholder="6"
                        v-model="end_index"
                      />
                    </div>
                    <small class="lable-text">結束序號</small>
                  </div>
                </form>
                <textarea
                  class="product-word-textarea"
                  v-model="generate_word_result"
                  placeholder="test0001,test0002,test0003,test0004,test0005,test0006"
                  rows="5"
                ></textarea>

                <button
                  type="submit"
                  class="btn btn-confirm btn-margin-5"
                  @click.prevent="genrateWordFunction"
                >
                  確認
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <transition name="modal-showWelineLoginPage">
    <div class="modal" v-if="showWelineLoginPage">
      <div>
        <div v-if="showWelineLoginPage" class="modal">
          <div class="modal-content">
            <span @click="closeWelineLoginPage" class="btn-right-top-close"
              >&times;</span
            >
            <h3>更新登入 Token</h3>
            <div class="addPushPlan-container">
              <div class="filter">
                <label for="xhost">平台域名</label>
                <input
                  class="input-box"
                  type="text"
                  placeholder="http://dafacloud-test.com"
                  id="xhost"
                  v-model="xhost"
                />
              </div>
              <div class="filter">
                <label for="lottery_username">平台帳號</label>
                <input
                  class="input-box"
                  type="text"
                  placeholder="username"
                  id="lottery_username"
                  v-model="lottery_username"
                />
              </div>
              <div class="filter">
                <label for="lottery_password">平台密碼</label>
                <input
                  class="input-box"
                  type="text"
                  placeholder="password"
                  id="lottery_password"
                  v-model="lottery_password"
                />
              </div>
            </div>

            <button class="btn-tips-close" @click="closeWelineLoginPage">
              關閉
            </button>
            <button class="btn-tips-submit" @click="welineLoginRequest">
              登入
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <div class="loading-overlay-two" v-if="loading">
    <div class="spinner"></div>
  </div>
</template>

<script>
import Sidebar from "@/components/sidebar/SidebarCom";
import Swal from "sweetalert2";
import { MD5 } from "crypto-js";
import {
  handelSuccessAlertUtil,
  handelErrorAlertUtil,
  handelFailAlertUtil,
} from "@/commonUtils.js";
export default {
  // 綁定元件
  components: { Sidebar },
  data() {
    return {
      activeTab: "weline_encode",
      domain: "",
      weline_group_lottery_domain: "",
      weline_group_weline_domain: "",
      friends_lottery_domain: "",
      friends_weline_domain: "",
      lottery_domain: "",
      weline_domain: "",
      usernameList: "",
      weline_group_name: "",
      weline_group_username: "",
      username: "",
      password: "",
      friends_password: "",
      xhost: "",
      result: "",
      host: "",
      ml_id: "",
      user_id: "",
      member_id: "",
      lottery_username: "",
      lottery_password: "",
      genrate_word: "",
      start_index: "",
      end_index: "",
      random: "",
      param: "",
      path1: "",
      path2: "",
      payload: "",
      generate_word_result: "",
      showWelineLoginPage: false,
      loading: false,
      showLabel: {
        random: false,
        path: false,
        payload: false,
        path2: false,
        host: false,
        username: false,
        password: false,
        ml_id: false,
        user_id: false,
        friends_username: false,
        friends_password: false,
        friends_lottery_domain: false,
        friends_weline_domain: false,
        weline_group_lottery_domain: false,
        weline_group_weline_domain: false,
        lottery_domain: false,
        weline_domain: false,
      },
    };
  },
  created() {},
  methods: {
    autoInputArgs(environment) {
      const currentDomain = window.location.hostname;
      // 國內版
      if (currentDomain === "172.31.44.19") {
        if (environment == "test1") {
          this.lottery_domain = "http://m.dafacloud-test.com";
          this.weline_domain = "https://vkw3hgk79d8-main.godzilla-test.com";
          this.friends_lottery_domain = "http://m.dafacloud-test.com";
          this.friends_weline_domain =
            "https://vkw3hgk79d8-main.godzilla-test.com";
        } else if (environment == "test2") {
          this.lottery_domain = "http://m.caishen03.com";
          this.weline_domain = "https://vkw3hgk79d8-main2.godzilla-test.com";
          this.friends_lottery_domain = "http://m.caishen03.com";
          this.friends_weline_domain =
            "https://vkw3hgk79d8-main2.godzilla-test.com";
        }
        // 國際版
      } else if (currentDomain === "54.254.64.244") {
        if (environment == "test1") {
          this.lottery_domain = "http://m.internation-test.com";
          this.weline_domain = "https://vkw3hgk79d5-main.kong-test.com";
          this.friends_lottery_domain = "http://m.internation-test.com";
          this.friends_weline_domain = "https://vkw3hgk79d5-main.kong-test.com";
        } else if (environment == "test2") {
          this.lottery_domain = "http://m.internation-test2-deploy08-01.com";
          this.weline_domain = "https://vkw3hgk79d8-main2.kong-test.com";
          this.friends_lottery_domain =
            "http://m.internation-test2-deploy08-01.com";
          this.friends_weline_domain =
            "https://vkw3hgk79d8-main2.kong-test.com";
        }
      }
    },
    displayWelineLoginPage() {
      this.showWelineLoginPage = true;
    },
    closeWelineLoginPage() {
      this.showWelineLoginPage = false;
    },
    welineRequestUtils(url, dict) {
      this.loading = true;
      this.axios
        .post(url, dict)
        .then((response) => {
          const status = response.data.status;

          // 在這裡處理成功回應的邏輯
          if (status == "1") {
            const result = response.data.result;
            this.result = result;

            // 檢查結果是否有 token 這個鍵
            if ("token" in result) {
              // 如果有 token，取得其值
              const token = result.token;
              localStorage.setItem("weline_token", token);
            }
            Swal.fire({
              title: "Success !",
              text: JSON.stringify(result), // 顯示回應內容
              icon: "success",
              confirmButtonText: "OK",
            });
          }
          // 在這裡處理失敗回應的邏輯
          else if (status == "-1") {
            handelFailAlertUtil(response);
          } else {
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          handelErrorAlertUtil(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    WelineUserInfoRequest(url, dict) {
      this.loading = true;
      this.axios
        .post(url, dict)
        .then((response) => {
          const status = response.data.status;

          // 在這裡處理成功回應的邏輯
          if (status == "1") {
            const result = response.data.result;
            this.user_id = result.user_id;
            this.ml_id = result.ml_id;

            handelSuccessAlertUtil(response);
          }
          // 在這裡處理失敗回應的邏輯
          else if (status == "-1") {
            handelFailAlertUtil(response);
          } else {
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          handelErrorAlertUtil(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    welineAddFriendsRequest(url, dict) {
      this.loading = true;
      this.axios
        .post(url, dict)
        .then((response) => {
          const status = response.data.status;

          // 在這裡處理成功回應的邏輯
          if (status == "1") {
            const msg = response.data.msg;

            Swal.fire({
              title: "Success !",
              text: JSON.stringify(msg), // 顯示回應內容
              icon: "success",
              confirmButtonText: "OK",
            });
          }
          // 在這裡處理失敗回應的邏輯
          else if (status == "-1") {
            handelFailAlertUtil(response);
          } else {
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          handelErrorAlertUtil(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    welineGroupRequest(url, dict) {
      this.loading = true;
      this.axios
        .post(url, dict)
        .then((response) => {
          const status = response.data.status;

          // 在這裡處理成功回應的邏輯
          if (status == "1") {
            handelSuccessAlertUtil(response);
          }
          // 在這裡處理失敗回應的邏輯
          else if (status == "-1") {
            handelFailAlertUtil(response);
          } else {
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          handelErrorAlertUtil(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 解密請求
    decodeParam() {
      const url = "/v1/api/decode_weline_param";
      const dict = {
        random: this.random,
        path: this.path1,
        param: this.param,
      };
      this.welineRequestUtils(url, dict);
    },
    encodeParam() {
      const url = "/v1/api/encode_weline_param";
      const dict = {
        payload: this.payload,
        path: this.path2,
      };
      this.welineRequestUtils(url, dict);
    },
    changeTab(tab) {
      this.activeTab = tab;
    },
    proxySendRequest() {
      const url = "/v1/api/weline_proxy_send_request";
      const token = localStorage.getItem("weline_token");
      const dict = {
        token: token,
        host: this.host,
        payload: this.payload,
        path: this.path2,
      };
      this.welineRequestUtils(url, dict);
    },
    fetchWelineUserInfoRequest() {
      const url = "/v1/api/fetch_weline_info";
      const dict = {
        platform_domain: this.lottery_domain,
        weline_domain: this.weline_domain,
        username: this.username,
        password: this.password,
      };
      this.WelineUserInfoRequest(url, dict);
    },
    addWelineFriendsRequest() {
      const url = "/v1/api/add_weline_friend";
      const dict = {
        lottery_domain: this.friends_lottery_domain,
        weline_domain: this.friends_weline_domain,
        ml_id: this.ml_id,
        user_id: this.user_id,
        username: this.usernameList,
        password: this.friends_password,
      };
      this.welineAddFriendsRequest(url, dict);
    },
    createWelineGroupRequest() {
      const timestamp = Date.now();
      const timestampString = timestamp.toString();
      const task_id = MD5(timestampString + this.member_id).toString();

      const url = "/v1/api/create_weline_group";
      const dict = {
        lottery_domain: this.weline_group_lottery_domain,
        weline_domain: this.weline_group_weline_domain,
        group_members: this.member_id,
        group_name: this.weline_group_name,
        username: this.weline_group_username,
        task_id: task_id,
      };
      this.welineGroupRequest(url, dict);
    },
    generateString(index) {
      // 使用字符串插值和padStart函数生成带有前缀的字符串
      return `${this.genrate_word}${String(index).padStart(4, "0")}`;
    },
    genrateWordFunction() {
      const start_index = this.start_index;
      const end_index = this.end_index;
      const result = [];
      for (let i = start_index; i <= end_index; i++) {
        result.push(this.generateString(i));
      }
      // 使用数组的join方法将字符串列表连接起来，用逗号分隔
      this.generate_word_result = result.join(",");
    },
    welineLoginRequest() {
      const url = "/v1/api/fetch_weline_token";
      const dict = {
        domain: this.xhost,
        lottery_username: this.lottery_username,
        lottery_password: this.lottery_password,
      };
      this.welineRequestUtils(url, dict);
      localStorage.setItem("weline_token", this.result.token);
    },
  },
};
</script>
