<template>
  <Sidebar />
  <div class="container">
    <div class="title">CMS 管理工具</div>
    <div class="card-container">
      <div class="submit-form">
        <div class="submit-form-title">新增管理員</div>
        <div class="submit-form-inner setting-height-220">
          <form>
            <div class="form-group">
              <input
                type="text"
                class="form-control"
                id="username"
                placeholder="請輸入管理帳號"
                v-model="username"
              />
            </div>
            <div class="form-group">
              <input
                type="password"
                class="form-control"
                id="password"
                placeholder="請輸入管理密碼"
                v-model="password"
              />
            </div>

            <button
              type="submit"
              class="btn btn-confirm setting-mgn-40-0-0-20"
              @click.prevent="addManager"
            >
              確認
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/sidebar/SidebarCom";
import Swal from "sweetalert2";

export default {
  // 綁定元件
  components: { Sidebar },
  data() {
    return {
      username: "",
      password: "",
    };
  },
  created() {},
  methods: {
    // 創建代理線
    addManager() {
      this.axios
        .post("/v1/api/add_manager", {
          username: this.username,
          password: this.password,
        })
        .then((response) => {
          const status = response.data.status;

          // 在這裡處理成功回應的邏輯
          if (status === "1") {
            Swal.fire({
              title: "Success !",
              text: "新增成功", // 顯示回應內容
              icon: "success",
              confirmButtonText: "OK",
            });
          }
          // 在這裡處理失敗回應的邏輯
          else if (status === "-1") {
            Swal.fire({
              title: "Fail !",
              text: JSON.stringify(response.data), // 顯示回應內容
              icon: "error",
              confirmButtonText: "OK",
            });
          } else {
            // localStorage.removeItem("task_id");
            Swal.fire({
              title: "Exception !",
              text: JSON.stringify(response.data), // 顯示回應內容
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        })
        .catch((error) => {
          // 在這裡處理錯誤回應的邏輯
          Swal.fire({
            title: "Exception !",
            text: JSON.stringify(error.data), // 顯示回應內容
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    },
  },
};
</script>
