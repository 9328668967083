<template>
  <Sidebar />
  <div>
    <p class="hiden">{{ seconds }}</p>
    <label class="switch">
      <input type="checkbox" @click="toggleTimer" />
      {{ isTimerRunning ? "Stop" : "Start" }}
      <span class="slider"></span>
    </label>
  </div>
  <div class="title">XX殺率</div>
  <span class="checkbox-tips">任務狀態開關</span>
  <div class="container-row">
    <div class="card">
      <div class="header">
        <div class="title-h2 setting-mgn-left-50">Test1 殺率</div>
        <!-- 根據後端回的版本切換顯示頁面 -->
        <div v-if="version === 'international'">
          <button class="btn-gear" @click="watchKillRateEnv('test1')">
            <i class="fa fa-gear"></i>
          </button>
        </div>
        <div v-if="version === 'domestic'">
          <button class="btn-gear" @click="watchKillRateConfig('test1')">
            <i class="fa fa-gear"></i>
          </button>
        </div>
      </div>
      <div class="card-body">
        <button
          class="btn btn-primary setting-mgn-10-50-50-10"
          @click="killRate('test1Kill', 'open')"
        >
          啟動
        </button>
        <button
          class="btn btn-primary setting-mgn-10-50-50-10"
          @click="closekillRate('test1Kill', 'close')"
        >
          關閉
        </button>
        <button
          class="btn btn-secondary setting-mgn-10-50-50-10"
          :style="{ backgroundColor: test1KillStatusColor }"
        >
          {{ test1KillStatus }}
        </button>
      </div>
    </div>
    <div class="card">
      <div class="header">
        <div class="title-h2 setting-mgn-left-50">Test2 殺率</div>
        <!-- 根據後端回的版本切換顯示頁面 -->
        <div v-if="version === 'international'">
          <button class="btn-gear" @click="watchKillRateEnv('test2')">
            <i class="fa fa-gear"></i>
          </button>
        </div>
        <div v-if="version === 'domestic'">
          <button class="btn-gear" @click="watchKillRateConfig('test2')">
            <i class="fa fa-gear"></i>
          </button>
        </div>
      </div>
      <div class="card-body">
        <button
          class="btn btn-primary setting-mgn-10-50-50-10"
          @click="killRate('test2Kill', 'open')"
        >
          啟動
        </button>
        <button
          class="btn btn-primary setting-mgn-10-50-50-10"
          @click="closekillRate('test2Kill', 'close')"
        >
          關閉
        </button>
        <button
          class="btn btn-secondary setting-mgn-10-50-50-10"
          :style="{ backgroundColor: test2KillStatusColor }"
        >
          {{ test2KillStatus }}
        </button>
      </div>
    </div>
    <div class="card">
      <div class="header">
        <div class="title-h2 setting-mgn-left-50">Test3 殺率</div>
        <!-- 根據後端回的版本切換顯示頁面 -->
        <div v-if="version === 'international'">
          <button class="btn-gear" @click="watchKillRateEnv('test3')">
            <i class="fa fa-gear"></i>
          </button>
        </div>
        <div v-if="version === 'domestic'">
          <button class="btn-gear" @click="watchKillRateConfig('test3')">
            <i class="fa fa-gear"></i>
          </button>
        </div>
      </div>
      <div class="card-body">
        <button
          class="btn btn-primary setting-mgn-10-50-50-10"
          @click="killRate('test3Kill', 'open')"
        >
          啟動
        </button>
        <button
          class="btn btn-primary setting-mgn-10-50-50-10"
          @click="closekillRate('test3Kill', 'close')"
        >
          關閉
        </button>
        <button
          class="btn btn-secondary setting-mgn-10-50-50-10"
          :style="{ backgroundColor: test3KillStatusColor }"
        >
          {{ test3KillStatus }}
        </button>
      </div>
    </div>
    <div class="card">
      <div class="header">
        <div class="title-h2 setting-mgn-left-50">Test4 殺率</div>
        <!-- 根據後端回的版本切換顯示頁面 -->
        <div v-if="version === 'international'">
          <button class="btn-gear" @click="watchKillRateEnv('test4')">
            <i class="fa fa-gear"></i>
          </button>
        </div>
        <div v-if="version === 'domestic'">
          <button class="btn-gear" @click="watchKillRateConfig('test4')">
            <i class="fa fa-gear"></i>
          </button>
        </div>
      </div>
      <div class="card-body">
        <button
          class="btn btn-primary setting-mgn-10-50-50-10"
          @click="killRate('test4Kill', 'open')"
        >
          啟動
        </button>
        <button
          class="btn btn-primary setting-mgn-10-50-50-10"
          @click="closekillRate('test4Kill', 'close')"
        >
          關閉
        </button>
        <button
          class="btn btn-secondary setting-mgn-10-50-50-10"
          :style="{ backgroundColor: test4KillStatusColor }"
        >
          {{ test4KillStatus }}
        </button>
      </div>
    </div>
  </div>
  <div class="card-container">
    <div class="card">
      <div class="header">
        <div class="title-h2 setting-mgn-left-50">Pre 殺率</div>
        <!-- 根據後端回的版本切換顯示頁面 -->
        <div v-if="version === 'international'">
          <button class="btn-gear" @click="watchKillRateEnv('pre')">
            <i class="fa fa-gear"></i>
          </button>
        </div>
        <div v-if="version === 'domestic'">
          <button class="btn-gear" @click="watchKillRateConfig('pre')">
            <i class="fa fa-gear"></i>
          </button>
        </div>
      </div>
      <div class="card-body">
        <button
          class="btn btn-primary setting-mgn-10-50-50-10"
          @click="killRate('preKill', 'open')"
        >
          啟動
        </button>
        <button
          class="btn btn-primary setting-mgn-10-50-50-10"
          @click="closekillRate('preKill', 'close')"
        >
          關閉
        </button>
        <button
          class="btn btn-secondary setting-mgn-10-50-50-10"
          :style="{ backgroundColor: preKillStatusColor }"
        >
          {{ preKillStatus }}
        </button>
      </div>
    </div>
  </div>
  <transition name="modal-displaySeclectKillRateEnvTest1">
    <div class="modal" v-if="displaySeclectKillRateEnvTest1">
      <div>
        <div v-if="displaySeclectKillRateEnvTest1" class="modal">
          <div class="modal-content">
            <span @click="closeKillRateEnv('test1')" class="btn-right-top-close"
              >&times;</span
            >
            <h3>選擇XX殺率環境 Test1</h3>
            <div class="container-env">
              <button
                class="btn-setting-env"
                @click="watchKillRateConfig('test1killin')"
              >
                test1killin
              </button>
              <button
                class="btn-setting-env"
                @click="watchKillRateConfig('test1killth')"
              >
                test1killth
              </button>
              <button
                class="btn-setting-env"
                @click="watchKillRateConfig('test1killuc')"
              >
                test1killuc
              </button>
              <button
                class="btn-setting-env"
                @click="watchKillRateConfig('test1killvn')"
              >
                test1killvn
              </button>
              <button
                class="btn-setting-env"
                @click="watchKillRateConfig('test1killid')"
              >
                test1killid
              </button>
            </div>
            <div class="container-lottery-play-setting">
              <button
                class="btn-lottery-play-setting"
                @click="watchLotteryPlayCodeEnvConfig('test1')"
              >
                玩法設定
              </button>
            </div>
            <div class="container-env-close">
              <button class="btn-tips-close" @click="closeKillRateEnv('test1')">
                關閉
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal-displaySeclectKillRateEnvTest2">
    <div class="modal" v-if="displaySeclectKillRateEnvTest2">
      <div>
        <div v-if="displaySeclectKillRateEnvTest2" class="modal">
          <div class="modal-content">
            <span @click="closeKillRateEnv('test2')" class="btn-right-top-close"
              >&times;</span
            >
            <h3>選擇XX殺率環境 Test2</h3>
            <div class="container-env">
              <button
                class="btn-setting-env"
                @click="watchKillRateConfig('test2killin')"
              >
                test2killin
              </button>
              <button
                class="btn-setting-env"
                @click="watchKillRateConfig('test2killth')"
              >
                test2killth
              </button>
              <button
                class="btn-setting-env"
                @click="watchKillRateConfig('test2killuc')"
              >
                test2killuc
              </button>
              <button
                class="btn-setting-env"
                @click="watchKillRateConfig('test2killvn')"
              >
                test2killvn
              </button>
              <button
                class="btn-setting-env"
                @click="watchKillRateConfig('test2killid')"
              >
                test2killid
              </button>
            </div>
            <div class="container-lottery-play-setting">
              <button
                class="btn-lottery-play-setting"
                @click="watchLotteryPlayCodeEnvConfig('test2')"
              >
                玩法設定
              </button>
            </div>
            <div class="container-env-close">
              <button class="btn-tips-close" @click="closeKillRateEnv('test2')">
                關閉
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal-displaySeclectKillRateEnvTest3">
    <div class="modal" v-if="displaySeclectKillRateEnvTest3">
      <div>
        <div v-if="displaySeclectKillRateEnvTest3" class="modal">
          <div class="modal-content">
            <span @click="closeKillRateEnv('test3')" class="btn-right-top-close"
              >&times;</span
            >
            <h3>選擇彩票殺率環境 Test3</h3>
            <div class="container-env">
              <button
                class="btn-setting-env"
                @click="watchKillRateConfig('test3killin')"
              >
                test3killin
              </button>
              <button
                class="btn-setting-env"
                @click="watchKillRateConfig('test3killth')"
              >
                test3killth
              </button>
              <button
                class="btn-setting-env"
                @click="watchKillRateConfig('test3killuc')"
              >
                test3killuc
              </button>
              <button
                class="btn-setting-env"
                @click="watchKillRateConfig('test3killvn')"
              >
                test3killvn
              </button>
              <button
                class="btn-setting-env"
                @click="watchKillRateConfig('test3killid')"
              >
                test3killid
              </button>
            </div>
            <div class="container-lottery-play-setting">
              <button
                class="btn-lottery-play-setting"
                @click="watchLotteryPlayCodeEnvConfig('test3')"
              >
                玩法設定
              </button>
            </div>
            <div class="container-env-close">
              <button class="btn-tips-close" @click="closeKillRateEnv('test3')">
                關閉
              </button>
            </div>
            <!-- <button class="btn-submit" @click="submitDisplayEnvironmentConfig">
              編輯
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal-displaySeclectKillRateEnvTest4">
    <div class="modal" v-if="displaySeclectKillRateEnvTest4">
      <div>
        <div v-if="displaySeclectKillRateEnvTest4" class="modal">
          <div class="modal-content">
            <span @click="closeKillRateEnv('test4')" class="btn-right-top-close"
              >&times;</span
            >
            <h3>選擇彩票殺率環境 Test4</h3>
            <div class="container-env">
              <button
                class="btn-setting-env"
                @click="watchKillRateConfig('test4killin')"
              >
                test4killin
              </button>
              <button
                class="btn-setting-env"
                @click="watchKillRateConfig('test4killth')"
              >
                test4killth
              </button>
              <button
                class="btn-setting-env"
                @click="watchKillRateConfig('test4killuc')"
              >
                test4killuc
              </button>
              <button
                class="btn-setting-env"
                @click="watchKillRateConfig('test4killvn')"
              >
                test4killvn
              </button>
              <button
                class="btn-setting-env"
                @click="watchKillRateConfig('test4killid')"
              >
                test4killid
              </button>
            </div>
            <div class="container-lottery-play-setting">
              <button
                class="btn-lottery-play-setting"
                @click="watchLotteryPlayCodeEnvConfig('test4')"
              >
                玩法設定
              </button>
            </div>
            <div class="container-env-close">
              <button class="btn-tips-close" @click="closeKillRateEnv('test4')">
                關閉
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal-displaySeclectKillRateEnvPre">
    <div class="modal" v-if="displaySeclectKillRateEnvPre">
      <div>
        <div v-if="displaySeclectKillRateEnvPre" class="modal">
          <div class="modal-content">
            <span @click="closeKillRateEnv('pre')" class="btn-right-top-close"
              >&times;</span
            >
            <h3>選擇彩票殺率環境 Pre</h3>
            <div class="container-env">
              <button
                class="btn-setting-env"
                @click="watchKillRateConfig('prekillin')"
              >
                prekillin
              </button>
              <button
                class="btn-setting-env"
                @click="watchKillRateConfig('prekillth')"
              >
                prekillth
              </button>
              <button
                class="btn-setting-env"
                @click="watchKillRateConfig('prekilluc')"
              >
                prekilluc
              </button>
              <button
                class="btn-setting-env"
                @click="watchKillRateConfig('prekillvn')"
              >
                prekillvn
              </button>
              <button
                class="btn-setting-env"
                @click="watchKillRateConfig('prekillid')"
              >
                prekkillid
              </button>
            </div>
            <div class="container-lottery-play-setting">
              <button
                class="btn-lottery-play-setting"
                @click="watchLotteryPlayCodeEnvConfig('pre')"
              >
                玩法設定
              </button>
            </div>
            <div class="container-env-close">
              <button class="btn-tips-close" @click="closeKillRateEnv('pre')">
                關閉
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal-displayLotteryPlayCodeEnvConfigTest1">
    <div class="modal" v-if="displayLotteryPlayCodeEnvConfigTest1">
      <div>
        <div v-if="displayLotteryPlayCodeEnvConfigTest1" class="modal">
          <div class="modal-content">
            <span
              @click="closeLotteryPlayCodeEnvConfig('test1')"
              class="btn-right-top-close"
              >&times;</span
            >
            <h3>選擇彩票殺率環境 Test1</h3>
            <div class="container-env">
              <button
                class="btn-setting-env"
                @click="watchLotteryCodeConfig('test1killin')"
              >
                test1killin
              </button>
              <button
                class="btn-setting-env"
                @click="watchLotteryCodeConfig('test1killth')"
              >
                test1killth
              </button>
              <button
                class="btn-setting-env"
                @click="watchLotteryCodeConfig('test1killuc')"
              >
                test1killuc
              </button>
              <button
                class="btn-setting-env"
                @click="watchLotteryCodeConfig('test1killvn')"
              >
                test1killvn
              </button>
              <button
                class="btn-setting-env"
                @click="watchLotteryCodeConfig('test1killid')"
              >
                test1killid
              </button>
            </div>
            <div v-if="modify_env !== ''">
              <h3>選擇 {{ modify_env }} 環境的彩票種類</h3>
              <div class="config-container">
                <div class="choice-lottery-code-container">
                  <button
                    v-for="(code, index) in lotteryCode_ls"
                    :key="index"
                    class="btn-lottery-code"
                    @click="watchLotteryPlayCodeConfig(code)"
                  >
                    {{ code }}
                  </button>
                </div>
              </div>
            </div>
            <div class="container-env-close">
              <button
                class="btn-tips-close"
                @click="closeLotteryPlayCodeEnvConfig('test1')"
              >
                關閉
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal-displayLotteryPlayCodeEnvConfigTest2">
    <div class="modal" v-if="displayLotteryPlayCodeEnvConfigTest2">
      <div>
        <div v-if="displayLotteryPlayCodeEnvConfigTest2" class="modal">
          <div class="modal-content">
            <span
              @click="closeLotteryPlayCodeEnvConfig('test2')"
              class="btn-right-top-close"
              >&times;</span
            >
            <h3>選擇彩票殺率環境 Test2</h3>
            <div class="container-env">
              <button
                class="btn-setting-env"
                @click="watchLotteryCodeConfig('test2killin')"
              >
                test2killin
              </button>
              <button
                class="btn-setting-env"
                @click="watchLotteryCodeConfig('test2killth')"
              >
                test2killth
              </button>
              <button
                class="btn-setting-env"
                @click="watchLotteryCodeConfig('test2killuc')"
              >
                test2killuc
              </button>
              <button
                class="btn-setting-env"
                @click="watchLotteryCodeConfig('test2killvn')"
              >
                test2killvn
              </button>
              <button
                class="btn-setting-env"
                @click="watchLotteryCodeConfig('test2killid')"
              >
                test2killid
              </button>
            </div>
            <div v-if="modify_env !== ''">
              <h3>選擇 {{ modify_env }} 環境的彩票種類</h3>
              <div class="config-container">
                <div class="choice-lottery-code-container">
                  <button
                    v-for="(code, index) in lotteryCode_ls"
                    :key="index"
                    class="btn-lottery-code"
                    @click="watchLotteryPlayCodeConfig(code)"
                  >
                    {{ code }}
                  </button>
                </div>
              </div>
            </div>
            <div class="container-env-close">
              <button
                class="btn-tips-close"
                @click="closeLotteryPlayCodeEnvConfig('test2')"
              >
                關閉
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal-displayLotteryPlayCodeEnvConfigTest3">
    <div class="modal" v-if="displayLotteryPlayCodeEnvConfigTest3">
      <div>
        <div v-if="displayLotteryPlayCodeEnvConfigTest3" class="modal">
          <div class="modal-content">
            <span
              @click="closeLotteryPlayCodeEnvConfig('test3')"
              class="btn-right-top-close"
              >&times;</span
            >
            <h3>選擇彩票殺率環境 Test3</h3>
            <div class="container-env">
              <button
                class="btn-setting-env"
                @click="watchLotteryCodeConfig('test3killin')"
              >
                test3killin
              </button>
              <button
                class="btn-setting-env"
                @click="watchLotteryCodeConfig('test3killth')"
              >
                test3killth
              </button>
              <button
                class="btn-setting-env"
                @click="watchLotteryCodeConfig('test3killuc')"
              >
                test3killuc
              </button>
              <button
                class="btn-setting-env"
                @click="watchLotteryCodeConfig('test3killvn')"
              >
                test3killvn
              </button>
              <button
                class="btn-setting-env"
                @click="watchLotteryCodeConfig('test3killid')"
              >
                test3killid
              </button>
            </div>
            <div v-if="modify_env !== ''">
              <h3>選擇 {{ modify_env }} 環境的彩票種類</h3>
              <div class="config-container">
                <div class="choice-lottery-code-container">
                  <button
                    v-for="(code, index) in lotteryCode_ls"
                    :key="index"
                    class="btn-lottery-code"
                    @click="watchLotteryPlayCodeConfig(code)"
                  >
                    {{ code }}
                  </button>
                </div>
              </div>
            </div>
            <div class="container-env-close">
              <button
                class="btn-tips-close"
                @click="closeLotteryPlayCodeEnvConfig('test3')"
              >
                關閉
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal-displayLotteryPlayCodeEnvConfigTest4">
    <div class="modal" v-if="displayLotteryPlayCodeEnvConfigTest4">
      <div>
        <div v-if="displayLotteryPlayCodeEnvConfigTest4" class="modal">
          <div class="modal-content">
            <span
              @click="closeLotteryPlayCodeEnvConfig('test4')"
              class="btn-right-top-close"
              >&times;</span
            >
            <h3>選擇彩票殺率環境 Test4</h3>
            <div class="container-env">
              <button
                class="btn-setting-env"
                @click="watchLotteryCodeConfig('test4killin')"
              >
                test4killin
              </button>
              <button
                class="btn-setting-env"
                @click="watchLotteryCodeConfig('test4killth')"
              >
                test4killth
              </button>
              <button
                class="btn-setting-env"
                @click="watchLotteryCodeConfig('test4killuc')"
              >
                test4killuc
              </button>
              <button
                class="btn-setting-env"
                @click="watchLotteryCodeConfig('test4killvn')"
              >
                test4killvn
              </button>
              <button
                class="btn-setting-env"
                @click="watchLotteryCodeConfig('test4killid')"
              >
                test4killid
              </button>
            </div>
            <div v-if="modify_env !== ''">
              <h3>選擇 {{ modify_env }} 環境的彩票種類</h3>
              <div class="config-container">
                <div class="choice-lottery-code-container">
                  <button
                    v-for="(code, index) in lotteryCode_ls"
                    :key="index"
                    class="btn-lottery-code"
                    @click="watchLotteryPlayCodeConfig(code)"
                  >
                    {{ code }}
                  </button>
                </div>
              </div>
            </div>
            <div class="container-env-close">
              <button
                class="btn-tips-close"
                @click="closeLotteryPlayCodeEnvConfig('test4')"
              >
                關閉
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal-displayLotteryPlayCodeEnvConfigPre">
    <div class="modal" v-if="displayLotteryPlayCodeEnvConfigPre">
      <div>
        <div v-if="displayLotteryPlayCodeEnvConfigPre" class="modal">
          <div class="modal-content">
            <span
              @click="closeLotteryPlayCodeEnvConfig('pre')"
              class="btn-right-top-close"
              >&times;</span
            >
            <h3>選擇彩票殺率環境 Pre</h3>
            <div class="container-env">
              <button
                class="btn-setting-env"
                @click="watchLotteryCodeConfig('prekillin')"
              >
                prekillin
              </button>
              <button
                class="btn-setting-env"
                @click="watchLotteryCodeConfig('prekillth')"
              >
                prekillth
              </button>
              <button
                class="btn-setting-env"
                @click="watchLotteryCodeConfig('prekilluc')"
              >
                prekilluc
              </button>
              <button
                class="btn-setting-env"
                @click="watchLotteryCodeConfig('prekillvn')"
              >
                prekillvn
              </button>
              <button
                class="btn-setting-env"
                @click="watchLotteryCodeConfig('prekillid')"
              >
                prekillid
              </button>
            </div>
            <div v-if="modify_env !== ''">
              <h3>選擇 {{ modify_env }} 環境的彩票種類</h3>
              <div class="config-container">
                <div class="choice-lottery-code-container">
                  <button
                    v-for="(code, index) in lotteryCode_ls"
                    :key="index"
                    class="btn-lottery-code"
                    @click="watchLotteryPlayCodeConfig(code)"
                  >
                    {{ code }}
                  </button>
                </div>
              </div>
            </div>
            <div class="container-env-close">
              <button
                class="btn-tips-close"
                @click="closeLotteryPlayCodeEnvConfig('pre')"
              >
                關閉
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal-displayKillRateConfig">
    <div class="modal" v-if="displayKillRateConfig">
      <div>
        <div v-if="displayKillRateConfig" class="modal">
          <div class="modal-content">
            <span @click="closeKillRateConfig" class="btn-right-top-close"
              >&times;</span
            >
            <h3>彩票殺率設定檔</h3>
            <div class="config-container">
              <div class="check-box-size-all">
                <label>
                  <input
                    type="checkbox"
                    v-model="selectAll"
                    @change="SelectAllCheckbox"
                  />
                  全選
                </label>
              </div>
              <div class="check-box-size-two">
                <label
                  v-for="(item, index) in kill_rate_config"
                  :key="index"
                  :class="{
                    rdobutton_label: true,
                    checked: item.checked,
                  }"
                >
                  <input
                    class="rdobutton_radio"
                    type="checkbox"
                    name="config"
                    :value="item.value"
                    v-model="item.checked"
                    :id="'tag-' + index"
                  />
                  {{ item.value }}
                </label>
              </div>
            </div>
            <button class="btn-tips-submit" @click="modifyKillRateConfig">
              修改
            </button>
            <button class="btn-tips-close" @click="closeKillRateConfig">
              關閉
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal-displayLotteryPlayCodeConfig">
    <div class="modal" v-if="displayLotteryPlayCodeConfig">
      <div>
        <div v-if="displayLotteryPlayCodeConfig" class="modal">
          <div class="modal-content">
            <span
              @click="closeLotteryPlayCodeConfig"
              class="btn-right-top-close"
              >&times;</span
            >
            <h3>選擇 lottery code: {{ lottery_code }} 彩票玩法</h3>
            <div class="config-container">
              <div class="check-box-size-all">
                <label>
                  <input
                    type="checkbox"
                    v-model="filterAllLotteryPlayCode"
                    @change="SelectAllLotteryPlayCode"
                  />
                  全選
                </label>
              </div>
              <div class="check-box-size-two">
                <label
                  v-for="(item, index) in lottery_code_config"
                  :key="index"
                  :class="{
                    rdobutton_label: true,
                    checked: item.checked,
                  }"
                >
                  <input
                    class="rdobutton_radio"
                    type="checkbox"
                    name="lottery_play_code_config"
                    :value="item.value"
                    v-model="item.checked"
                    :id="'tag-' + index"
                  />
                  {{ item.value }}
                </label>
              </div>
            </div>
            <button
              class="btn-tips-submit"
              @click="modifyLotteryPlayCodeConfig"
            >
              修改
            </button>
            <button class="btn-tips-close" @click="closeLotteryPlayCodeConfig">
              關閉
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <div class="loading-overlay-two" v-if="loading">
    <div class="spinner"></div>
  </div>
</template>

<script>
import Sidebar from "@/components/sidebar/SidebarCom";
import {
  handelSuccessAlertUtil,
  handelErrorAlertUtil,
  handelFailAlertUtil,
} from "@/commonUtils.js";

export default {
  // 綁定元件
  components: { Sidebar },
  data() {
    return {
      version: "",
      loading: false,
      intervalId: null,
      switchState: false,
      isTimerRunning: false,
      displayKillRateConfig: false,
      displayLotteryPlayCodeConfig: false,
      displayLotteryPlayCodeEnvConfig: false,
      displaySeclectKillRateEnvTest1: false,
      displaySeclectKillRateEnvTest2: false,
      displaySeclectKillRateEnvTest3: false,
      displaySeclectKillRateEnvTest4: false,
      displaySeclectKillRateEnvPre: false,
      displayLotteryPlayCodeEnvConfigTest1: false,
      displayLotteryPlayCodeEnvConfigTest2: false,
      displayLotteryPlayCodeEnvConfigTest3: false,
      displayLotteryPlayCodeEnvConfigTest4: false,
      displayLotteryPlayCodeEnvConfigPre: false,
      modify_env: "",
      lottery_code: "",
      selectAll: false,
      filterAllLotteryPlayCode: false,
      kill_rate_config: [],
      lottery_code_config: [],
      lotteryCode_ls: [
        "1001",
        "1002",
        "1003",
        "1004",
        "1005",
        "1006",
        "1007",
        "1008",
        "1009",
        "1010",
        "1011",
        "1012",
        "1013",
        "1014",
        "1015",
        "1016",
        "1017",
        "1018",
        "1020",
        "1021",
        "1022",
        "1023",
        "1024",
        "1025",
        "1026",
        "1027",
        "1028",
        "1029",
        "1030",
        "1031",
        "1032",
        "1033",
        "1034",
        "1035",
        "1036",
        "1037",
        "1038",
        "1039",
        "1040",
        "1041",
        "1042",
        "1043",
        "1044",
        "1101",
        "1102",
        "1103",
        "1201",
        "1202",
        "1203",
        "1301",
        "1302",
        "1303",
        "1350",
        "1352",
        "1353",
        "1401",
        "1402",
        "1403",
        "1404",
        "1405",
        "1406",
        "1407",
        "1450",
        "1452",
        "1453",
        "1501",
        "1502",
        "1503",
        "1650",
        "1652",
        "1653",
      ],
      seconds: 0,

      test1KillStatus: "",
      test2KillStatus: "",
      test3KillStatus: "",
      test4KillStatus: "",
      preKillStatus: "",

      test1KillStatusColor: "",
      test2KillStatusColor: "",
      test3KillStatusColor: "",
      test4KillStatusColor: "",
      preKillStatusColor: "",

      kill_rate_config_status: "",
      lottery_play_code_config_status: "",
    };
  },
  // 進入 component 頁面則發送請求
  created() {
    this.checkKillStatus();
    this.stopSchedule();
    this.checkVersion();
  },
  methods: {
    checkVersion() {
      const url = "/v1/api/fetch_version";
      this.axios
        .post(url)
        .then((response) => {
          const status = response.data.status;

          // 在這裡處理成功回應的邏輯
          if (status === "1") {
            const version = response.data.version;
            this.version = version;
          } else if (status === "-1") {
            handelFailAlertUtil(response);
          } else {
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          handelErrorAlertUtil(error);
        });
    },
    setStatus(apiName, apiStatus) {
      if (apiStatus === "running") {
        this[`${apiName}Status`] = "running";
        this[`${apiName}StatusColor`] = "green";
      }
      if (apiStatus === "idle") {
        this[`${apiName}Status`] = "idle";
        this[`${apiName}StatusColor`] = "orange";
      }
    },
    // 確認殺率狀態
    checkKillStatus() {
      const url = "/v1/api/kill_rate_status";
      this.loading = true;
      this.axios
        .post(url)
        .then((response) => {
          const result = response.data.result;
          this.setStatus("test1Kill", result.test1Kill);
          this.setStatus("test2Kill", result.test2Kill);
          this.setStatus("test3Kill", result.test3Kill);
          this.setStatus("test4Kill", result.test4Kill);
          this.setStatus("preKill", result.preKill);
          // 在這裡處理成功回應的邏輯
        })
        .catch((error) => {
          handelErrorAlertUtil(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 關閉排程
    stopSchedule() {
      const task = localStorage.getItem("task");
      clearInterval(task);
      this.intervalId = null;
      this.switchState = false;
      this.isTimerRunning = false;
    },
    killRate(env, switchState) {
      const url = "/v1/api/kill_rate";
      this.axios
        .post(url, {
          env: env,
          switchState: switchState,
        })
        .then((response) => {
          const status = response.data.status;
          if (status == "1") {
            handelSuccessAlertUtil(response);
          } else if (status == "-1") {
            handelFailAlertUtil(response);
          } else {
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          handelErrorAlertUtil(error);
        });
    },
    closekillRate(env, switchState) {
      const url = "/v1/api/kill_rate";
      this.axios
        .post(url, {
          env: env,
          switchState: switchState,
        })
        .then((response) => {
          const status = response.data.status;
          if (status == "1") {
            handelSuccessAlertUtil(response);
          } else if (status == "-1") {
            handelFailAlertUtil(response);
          } else {
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          handelErrorAlertUtil(error);
        });
    },
    toggleTimer() {
      this.isTimerRunning = !this.isTimerRunning;
      if (this.isTimerRunning) {
        this.intervalId = setInterval(() => {
          this.seconds++;
          this.axios
            .post("/v1/api/kill_rate_status")
            .then((response) => {
              const result = response.data.result;
              const test1Kill = result.test1Kill;
              const test2Kill = result.test2Kill;
              const test3Kill = result.test3Kill;
              const test4Kill = result.test4Kill;
              const preKill = result.preKill;

              if (test1Kill == "running") {
                this.test1KillStatus = "running";
                this.test1KillStatusColor = "green";
              }
              if (test1Kill == "idle") {
                this.test1KillStatus = "idle";
                this.test1KillStatusColor = "orange";
              }
              if (test2Kill == "running") {
                this.test2KillStatus = "running";
                this.test2KillStatusColor = "green";
              }
              if (test2Kill == "idle") {
                this.test2KillStatus = "idle";
                this.test2KillStatusColor = "orange";
              }
              if (test3Kill == "running") {
                this.test3KillStatus = "running";
                this.test3KillStatusColor = "green";
              }
              if (test3Kill == "idle") {
                this.test3KillStatus = "idle";
                this.test3KillStatusColor = "orange";
              }
              if (test4Kill == "running") {
                this.test4KillStatus = "running";
                this.test4KillStatusColor = "green";
              }
              if (test4Kill == "idle") {
                this.test4KillStatus = "idle";
                this.test4KillStatusColor = "orange";
              }
              if (preKill == "running") {
                this.preKillStatus = "running";
                this.preKillStatusColor = "green";
              }
              if (preKill == "idle") {
                this.preKillStatus = "idle";
                this.preKillStatusColor = "orange";
              }
            })
            .catch((error) => {
              // 在這裡處理錯誤回應的邏輯
              error.data;
              this.stopSchedule(); // 關閉排程
            });
        }, 5000);
        localStorage.setItem("task", this.intervalId);
      } else {
        this.stopSchedule(); // 關閉排程
      }
    },
    watchKillRateConfig(env) {
      this.fetchKillRateConfig(env);
      this.displayKillRateConfig = true;
      this.modify_env = env;
    },
    // 查看環境設定
    watchLotteryPlayCodeEnvConfig(env) {
      if (env.includes("test1")) {
        this.displayLotteryPlayCodeEnvConfigTest1 = true;
      } else if (env.includes("test2")) {
        this.displayLotteryPlayCodeEnvConfigTest2 = true;
      } else if (env.includes("test3")) {
        this.displayLotteryPlayCodeEnvConfigTest3 = true;
      } else if (env.includes("test4")) {
        this.displayLotteryPlayCodeEnvConfigTest4 = true;
      } else if (env.includes("pre")) {
        this.displayLotteryPlayCodeEnvConfigPre = true;
      }
    },
    // 查看玩法設定
    watchLotteryPlayCodeConfig(lottery_code) {
      this.displayLotteryPlayCodeConfig = true;
      this.lottery_code = lottery_code;
      this.fetchLotteryPlayCodeConfig();
    },
    // 查看彩票種類
    watchLotteryCodeConfig(env) {
      this.modify_env = env;
    },
    watchKillRateEnv(env) {
      if (env.includes("test1")) {
        this.displaySeclectKillRateEnvTest1 = true;
      } else if (env.includes("test2")) {
        this.displaySeclectKillRateEnvTest2 = true;
      } else if (env.includes("test3")) {
        this.displaySeclectKillRateEnvTest3 = true;
      } else if (env.includes("test4")) {
        this.displaySeclectKillRateEnvTest4 = true;
      } else if (env.includes("pre")) {
        this.displaySeclectKillRateEnvPre = true;
      }
    },
    closeKillRateConfig() {
      this.displayKillRateConfig = false;
    },
    closeLotteryPlayCodeConfig() {
      this.displayLotteryPlayCodeConfig = false;
    },
    closeKillRateEnv(env) {
      if (env.includes("test1")) {
        this.displaySeclectKillRateEnvTest1 = false;
      } else if (env.includes("test2")) {
        this.displaySeclectKillRateEnvTest2 = false;
      } else if (env.includes("test3")) {
        this.displaySeclectKillRateEnvTest3 = false;
      } else if (env.includes("test4")) {
        this.displaySeclectKillRateEnvTest4 = false;
      } else if (env.includes("pre")) {
        this.displaySeclectKillRateEnvPre = false;
      }
    },
    closeLotteryPlayCodeEnvConfig(env) {
      this.modify_env = "";
      if (env.includes("test1")) {
        this.displayLotteryPlayCodeEnvConfigTest1 = false;
      } else if (env.includes("test2")) {
        this.displayLotteryPlayCodeEnvConfigTest2 = false;
      } else if (env.includes("test3")) {
        this.displayLotteryPlayCodeEnvConfigTest3 = false;
      } else if (env.includes("test4")) {
        this.displayLotteryPlayCodeEnvConfigTest4 = false;
      } else if (env.includes("pre")) {
        this.displayLotteryPlayCodeEnvConfigPre = false;
      }
    },
    SelectAllCheckbox() {
      this.kill_rate_config.forEach((item) => {
        item.checked = this.selectAll;
      });
    },
    SelectAllLotteryPlayCode() {
      this.lottery_code_config.forEach((item) => {
        item.checked = this.filterAllLotteryPlayCode;
      });
    },
    querySelectorComicConfig() {
      const selected_kill_rate_config_status = document.querySelectorAll(
        'input[name="config"]:checked'
      );
      const selected_kill_rate_config_status_values = Array.from(
        selected_kill_rate_config_status
      ).map((input) => input.value);

      const not_selecte_kill_rate_config_status = document.querySelectorAll(
        'input[name="config"]:not(:checked)'
      );
      const not_selecte_kill_rate_config_status_values = Array.from(
        not_selecte_kill_rate_config_status
      ).map((input) => input.value);

      const selecdtValues = selected_kill_rate_config_status_values.join(",");

      const NotSelectValues =
        not_selecte_kill_rate_config_status_values.join(",");

      console.log(NotSelectValues);

      let result = {};
      let items = selecdtValues.split(",");

      let NotItems = NotSelectValues.split(",");

      items.forEach((item) => {
        result[item] = "on";
      });

      NotItems.forEach((NotItem) => {
        if (NotItem !== "") {
          result[NotItem] = "off";
        }
      });

      this.kill_rate_config_status = result;
    },
    querySelectorLotteryPlayCodeConfig() {
      const selected_lottery_play_code_config_status =
        document.querySelectorAll(
          'input[name="lottery_play_code_config"]:checked'
        );
      const selected_lottery_play_code_config_status_values = Array.from(
        selected_lottery_play_code_config_status
      ).map((input) => input.value);

      const not_lottery_play_code_config_status = document.querySelectorAll(
        'input[name="lottery_play_code_config"]:not(:checked)'
      );
      const not_selecte_lottery_play_code_config_status_values = Array.from(
        not_lottery_play_code_config_status
      ).map((input) => input.value);

      const selecdtValues =
        selected_lottery_play_code_config_status_values.join(",");

      const NotSelectValues =
        not_selecte_lottery_play_code_config_status_values.join(",");

      console.log(NotSelectValues);

      let result = {};
      let items = selecdtValues.split(",");

      let NotItems = NotSelectValues.split(",");

      items.forEach((item) => {
        result[item] = "on";
      });

      NotItems.forEach((NotItem) => {
        if (NotItem !== "") {
          result[NotItem] = "off";
        }
      });

      this.lottery_play_code_config_status = result;
    },
    fetchKillRateConfig(env) {
      const url = "/v1/api/fetch_kill_rate_configration";
      this.axios
        .post(url, {
          env: env,
        })
        .then((response) => {
          const status = response.data.status;
          const result = response.data.result;
          if (status == "1") {
            this.kill_rate_config = result;
          } else if (status == "-1") {
            handelFailAlertUtil(response);
          } else {
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          handelErrorAlertUtil(error);
        });
    },
    fetchLotteryPlayCodeConfig() {
      const url = "/v1/api/fetch_lottery_play_code_configration";
      this.axios
        .post(url, {
          lottery_site: this.modify_env,
          lottery_code: this.lottery_code,
        })
        .then((response) => {
          const status = response.data.status;
          const result = response.data.result;
          if (status == "1") {
            this.lottery_code_config = result;
          } else if (status == "-1") {
            handelFailAlertUtil(response);
          } else {
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          handelErrorAlertUtil(error);
        });
    },
    modifyKillRateConfig() {
      const url = "/v1/api/modify_kill_rate_configration";
      this.querySelectorComicConfig();
      this.axios
        .post(url, {
          env: this.modify_env,
          schedule_config: this.kill_rate_config_status,
        })
        .then((response) => {
          const status = response.data.status;
          const result = response.data.result;
          if (status == "1") {
            this.kill_rate_config = result;
            this.fetchKillRateConfig(this.modify_env);
            handelSuccessAlertUtil(response);
          } else if (status == "-1") {
            handelFailAlertUtil(response);
          } else {
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          handelErrorAlertUtil(error);
        });
    },
    modifyLotteryPlayCodeConfig() {
      const url = "/v1/api/modify_kill_rate_playDetailCode_config";
      this.querySelectorLotteryPlayCodeConfig();
      this.axios
        .post(url, {
          lottery_site: this.modify_env,
          lottery_code: this.lottery_code,
          status: this.lottery_play_code_config_status,
        })
        .then((response) => {
          const status = response.data.status;
          const result = response.data.result;
          if (status == "1") {
            this.lottery_code_config = result;
            this.fetchLotteryPlayCodeConfig();
            handelSuccessAlertUtil(response);
          } else if (status == "-1") {
            handelFailAlertUtil(response);
          } else {
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          handelErrorAlertUtil(error);
        });
    },
  },
};
</script>
