import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ElementPlus from "element-plus";
import axios from "axios";
import "@fortawesome/fontawesome-free/js/all";
import "element-plus/dist/index.css";
import "./global.css"; // 引入全局样式文件

const app = createApp(App);
/* eslint-disable */

app.config.globalProperties.axios = axios;
app.config.globalProperties.axios.defaults.baseURL = "http://localhost:5001";

/* eslint-enable */
app.use(router);
app.use(ElementPlus);
app.mount("#app");
