<template>
  <Sidebar />
  <div class="container">
    <div class="title">推送計畫管理</div>
    <NavBar />
    <div class="filter-container">
      <div class="filter">
        <label for="lottery_type">彩種類型</label>
        <select
          class="lottery-type-box"
          id="lottery_type"
          v-model="lottery_type"
        >
          <template v-if="currentDomain === 'international'">
            <option value="all">全部</option>
            <option value="sicbo">sicbo</option>
            <option value="keno">keno</option>
            <option value="yncp">yncp</option>
            <option value="four_d">four_d</option>
            <option value="lotto">lotto</option>
            <option value="trx">trx</option>
            <option value="thl">thl</option>
          </template>
          <template v-else-if="currentDomain === 'domestic'">
            <option value="all">全部</option>
            <option value="ssc">ssc</option>
            <option value="pk10">pk10</option>
            <option value="lhc">lhc</option>
            <option value="k3">k3</option>
            <option value="kl8">kl8</option>
          </template>
        </select>
      </div>
      <div class="filter">
        <label for="push_plan_status">狀態</label>
        <select
          class="status-box"
          id="push_plan_status"
          v-model="push_plan_status"
        >
          <option value="2">全部</option>
          <option value="1">打開</option>
          <option value="0">關閉</option>
        </select>
      </div>
      <div class="filter">
        <label for="envir">環境</label>
        <select class="status-box" id="envir" v-model="envir">
          <option value="test1">test1</option>
          <option value="test2">test2</option>
          <option value="test3">test3</option>
          <option value="test4">test4</option>
          <option value="pre">pre</option>
        </select>
      </div>
      <div class="filter">
        <button class="btn-reset" @click="resetFilters">重置</button>
        <button class="btn-query" @click="searchPushPlan">查詢</button>
      </div>
    </div>
    <div class="top-button-container">
      <!-- 開啟爬蟲 -->
      <div class="popover-container">
        <div
          class="popover-content"
          v-if="showPopover"
          @mouseover="showPopover = true"
          @mouseout="showPopover = false"
        >
          {{ content }}
        </div>
        <button
          class="btn-crawler"
          @click="controlCrawler('open', 'weline')"
          @mouseover="showPopover = true"
          @mouseout="showPopover = false"
        >
          {{ triggerText }}
        </button>
      </div>
      <button
        class="btn-crawler-close"
        @click="controlCrawler('close', 'weline')"
      >
        關閉微聊爬蟲
      </button>
      <button class="btn-token" @click="displayUpdateToken()">更新token</button>
      <button class="btn-check-lose" @click="displayCheckLoseConfig()">
        補漏設定
      </button>
      <button
        class="btn-add-account setting-width-120"
        @click="createAccount = !createAccount"
      >
        +新增帳號設定
      </button>
      <button
        class="btn-add-account setting-width-160"
        @click="batchCreateAccount = !batchCreateAccount"
      >
        +批量新增帳號設定
      </button>
      <button class="btn-online" @click="displayDescription()">說明</button>
    </div>
    <div class="top-button-container">
      <!-- 開啟爬蟲 -->
      <template v-if="currentDomain === 'domestic'">
        <button class="btn-crawler" @click="controlCrawler('open', 'lottery')">
          開啟平台爬蟲
        </button>
        <button
          class="btn-crawler-close"
          @click="controlCrawler('close', 'lottery')"
        >
          關閉平台爬蟲
        </button>
      </template>
    </div>
    <div class="filter-container">
      <button class="btn-select-all" @click="toggleSelectAll">
        {{ selectAll ? "取消" : "全選" }}
      </button>
      <button class="btn-online" @click="batchOpenPushPlan()">打開</button>
      <button class="btn-offline" @click="batchClosePushPlan()">關閉</button>
      <button class="btn-delete" @click="batchDeletePushPlan()">刪除</button>
      <button
        class="btn-token-status"
        :style="{ backgroundColor: tokenUiStatus }"
      >
        token {{ tokenStatus }}
      </button>
      <button
        class="btn-crawler-status"
        :style="{ backgroundColor: crawlerUiStatus }"
      >
        爬蟲 {{ crawlerStatus }}
      </button>
      <button class="btn-online" @click="refreshCrawlerStatus()">刷新</button>
    </div>

    <div class="push-plan-table-container">
      <div class="pagination">
        <button
          @click="changePage(currentPage - 1)"
          :disabled="currentPage <= 1"
        >
          上一頁
        </button>

        <!-- 顯示計算後的頁碼範圍 -->
        <button
          v-for="page in paginatedPages"
          :key="page"
          @click="changePage(page)"
          :class="{ active: page === currentPage }"
        >
          {{ page }}
        </button>

        <button
          @click="changePage(currentPage + 1)"
          :disabled="currentPage >= totalPages"
        >
          下一頁
        </button>
      </div>
      <table class="push-plan-table">
        <thead>
          <tr>
            <th>帳號</th>
            <th>計畫名稱</th>
            <th>平台域名</th>
            <th>環境</th>
            <th>彩種類型</th>
            <th>微聊狀態</th>
            <template v-if="currentDomain === 'domestic'">
              <th>彩票狀態</th>
            </template>
            <th>微聊管理</th>
            <template v-if="currentDomain === 'domestic'">
              <th>彩票管理</th>
            </template>
          </tr>
        </thead>
        <tbody>
          <tr v-for="book in books" :key="book.id">
            <td>
              <input
                type="checkbox"
                :checked="book.selected"
                v-model="book.selected"
              />{{ book.account }}
            </td>
            <td>{{ book.group_name }}</td>
            <td>{{ book.domain }}</td>
            <td>{{ book.env }}</td>
            <td>{{ book.lottery_type }}</td>
            <td>
              <!-- 微聊狀態欄位 -->
              <div class="switch-container">
                <label class="push-plan-switch-status">
                  <template v-if="book.weline_statement === 'on'">
                    <input
                      type="checkbox"
                      @click="toggleSwitch(book.id, 'off', 'weline')"
                      checked />
                    <span class="slider"></span
                  ></template>
                  <template v-if="book.weline_statement === 'off'">
                    <input
                      type="checkbox"
                      @click="toggleSwitch(book.id, 'on', 'weline')" />
                    <span class="slider"></span
                  ></template>
                </label>
              </div>
            </td>
            <!-- 彩票狀態欄位 -->
            <template v-if="currentDomain === 'domestic'">
              <td>
                <div class="switch-container">
                  <label class="push-plan-switch-status">
                    <!-- 目前國際版沒有聊天室 -->
                    <template
                      v-if="
                        book.lottery_statement === 'on' &&
                        currentDomain === 'domestic'
                      "
                    >
                      <input
                        type="checkbox"
                        @click="toggleSwitch(book.id, 'off', 'lottery')"
                        checked />
                      <span class="slider"></span
                    ></template>
                    <template
                      v-if="
                        book.lottery_statement === 'off' &&
                        currentDomain === 'domestic'
                      "
                    >
                      <input
                        type="checkbox"
                        @click="toggleSwitch(book.id, 'on', 'lottery')" />
                      <span class="slider"></span
                    ></template>
                  </label>
                </div>
              </td>
            </template>

            <td>
              <div class="management-container">
                <button
                  class="btn-edit-smail"
                  @click="
                    setFormFieldAndShowPushPlan(
                      book.id,
                      book.account,
                      book.domain,
                      book.group_name,
                      book.lottery_type,
                      book.env,
                      book.weline_statement,
                      'weline'
                    )
                  "
                >
                  編輯
                </button>
              </div>
            </td>
            <template v-if="currentDomain === 'domestic'">
              <td>
                <div class="management-container">
                  <button
                    class="btn-edit-smail"
                    @click="
                      setFormFieldAndShowPushPlan(
                        book.id,
                        book.account,
                        book.domain,
                        book.group_name,
                        book.lottery_type,
                        book.env,
                        book.lottery_statement,
                        'lottery'
                      )
                    "
                  >
                    編輯
                  </button>
                </div>
              </td></template
            >
          </tr>
        </tbody>
      </table>
      <div class="pagination">
        <button
          @click="changePage(currentPage - 1)"
          :disabled="currentPage <= 1"
        >
          上一頁
        </button>

        <!-- 顯示計算後的頁碼範圍 -->
        <button
          v-for="page in paginatedPages"
          :key="page"
          @click="changePage(page)"
          :class="{ active: page === currentPage }"
        >
          {{ page }}
        </button>

        <button
          @click="changePage(currentPage + 1)"
          :disabled="currentPage >= totalPages"
        >
          下一頁
        </button>
      </div>
      <!-- <div class="pagination">
        <button class="prev-button" @click="goToPreviousPage">上頁</button>
        <div class="current-page">{{ page }}</div>
        <button class="next-button" @click="goToNextPage">下頁</button>
      </div>
      <div class="total-pages">
        <div class="current-page">總頁數： {{ totalPages }}</div>
      </div> -->
    </div>
  </div>

  <transition name="modal-createAccount">
    <div class="modal" v-if="createAccount">
      <div>
        <div v-if="createAccount" class="modal">
          <div class="modal-content">
            <span @click="closeCreateAccount" class="btn-right-top-close"
              >&times;</span
            >
            <h3>新增推送計畫帳號</h3>
            <div class="container setting-mgn-btn-20">
              <div class="filter">
                <label for="AddAccount_push_account">推送帳號</label>
                <input
                  class="search-box"
                  type="text"
                  id="AddAccount_push_account"
                  placeholder="sicbo0001"
                  v-model="AddAccount_push_account"
                />
              </div>
              <div class="filter">
                <label for="AddAccount_group_name">群組名稱</label>
                <input
                  class="search-box"
                  type="text"
                  id="AddAccount_group_name"
                  placeholder="sicbo 大小單雙"
                  v-model="AddAccount_group_name"
                />
              </div>
              <div class="filter">
                <label for="AddAccount_domain">域名設定</label>
                <input
                  class="search-box"
                  type="text"
                  id="AddAccount_domain"
                  placeholder="請輸入平台域名"
                  v-model="AddAccount_domain"
                />
              </div>
              <div class="filter">
                <label for="AddAccount_lottery_type">彩種類型</label>
                <select
                  class="type-box setting-width-206"
                  id="AddAccount_lottery_type"
                  v-model="AddAccount_lottery_type"
                >
                  <template v-if="currentDomain === 'international'">
                    <option value="sicbo">sicbo</option>
                    <option value="keno">keno</option>
                    <option value="yncp">yncp</option>
                    <option value="four_d">four_d</option>
                    <option value="lotto">lotto</option>
                    <option value="trx">trx</option>
                    <option value="thl">thl</option>
                  </template>
                  <template v-else-if="currentDomain === 'domestic'">
                    <option value="ssc">ssc</option>
                    <option value="pk10">pk10</option>
                    <option value="lhc">lhc</option>
                    <option value="k3">k3</option>
                    <option value="kl8">kl8</option>
                  </template>
                </select>
              </div>
              <div class="filter">
                <label for="AddAccount_select_env">選擇環境</label>
                <select
                  class="type-box setting-width-206"
                  id="AddAccount_select_env"
                  v-model="AddAccount_select_env"
                >
                  <option value="test1">test1</option>
                  <option value="test2">test2</option>
                  <option value="test3">test3</option>
                  <option value="test4">test4</option>
                  <option value="pre">pre</option>
                </select>
              </div>
              <div class="filter">
                <label for="AddAccount_crawler_status">爬蟲狀態</label>
                <select
                  class="type-box setting-width-206"
                  id="AddAccount_crawler_status"
                  v-model="AddAccount_crawler_status"
                >
                  <option value="on">on</option>
                  <option value="off">off</option>
                </select>
              </div>
            </div>

            <button class="btn-tips-close" @click="closeCreateAccount">
              關閉
            </button>
            <button class="btn-tips-submit" @click="submitCreateAccount">
              提交
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal-batchCreateAccount">
    <div class="modal" v-if="batchCreateAccount">
      <div>
        <div v-if="batchCreateAccount" class="modal">
          <div class="modal-content">
            <span @click="closeBatchCreateAccount" class="btn-right-top-close"
              >&times;</span
            >
            <h3>批量新增推送計畫帳號設定</h3>
            <div class="addPushPlan-container">
              <div class="management-container">
                <div class="push-plan-container-row">
                  <label for="batchCreateAccount_push_account">推送帳號</label>
                  <textarea
                    class="push-plan-many-box"
                    type="text"
                    id="batchCreateAccount_push_account"
                    placeholder="sicob0001,sicob0002"
                    v-model="batchCreateAccount_push_account"
                  ></textarea>
                </div>
                <div class="push-plan-container-row">
                  <label for="batchCreateAccount_plan_name">群組名稱</label>
                  <textarea
                    class="push-plan-many-box"
                    type="text"
                    id="batchCreateAccount_plan_name"
                    placeholder="sicob 大小單雙,sicbo 單雙"
                    v-model="batchCreateAccount_plan_name"
                  ></textarea>
                </div>
              </div>
              <div class="filter">
                <label for="batchCreateAccount_domain">域名設定</label>
                <input
                  class="search-box"
                  placeholder="請輸入平台域名"
                  type="text"
                  id="batchCreateAccount_domain"
                  v-model="batchCreateAccount_domain"
                />
              </div>
              <div class="filter">
                <label for="batchCreateAccount_lottery_type">彩種類型</label>
                <select
                  class="type-box setting-width-206"
                  id="batchCreateAccount_lottery_type"
                  v-model="batchCreateAccount_lottery_type"
                >
                  <template v-if="currentDomain === 'international'">
                    <option value="sicbo">sicbo</option>
                    <option value="keno">keno</option>
                    <option value="yncp">yncp</option>
                    <option value="four_d">four_d</option>
                    <option value="lotto">lotto</option>
                    <option value="trx">trx</option>
                    <option value="thl">thl</option>
                  </template>
                  <template v-else-if="currentDomain === 'domestic'">
                    <option value="ssc">ssc</option>
                    <option value="pk10">pk10</option>
                    <option value="lhc">lhc</option>
                    <option value="k3">k3</option>
                    <option value="kl8">kl8</option>
                  </template>
                </select>
              </div>
              <div class="filter">
                <label for="batchCreateAccount_select_env">選擇環境</label>
                <select
                  class="type-box setting-width-206"
                  id="batchCreateAccount_select_env"
                  v-model="batchCreateAccount_select_env"
                >
                  <option value="test1">test1</option>
                  <option value="test2">test2</option>
                  <option value="test3">test3</option>
                  <option value="test4">test4</option>
                  <option value="pre">pre</option>
                </select>
              </div>
              <div class="filter">
                <label for="batchCreateAccount_crawler_status">爬蟲狀態</label>
                <select
                  class="type-box setting-width-206"
                  id="batchCreateAccount_crawler_status"
                  v-model="batchCreateAccount_crawler_status"
                >
                  <option value="on">on</option>
                  <option value="off">off</option>
                </select>
              </div>
            </div>

            <button class="btn-tips-close" @click="closeBatchCreateAccount">
              關閉
            </button>
            <button class="btn-tips-submit" @click="submitBatchCreateAccount">
              提交
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal-editPushPlan">
    <div class="modal" v-if="editPushPlan">
      <div>
        <div v-if="editPushPlan" class="modal">
          <div class="modal-content">
            <span @click="closeEditPushPlan" class="btn-right-top-close"
              >&times;</span
            >
            <h3>修改推送計畫參數</h3>
            <div class="addPushPlan-container">
              <div class="filter">
                <label for="editPushPlan_push_account">推送帳號</label>
                <input
                  class="search-box"
                  type="text"
                  id="editPushPlan_push_account"
                  placeholder="sicboPush001"
                  v-model="editPushPlan_push_account"
                />
              </div>
              <div class="filter">
                <label for="editPushPlan_group_name">群組名稱</label>
                <input
                  class="search-box"
                  type="text"
                  id="editPushPlan_group_name"
                  placeholder="sicbo 大小單雙"
                  v-model="editPushPlan_group_name"
                />
              </div>
              <div class="filter">
                <label for="editPushPlan_domain">域名設定</label>
                <input
                  class="search-box"
                  type="text"
                  id="editPushPlan_domain"
                  placeholder="請輸入平台域名"
                  v-model="editPushPlan_domain"
                />
              </div>
              <div class="filter">
                <label for="editPushPlan_lottery_type">彩種類型</label>
                <select
                  class="type-box setting-width-206"
                  id="editPushPlan_lottery_type"
                  v-model="editPushPlan_lottery_type"
                >
                  <template v-if="currentDomain === 'international'">
                    <option value="sicbo">sicbo</option>
                    <option value="keno">keno</option>
                    <option value="yncp">yncp</option>
                    <option value="four_d">four_d</option>
                    <option value="lotto">lotto</option>
                    <option value="trx">trx</option>
                    <option value="thl">thl</option>
                  </template>
                  <template v-else-if="currentDomain === 'domestic'">
                    <option value="ssc">ssc</option>
                    <option value="pk10">pk10</option>
                    <option value="lhc">lhc</option>
                    <option value="k3">k3</option>
                    <option value="kl8">kl8</option>
                  </template>
                </select>
              </div>
              <div class="filter">
                <label for="editPushPlan_select_env">選擇環境</label>
                <select
                  class="type-box setting-width-206"
                  id="editPushPlan_select_env"
                  v-model="editPushPlan_select_env"
                >
                  <option value="test1">test1</option>
                  <option value="test2">test2</option>
                  <option value="test3">test3</option>
                  <option value="test4">test4</option>
                  <option value="pre">pre</option>
                </select>
              </div>
              <div class="filter">
                <label for="editPushPlan_crawler_status">爬蟲狀態</label>
                <select
                  class="type-box setting-width-206"
                  id="editPushPlan_crawler_status"
                  v-model="editPushPlan_crawler_status"
                >
                  <option value="on">on</option>
                  <option value="off">off</option>
                </select>
              </div>
            </div>
            <button class="btn-tips-submit" @click="submitEditPushPlan">
              提交
            </button>
            <button class="btn-tips-close" @click="closeEditPushPlan">
              關閉
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal-showUpdateToken">
    <div class="modal" v-if="showUpdateToken">
      <div>
        <div v-if="showUpdateToken" class="modal">
          <div class="modal-content">
            <span @click="closeDisplayUpdateToken" class="btn-right-top-close"
              >&times;</span
            >
            <h3>更新推送計畫帳號 token</h3>
            <div class="addPushPlan-container">
              <div class="filter">
                <label for="showUpdateToken_domain">連線域名</label>
                <input
                  class="search-box"
                  type="text"
                  id="showUpdateToken_domain"
                  placeholder="請輸入微聊域名"
                  v-model="showUpdateToken_domain"
                />
              </div>
              <div class="filter">
                <label for="showUpdateToken_select_env">選擇環境</label>
                <select
                  class="type-box setting-width-206"
                  id="showUpdateToken_select_env"
                  v-model="showUpdateToken_select_env"
                >
                  <option value="test1">test1</option>
                  <option value="test2">test2</option>
                  <option value="test3">test3</option>
                  <option value="test4">test4</option>
                  <option value="pre">pre</option>
                </select>
              </div>
              <div class="filter">
                <label for="showUpdateToken_crawler_status">爬蟲狀態</label>
                <select
                  class="type-box setting-width-206"
                  id="showUpdateToken_crawler_status"
                  v-model="showUpdateToken_crawler_status"
                >
                  <option value="on">on</option>
                  <option value="off">off</option>
                </select>
              </div>
            </div>

            <button class="btn-tips-close" @click="closeDisplayUpdateToken">
              關閉
            </button>
            <button class="btn-tips-update" @click="submitUpdateToken">
              更新
            </button>
            <button class="btn-tips-enable" @click="enableUpdateToken">
              啟動
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal-showCheckLose">
    <div class="modal" v-if="showCheckLose">
      <div>
        <div v-if="showCheckLose" class="modal">
          <div class="modal-content">
            <span @click="closeDisplayCheckLose" class="btn-right-top-close"
              >&times;</span
            >
            <h3>推送計畫補漏設定</h3>
            <div class="addPushPlan-container">
              <div class="filter">
                <label for="showCheckLose_lottery_name">彩種名稱</label>
                <input
                  class="search-box"
                  type="text"
                  placeholder="以模糊搜尋名稱方式設定"
                  id="showCheckLose_lottery_name"
                  v-model="showCheckLose_lottery_name"
                />
              </div>
              <div class="filter">
                <label for="showCheckLose_times">次數設定</label>
                <input
                  class="search-box"
                  type="text"
                  id="showCheckLose_times"
                  v-model="showCheckLose_times"
                />
              </div>
              <div class="filter">
                <label for="showCheckLose_env">環境設定</label>
                <select
                  class="type-box setting-width-206"
                  id="showCheckLose_env"
                  v-model="showCheckLose_env"
                >
                  <option value="test1">test1</option>
                  <option value="test2">test2</option>
                  <option value="test3">test3</option>
                  <option value="test4">test4</option>
                  <option value="pre">pre</option>
                </select>
              </div>
            </div>

            <button class="btn-tips-close" @click="closeDisplayCheckLose">
              關閉
            </button>
            <button class="btn-tips-submit" @click="submitCheckLose">
              新增
            </button>
            <button class="btn-tips-submit" @click="watchCheckLoseConfig">
              查看
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal-showCheckLoseConfig">
    <div class="modal" v-if="showCheckLoseConfig">
      <div>
        <div v-if="showCheckLoseConfig" class="modal">
          <div class="modal-content modal-content-size-36">
            <span
              @click="closeDisplayCheckLoseConfig"
              class="btn-right-top-close"
              >&times;</span
            >
            <h3>補漏設定檔</h3>
            <table class="check-lose-config-table2">
              <thead>
                <tr>
                  <th>名稱</th>
                  <th>次數</th>
                  <th>環境</th>
                  <th>刪除</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="checkLoseConfig in checkLoseConfig_list"
                  :key="checkLoseConfig.id"
                >
                  <td>
                    <input
                      class="name-box"
                      type="text"
                      v-model="checkLoseConfig.name"
                    />
                  </td>
                  <td>
                    <input
                      class="times-box"
                      type="text"
                      v-model="checkLoseConfig.count"
                    />
                  </td>
                  <td>
                    <input
                      class="times-box"
                      type="text"
                      v-model="checkLoseConfig.env"
                    />
                  </td>
                  <td>
                    <button
                      class="btn-delete"
                      @click="deleteCheckLoseConfig(checkLoseConfig.id)"
                    >
                      刪除
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

            <button class="btn-tips-close" @click="closeDisplayCheckLoseConfig">
              關閉
            </button>
            <button class="btn-tips-update" @click="updateCheckLoseConfig">
              更新
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal-description">
    <div class="modal" v-if="description">
      <div>
        <div v-if="description" class="modal">
          <div class="modal-content">
            <span @click="closeDisplayDescription" class="btn-right-top-close"
              >&times;</span
            >
            <h3>推送計畫說明</h3>
            <div class="container-description">
              <p class="description-content">
                1.先點選 "更新 token", 保持爬蟲啟動時是登入狀態
              </p>
              <img
                class="description-img-container"
                src="./../../public/images/push_plan_description1.jpg"
                alt="image1"
              />
              <p class="description-content">
                2.點選 "開啟微聊爬蟲", 會自動開啟 sse 連線
              </p>
              <img
                class="description-img-container"
                src="./../../public/images/push_plan_description2.jpg"
                alt="image2"
              />
              <p class="description-content">
                3.點選 "刷新" 按鈕, 則可以觀察爬蟲是否正常啟動或者是否正在更新
                token
              </p>
              <p class="description-content">
                ps: 綠色表示在背景運行中, 土黃色則表示未啟動的狀態
              </p>
              <img
                class="description-img-container"
                src="./../../public/images/push_plan_description3.jpg"
                alt="image3"
              />
              <p class="description-content">
                4.後續可以觀察資料庫 "push_paln_test"
                這張表單的寫入的推送計畫紀錄, 國際版 db 名稱 int_qa
              </p>
              <p class="description-content">國內版 db 名稱 dafacloud_qa</p>
              <img
                class="description-img-container"
                src="./../../public/images/push_plan_description4.jpg"
                alt="image4"
              />
            </div>

            <button class="btn-tips-close" @click="closeDisplayDescription">
              關閉
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <div class="loading-overlay-two" v-if="loading">
    <div class="spinner"></div>
  </div>
</template>

<script>
import Sidebar from "@/components/sidebar/SidebarCom";
import NavBar from "@/components/navBar/ComNavButtons";
import { getCurrentDomain } from "@/commonUtils.js";
import { submitFormRequest } from "@/commonUtils.js";
import {
  handelSuccessAlertUtil,
  handelErrorAlertUtil,
  handelFailAlertUtil,
} from "@/commonUtils.js";

export default {
  components: { Sidebar, NavBar },
  data() {
    return {
      currentPage: 1,
      loading: false,
      showPopover: false,
      totalPages: 1,
      displayedPages: [], // 要顯示的頁數數組
      selectedBooks: [], // 存储被选中的书籍的 book.id
      folderName: "",
      start_time: "",
      end_time: "",
      platform: "",
      lottery_type: "",
      push_plan_status: "",
      pushConigId: "",
      AddAccount_push_account: "",
      AddAccount_plan_name: "",
      AddAccount_domain: "",
      AddAccount_select_env: "",
      AddAccount_crawler_status: "",
      AddAccount_group_name: "",
      AddAccount_lottery_type: "",
      batchCreateAccount: "",
      batchCreateAccount_push_account: "",
      batchCreateAccount_domain: "",
      batchCreateAccount_plan_name: "",
      batchCreateAccount_lottery_type: "",
      batchCreateAccount_select_env: "",
      showUpdateToken_lottery_type: "",
      showUpdateToken_select_env: "",
      showUpdateToken_crawler_status: "",
      showUpdateToken_domain: "",
      showCheckLose_lottery_name: "",
      showCheckLose_select_env: "",
      showCheckLose_times: "",
      showCheckLose_env: "",
      editPushPlan_push_account: "",
      editPushPlan_group_name: "",
      editPushPlan_domain: "",
      editPushPlan_lottery_type: "",
      editPushPlan_select_env: "",
      editPushPlan_crawler_status: "",
      currentDomain: "",
      envir: "",
      tokenUiStatus: "rgb(211, 156, 36)",
      crawlerUiStatus: "rgb(211, 156, 36)",
      tokenStatus: "idle",
      crawlerStatus: "idle",
      books: [],
      checkLoseConfig_list: [],
      selectAll: false, // 新增全選狀態變數
      createAccount: false,
      editPushPlan: false,
      showUpdateToken: false,
      showCheckLose: false,
      showCheckLoseConfig: false,
      description: false,
    };
  },
  // 氣泡提示
  props: {
    triggerText: {
      type: String,
      default: "開啟微聊爬蟲",
    },
    content: {
      type: String,
      default: "開啟爬蟲時, 建議先更新 token, 避免 token 過期無法連線登入",
    },
  },
  created() {
    // 使用共用方法
    this.currentDomain = getCurrentDomain();
    this.initFetchPushPlanConfig(this.currentDomain);
    this.refreshCrawlerStatus();
  },
  computed: {
    paginatedPages() {
      const pages = [];
      let start = this.currentPage - 5;
      let end = this.currentPage + 4;

      if (start < 1) {
        start = 1;
        end = 10;
      }
      if (end > this.totalPages) {
        end = this.totalPages;
        start = this.totalPages - 9 > 0 ? this.totalPages - 9 : 1;
      }

      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      return pages;
    },
  },
  methods: {
    changePage(newPage) {
      if (newPage > 0 && newPage <= this.totalPages) {
        this.currentPage = newPage;
        this.searchPushPlan();
      }
    },
    toggleSwitch(id, status, platform) {
      if (status === "on") {
        this.change_push_plan_status(id, status, platform);
      } else if (status === "off") {
        this.change_push_plan_status(id, status, platform);
      }
    },
    // 添加推送計畫爬蟲帳號
    submitCreateAccount() {
      const dict = {
        push_account: this.AddAccount_push_account,
        group_name: this.AddAccount_group_name,
        domain: this.AddAccount_domain,
        lottery_type: this.AddAccount_lottery_type,
        select_env: this.AddAccount_select_env,
        crawler_status: this.AddAccount_crawler_status,
      };
      const url = "/v1/api/add_push_plan_account";
      submitFormRequest(this, url, dict);
      // 清除表單資料
      this.AddAccount_push_account = "";
      this.AddAccount_group_name = "";
      this.AddAccount_lottery_type = "";
      this.AddAccount_select_env = "";
      this.AddAccount_crawler_status = "";
      this.AddAccount_domain = "";
    },
    // 編輯提交按鈕
    submitBatchCreateAccount() {
      const dict = {
        push_account: this.batchCreateAccount_push_account,
        domain: this.batchCreateAccount_domain,
        group_name: this.batchCreateAccount_plan_name,
        lottery_type: this.batchCreateAccount_lottery_type,
        select_env: this.batchCreateAccount_select_env,
        crawler_status: this.batchCreateAccount_crawler_status,
      };
      const url = "/v1/api/batch_add_push_plan_account";
      submitFormRequest(this, url, dict);
      // 清除表單資料
      this.batchCreateAccount_push_account = "";
      this.batchCreateAccount_plan_name = "";
      this.batchCreateAccount_lottery_type = "";
      this.batchCreateAccount_select_env = "";
      this.batchCreateAccount_crawler_status = "";
      this.batchCreateAccount_domain = "";
    },
    // 啟動更新 token 爬蟲
    enableUpdateToken() {
      const dict = { platform: "weline" };
      const url = "/v1/api/enable_crawler_update_token";
      submitFormRequest(this, url, dict);
      // 清除表單資料
      this.showUpdateToken_domain = "";
      this.showUpdateToken_select_env = "";
      this.showUpdateToken_crawler_status = "";
    },
    // 更新 token 設定檔
    submitUpdateToken() {
      const dict = {
        domain: this.showUpdateToken_domain,
        select_env: this.showUpdateToken_select_env,
        crawler_status: this.showUpdateToken_crawler_status,
        config_id: this.config_id,
      };
      const url = "/v1/api/push_plan_crawler_update_token";
      submitFormRequest(this, url, dict);
      // 清除表單資料
      this.showUpdateToken_domain = "";
      this.showUpdateToken_select_env = "";
      this.showUpdateToken_crawler_status = "";
    },
    // 更新補漏期號設定檔
    updateCheckLoseConfig() {
      const dict = {
        update_info: this.checkLoseConfig_list,
      };
      const url = "/v1/api/update_check_lose_issue_config";
      submitFormRequest(this, url, dict);
    },
    submitCheckLose() {
      const dict = {
        lottery_name: this.showCheckLose_lottery_name,
        times: this.showCheckLose_times,
        env: this.showCheckLose_env,
      };
      const url = "/v1/api/add_push_plan_lose_issue";
      submitFormRequest(this, url, dict);
    },
    submitEditPushPlan() {
      const url = "/v1/api/edit_push_plan_config";
      const dict = {
        push_plan_id: this.pushConigId,
        push_account: this.editPushPlan_push_account,
        group_name: this.editPushPlan_group_name,
        domain: this.editPushPlan_domain,
        select_env: this.editPushPlan_select_env,
        crawler_status: this.editPushPlan_crawler_status,
        platform: this.platform,
        lottery_type: this.editPushPlan_lottery_type,
      };
      submitFormRequest(this, url, dict);
      this.fetchPushPlanConig(this.editPushPlan_lottery_type);
    },
    fetchUpdateTokenConfig() {
      const url = "/v1/api/fetch_update_token_config";
      const dict = {};
      this.axios
        .post(url, dict)
        .then((response) => {
          const status = response.data.status;

          // 在這裡處理成功回應的邏輯
          if (status === "1") {
            const result = response.data.result;
            this.showUpdateToken_domain = result.domain;
            this.showUpdateToken_select_env = result.env;
            this.showUpdateToken_crawler_status = result.statement;
            this.config_id = result.config_id;
          } else if (status === "-1") {
            handelFailAlertUtil(response);
          } else {
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          handelErrorAlertUtil(error);
        });
    },
    fetchCheckLoseConfig() {
      const url = "/v1/api/fetch_check_lose_issue_config";
      const dict = {};
      this.axios
        .post(url, dict)
        .then((response) => {
          const status = response.data.status;

          // 在這裡處理成功回應的邏輯
          if (status === "1") {
            this.checkLoseConfig_list = response.data.result;
          }
          // 在這裡處理失敗回應的邏輯
          else if (status === "-1") {
            handelFailAlertUtil(response);
          } else {
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          handelErrorAlertUtil(error);
        });
    },
    setFormFieldAndShowPushPlan(
      pushConigId,
      account,
      domain,
      group_name,
      lottery_type,
      select_env,
      crawler_status,
      platform
    ) {
      this.pushConigId = pushConigId;
      this.editPushPlan_push_account = account;
      this.editPushPlan_domain = domain;
      this.editPushPlan_lottery_type = lottery_type;
      this.editPushPlan_group_name = group_name;
      this.editPushPlan_select_env = select_env;
      this.editPushPlan_crawler_status = crawler_status;
      this.editPushPlan = true;
      this.platform = platform;
    },
    displayUpdateToken() {
      this.fetchUpdateTokenConfig();
      this.showUpdateToken = true;
    },
    displayCheckLoseConfig() {
      this.showCheckLose = true;
    },
    displayDescription() {
      this.description = true;
    },
    // 查看補漏爬蟲設定檔
    watchCheckLoseConfig() {
      this.fetchCheckLoseConfig();
      this.showCheckLoseConfig = true;
    },
    // 關閉新增帳號設定視窗
    closeCreateAccount() {
      this.createAccount = false;
    },
    // 關閉批量新增帳號設定視窗
    closeBatchCreateAccount() {
      this.batchCreateAccount = false;
    },
    // 關閉“編輯”帳號設定視窗
    closeEditPushPlan() {
      this.editPushPlan = false;
    },
    // 關閉“說明”視窗
    closeDisplayDescription() {
      this.description = false;
    },
    // 關閉更新 token 設定視窗
    closeDisplayUpdateToken() {
      this.showUpdateToken = false;
    },
    // 關閉補漏設定視窗
    closeDisplayCheckLose() {
      this.showCheckLose = false;
    },
    // 關閉補漏設定檔視窗
    closeDisplayCheckLoseConfig() {
      this.showCheckLoseConfig = false;
    },
    // 跳轉推送計畫管理路由
    goToPushPlan() {
      this.$router.push({ path: "/pushPlanManagement" });
    },
    // 跳轉創建代理線路由
    goToCreateProxy() {
      this.$router.push({ path: "/lotteryTool" });
    },
    goToDisbandSilder() {
      this.$router.push({ path: "/disbandSilderTool" });
    },
    goToVerifyLotteryOpenResult() {
      this.$router.push({ path: "/verifyLotteryOpenResult" });
    },
    // 上一頁
    goToPreviousPage() {
      if (this.page > 1) {
        this.page--;
        this.searchPushPlan();
      }
    },
    // 下一頁
    goToNextPage() {
      if (this.page < this.totalPages) {
        this.page++;
        this.searchPushPlan();
      }
    },
    // 全選
    toggleSelectAll() {
      this.selectAll = !this.selectAll;
      for (let book of this.books) {
        book.selected = this.selectAll;
      }
    },
    fetchPushPlanConig(lottery_type) {
      const url = "/v1/api/fetch_push_plan_config";
      this.axios
        .post(url, {
          lottery_type: lottery_type,
          push_plan_status: this.push_plan_status,
          env: this.envir,
          page: this.currentPage, // 傳遞當前頁數
          limit: 50, // 每頁顯示的數據筆數
        })
        .then((response) => {
          const status = response.data.status;

          // 在這裡處理成功回應的邏輯
          if (status === "1") {
            this.books = response.data.result;
            // 計算總頁數
            this.totalPages = response.data.total_page;
          }
          // 在這裡處理失敗回應的邏輯
          else if (status === "-1") {
            handelFailAlertUtil(response);
          } else {
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          // 在這裡處理錯誤回應的邏輯
          handelErrorAlertUtil(error);
        });
    },
    initFetchPushPlanConfig(type) {
      if (type == "international") {
        const lottery_type = "sicbo";
        this.lottery_type = lottery_type;
        this.fetchPushPlanConig(lottery_type);
      } else {
        const lottery_type = "ssc";
        this.lottery_type = lottery_type;
        this.fetchPushPlanConig(lottery_type);
      }
    },
    updatePushPlanButtonStatus() {
      const url = "/v1/api/fetch_push_plan_config";
      this.axios
        .post(url, {
          lottery_type: this.lottery_type,
          push_plan_status: this.push_plan_status,
          env: this.envir,
          page: this.page, // 傳遞當前頁數
          limit: 50, // 每頁顯示的數據筆數
        })
        .then((response) => {
          const status = response.data.status;

          // 在這裡處理成功回應的邏輯
          if (status === "1") {
            this.books = response.data.result;
            // 計算總頁數
            this.totalPages = response.data.total_page;
          }
          // 在這裡處理失敗回應的邏輯
          else if (status === "-1") {
            handelFailAlertUtil(response);
          } else {
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          // 在這裡處理錯誤回應的邏輯
          handelErrorAlertUtil(error);
        });
    },
    // 搜尋推送計畫 config
    searchPushPlan() {
      const url = "/v1/api/fetch_push_plan_config";
      this.axios
        .post(url, {
          lottery_type: this.lottery_type,
          push_plan_status: this.push_plan_status,
          env: this.envir,
          page: this.currentPage, // 傳遞當前頁數
          limit: 50, // 每頁顯示的數據筆數
        })
        .then((response) => {
          const status = response.data.status;

          // 在這裡處理成功回應的邏輯
          if (status === "1") {
            this.books = response.data.result;
            // 計算總頁數
            this.totalPages = response.data.total_page;
            this.scrollToTop();
          }
          // 在這裡處理失敗回應的邏輯
          else if (status === "-1") {
            handelFailAlertUtil(response);
          } else {
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          handelErrorAlertUtil(error);
        });
    },
    // 重置搜尋欄位內得值
    resetFilters() {
      this.lottery_type = ""; // 清除彩種類型的值
      this.push_plan_status = ""; // 清除推送狀態的值
      this.envir = ""; // 清除環境參數
    },
    requestUtil(url, dict) {
      this.loading = true;
      this.axios
        .post(url, dict)
        .then((response) => {
          const status = response.data.status;

          // 在這裡處理成功回應的邏輯
          if (status === "1") {
            handelSuccessAlertUtil(response);
          }
          // 在這裡處理失敗回應的邏輯
          else if (status === "-1") {
            handelFailAlertUtil(response);
          } else {
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          handelErrorAlertUtil(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 刷新爬蟲狀態
    refreshCrawlerStatus() {
      this.loading = true;
      const checkTokenStatus_url = "/v1/api/check_token_status";
      const checkCrawlerStatus_url = "/v1/api/check_crawler_status";

      this.checkTokenStatus(checkTokenStatus_url);
      this.checkCrawlerStatus(checkCrawlerStatus_url);
    },
    // 確認更新 token 爬蟲狀態
    checkTokenStatus(url) {
      this.axios
        .post(url, {})
        .then((response) => {
          const status = response.data.status;

          // 在這裡處理成功回應的邏輯
          if (status === "1") {
            const result = response.data.result;
            if (result === "running") {
              this.tokenUiStatus = "rgb(31, 156, 20)";
              this.tokenStatus = "running";
            } else {
              this.tokenUiStatus = "rgb(211, 156, 36)";
              this.tokenStatus = "idle";
            }
          }
          // 在這裡處理失敗回應的邏輯
          else if (status === "-1") {
            handelFailAlertUtil(response);
          } else {
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          handelErrorAlertUtil(error);
        });
    },
    // 確認更新 sse 長連線爬蟲狀態
    checkCrawlerStatus(url) {
      this.axios
        .post(url, {})
        .then((response) => {
          const status = response.data.status;

          // 在這裡處理成功回應的邏輯
          if (status === "1") {
            const result = response.data.result;
            if (result === "running") {
              this.crawlerUiStatus = "rgb(31, 156, 20)";
              this.crawlerStatus = "running";
            } else {
              this.crawlerUiStatus = "rgb(211, 156, 36)";
              this.crawlerStatus = "idle";
            }
          }
          // 在這裡處理失敗回應的邏輯
          else if (status === "-1") {
            handelFailAlertUtil(response);
          } else {
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          // 在這裡處理錯誤回應的邏輯
          handelErrorAlertUtil(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 控制爬蟲開關
    controlCrawler(status, env) {
      const url = "/v1/api/control_push_plan_crawler";
      const dict = { switch: status, env: env };
      this.requestUtil(url, dict);
      this.refreshCrawlerStatus();
    },
    // 批量請求共用類
    batchPushPlanUtil(status) {
      const selectedIds = [];
      const url = "/v1/api/batch_control_push_plan";
      for (let book of this.books) {
        if (book.selected) {
          selectedIds.push(book.id); // 将已选中的书籍的 id 添加到 selectedIds
        }
      }
      this.axios
        .post(url, {
          push_plan_id: selectedIds,
          crawler_status: status,
        })
        .then((response) => {
          const status = response.data.status;

          // 在這裡處理成功回應的邏輯
          if (status === "1") {
            this.books = response.data.result;
            // 計算總頁數
            this.totalPages = response.data.totalPage;
          }
          // 在這裡處理失敗回應的邏輯
          else if (status === "-1") {
            handelFailAlertUtil(response);
          } else {
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          handelErrorAlertUtil(error);
        });
    },
    change_push_plan_status(selectedId, status, platform) {
      const url = "/v1/api/change_push_plan_status";
      const dict = {
        push_plan_id: selectedId,
        crawler_status: status,
        platform: platform,
      };
      this.axios
        .post(url, dict)
        .then((response) => {
          const status = response.data.status;

          // 在這裡處理成功回應的邏輯
          if (status === "1") {
            this.updatePushPlanButtonStatus();
          }
          // 在這裡處理失敗回應的邏輯
          else if (status === "-1") {
            handelFailAlertUtil(response);
          } else {
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          handelErrorAlertUtil(error);
        });
    },
    // 推送計畫管理請求共用類
    pushPlanUtil(selectedId, status, platform) {
      const url = "/v1/api/change_push_plan_status";
      const dict = {
        push_plan_id: selectedId,
        crawler_status: status,
        platform: platform,
      };
      this.axios
        .post(url, dict)
        .then((response) => {
          const status = response.data.status;

          // 在這裡處理成功回應的邏輯
          if (status === "1") {
            this.searchPushPlan();
          }
          // 在這裡處理失敗回應的邏輯
          else if (status === "-1") {
            handelFailAlertUtil(response);
          } else {
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          handelErrorAlertUtil(error);
        });
    },
    // 批量打開
    batchOpenPushPlan() {
      this.batchPushPlanUtil("on");
      this.searchPushPlan();
      this.selectAll = false;
    },
    // 批量關閉
    batchClosePushPlan() {
      this.batchPushPlanUtil("off");
      this.searchPushPlan();
      this.selectAll = false;
    },
    // 批量刪除
    batchDeletePushPlan() {
      this.batchPushPlanUtil("delete");
      this.searchPushPlan();
    },
    // 推送計畫管理請求共用類
    deleteCheckLoseConfig(configId) {
      const url = "/v1/api/delete_check_lose_issue_config";
      this.axios
        .post(url, {
          config_id: configId,
        })
        .then((response) => {
          const status = response.data.status;

          // 在這裡處理成功回應的邏輯
          if (status === "1") {
            this.fetchCheckLoseConfig();
          }
          // 在這裡處理失敗回應的邏輯
          else if (status === "-1") {
            handelFailAlertUtil(response);
          } else {
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          handelErrorAlertUtil(error);
        });
    },
    // 滚动到页面顶部
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 平滑滚动效果
      });
    },
  },
};
</script>
