<template>
  <Sidebar />
  <div class="title">XX工具</div>
  <NavBar />
  <div class="select-container">
    <button class="btn-environment" @click="gotoVerfiyOpenResultMode('all')">
      全部
    </button>
    <button class="btn-environment" @click="gotoVerfiyOpenResultMode('single')">
      單一
    </button>
  </div>
  <template v-if="mode === 'all'">
    <div class="container-row">
      <div class="card setting-height-550 setting-width-555">
        <div class="header">
          <div class="title-h2-2 setting-verify-open-result">
            驗證彩票開獎(全部)
          </div>
          <button class="btn-gear" @click="displayShowVerifyLotteryConfig">
            <i class="fa fa-gear"></i>
          </button>
        </div>

        <div class="card-body">
          <div class="submit-form-inner setting-height-450">
            <form>
              <div class="container-row">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control-3"
                    id="domain"
                    placeholder="域名"
                    v-model="domain"
                  />
                </div>
                <small class="lable-text-2">域名</small>
              </div>
              <div class="container-row">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control-3"
                    id="username"
                    placeholder="帳號"
                    v-model="username"
                  />
                </div>
                <small class="lable-text-2">帳號</small>
              </div>
              <div class="container-row">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control-3"
                    id="password"
                    placeholder="密碼"
                    v-model="password"
                  />
                </div>
                <small class="lable-text-2">密碼</small>
              </div>
              <div class="form-group">
                <div class="container-row">
                  <select class="form-control-2" id="check" v-model="check">
                    <option value="all">檢查全部</option>
                    <option value="check_issue">開獎是否斷號</option>
                    <option value="lottery_format">開獎格式正確性</option>
                  </select>
                  <small class="lable-text-2">類型</small>
                </div>
              </div>
              <div class="form-group">
                <div class="container-row">
                  <select
                    class="form-control-2"
                    id="date_num"
                    v-model="date_num"
                  >
                    <option v-for="l in limit" :value="l" :key="l">
                      {{ l }}
                    </option>
                  </select>
                  <small class="lable-text-2">期號數量</small>
                </div>
              </div>
              <div class="container2">
                <button
                  type="submit"
                  class="btn btn-confirm btn-margin-3"
                  @click.prevent="verifyLotteryOpenResultAll"
                >
                  確認
                </button>
                <button
                  class="btn btn-info btn-margin-4"
                  @click.prevent="displayShowLotteryVerifyReport"
                >
                  結果
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </template>
  <template v-if="mode === 'single'">
    <div class="container-row">
      <div class="card setting-height-550 setting-width-555">
        <div class="header">
          <div class="title-h2-2 setting-verify-open-result">
            驗證彩票開獎(單一)
          </div>
          <button class="btn-gear">
            <i class="fa fa-gear"></i>
          </button>
        </div>

        <div class="card-body">
          <div class="submit-form-inner setting-height-450">
            <form>
              <div class="container-row">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control-3"
                    id="domain"
                    placeholder="域名"
                    v-model="domain"
                  />
                </div>
                <small class="lable-text-2">域名</small>
              </div>
              <div class="container-row">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control-3"
                    id="username"
                    placeholder="帳號"
                    v-model="username"
                  />
                </div>
                <small class="lable-text-2">帳號</small>
              </div>
              <div class="container-row">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control-3"
                    id="password"
                    placeholder="密碼"
                    v-model="password"
                  />
                </div>
                <small class="lable-text-2">密碼</small>
              </div>
              <div class="form-group">
                <div class="container-row">
                  <select class="form-control-2" id="check" v-model="check">
                    <option value="all">檢查全部</option>
                    <option value="check_issue">開獎是否斷號</option>
                    <option value="lottery_format">開獎格式正確性</option>
                  </select>
                  <small class="lable-text-2">類型</small>
                </div>
              </div>
              <div class="form-group">
                <div class="container-row">
                  <select
                    class="form-control-2"
                    id="lottery_code"
                    v-model="lottery_code"
                  >
                    <template v-if="currentDomain === 'international'">
                      <option
                        v-for="(value, key) in international_lottery_code_dict"
                        :value="value"
                        :key="key"
                      >
                        {{ key }}
                      </option>
                    </template>
                    <template v-else-if="currentDomain === 'domestic'">
                      <option
                        v-for="(value, key) in domestic_lottery_code_ls"
                        :value="value"
                        :key="key"
                      >
                        {{ key }}
                      </option>
                    </template>
                  </select>

                  <small class="lable-text-2">彩種代號</small>
                </div>
              </div>
              <div class="form-group">
                <div class="container-row">
                  <select
                    class="form-control-2"
                    id="date_num"
                    v-model="date_num"
                  >
                    <option
                      v-for="date_num in limit"
                      :value="date_num"
                      :key="date_num"
                    >
                      {{ date_num }}
                    </option>
                  </select>
                  <small class="lable-text-2">期號數量</small>
                </div>
              </div>
              <div class="container2">
                <button
                  type="submit"
                  class="btn btn-confirm btn-margin-3"
                  @click.prevent="verifyLotteryOpenResultSingel"
                >
                  確認
                </button>
                <button
                  class="btn btn-info btn-margin-4"
                  @click.prevent="displayShowLotteryVerifyReport"
                >
                  結果
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </template>

  <transition name="modal-showLotteryVerifyReport">
    <div class="modal" v-if="showLotteryVerifyReport">
      <div>
        <div v-if="showLotteryVerifyReport" class="modal">
          <div class="modal3-content modal-content-size-36">
            <span
              @click="closeDisplayShowLotteryVerifyReport"
              class="btn-right-top-close"
              >&times;</span
            >
            <button
              class="btn-lottery-report"
              @click="gotoLotteryVerifyReport('one')"
            >
              期號缺失
            </button>
            <button
              class="btn-lottery-report"
              @click="gotoLotteryVerifyReport('two')"
            >
              錯誤格式
            </button>
            <template v-if="lottery_report_switch === 'one'">
              <h2>驗證彩票期號缺失報告</h2>
              <table class="verify-lottery-report-table">
                <thead>
                  <tr>
                    <th>彩票代號</th>
                    <th>彩票期號範圍</th>
                    <th>彩票缺失期號</th>
                    <th>結果</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="lottery_report in check_lottery_lose_issue_ls"
                    :key="lottery_report.id"
                  >
                    <template
                      v-if="lottery_report.hasOwnProperty('lose_issue')"
                    >
                      <td>
                        {{ lottery_report.lottery_code }}
                      </td>
                      <td>
                        {{ lottery_report.issue_ls }}
                      </td>
                      <td>
                        <div
                          v-if="
                            Array.isArray(lottery_report.lose_issue) &&
                            lottery_report.lose_issue.length > 0
                          "
                          class="btn-detail setting-man-left-80 setting-width-30 setting-height-24"
                          @click="
                            setFormFieldLoseIssue(lottery_report.lose_issue)
                          "
                        >
                          查看
                        </div>
                        <div
                          v-if="
                            Array.isArray(lottery_report.lose_issue) &&
                            lottery_report.lose_issue.length === 0
                          "
                        ></div>
                      </td>
                      <td>
                        <button
                          v-if="lottery_report.rs === 'pass'"
                          class="btn-pass"
                        >
                          pass
                        </button>
                        <button
                          v-else-if="lottery_report.rs === 'fail'"
                          class="btn-fail"
                        >
                          fail
                        </button>
                      </td>
                    </template>
                  </tr>
                </tbody>
              </table>

              <button
                class="btn-tips-close"
                @click="closeDisplayShowLotteryVerifyReport"
              >
                關閉
              </button>
            </template>
            <template v-if="lottery_report_switch === 'two'">
              <h2>驗證彩票開獎格式報告</h2>
              <table class="verify-lottery-report-table">
                <thead>
                  <tr>
                    <th>彩票代號</th>
                    <th>錯誤格式</th>
                    <th>結果</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="lottery_report in check_lottery_lose_issue_ls"
                    :key="lottery_report.id"
                  >
                    <template
                      v-if="lottery_report.hasOwnProperty('error_number_ls')"
                    >
                      <td>
                        {{ lottery_report.lottery_code }}
                      </td>
                      <td>
                        <div
                          v-if="
                            Array.isArray(lottery_report.error_number_ls) &&
                            lottery_report.error_number_ls.length > 0
                          "
                          class="btn-detail setting-man-left-80 setting-width-30 setting-height-24"
                          @click="setFormFieldLoseIssue(lose_issue)"
                        >
                          查看
                        </div>
                        <div
                          v-if="
                            Array.isArray(lottery_report.error_number_ls) &&
                            lottery_report.error_number_ls.length === 0
                          "
                        ></div>
                      </td>
                      <td>
                        <button
                          v-if="lottery_report.rs === 'pass'"
                          class="btn-pass"
                        >
                          pass
                        </button>
                        <button
                          v-else-if="lottery_report.rs === 'fail'"
                          class="btn-fail"
                        >
                          fail
                        </button>
                      </td>
                    </template>
                  </tr>
                </tbody>
              </table>

              <button
                class="btn-tips-close"
                @click="closeDisplayShowLotteryVerifyReport"
              >
                關閉
              </button>
            </template>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal-showLotteryLoseIssue">
    <div class="modal" v-if="showLotteryLoseIssue">
      <div>
        <div v-if="showLotteryLoseIssue" class="modal">
          <div class="modal3-content modal-content-size-36">
            <span @click="closeShowLotteryLoseIssue" class="btn-right-top-close"
              >&times;</span
            >
            <h2>彩票缺失期號</h2>
            <div class="container-column">
              <textarea
                class="custom-textarea"
                rows="4"
                v-model="lose_issue"
              ></textarea>
              <button class="btn-tips-close" @click="closeShowLotteryLoseIssue">
                關閉
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal-showVerifyLotteryConfig">
    <div class="modal" v-if="showVerifyLotteryConfig">
      <div>
        <div v-if="showVerifyLotteryConfig" class="modal">
          <div class="modal4-content modal-content-size-36">
            <span @click="closeVerifyLotteryConfig" class="btn-right-top-close"
              >&times;</span
            >
            <h3>XX工具設定檔</h3>
            <table class="verify-lottery-config-table">
              <thead>
                <tr>
                  <th>彩種</th>
                  <th>類型</th>
                  <th>狀態</th>
                  <th>操作</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="VerifyLotteryConfig in VerifyLotteryConfig_ls"
                  :key="VerifyLotteryConfig.id"
                >
                  <td>
                    <input
                      class="rebate-box"
                      name="name"
                      type="text"
                      v-model="VerifyLotteryConfig.lottery_code"
                    />
                  </td>
                  <td>
                    <select
                      class="type-box setting-width-160"
                      v-model="VerifyLotteryConfig.rule"
                    >
                      <template v-if="VerifyLotteryConfig.rule === 'flow'">
                        <option value="flow">流水號</option>
                        <option value="ymd">年月日</option>
                        <option value="mdis">多天一期</option>
                        <option value="odis">一天一期</option>
                      </template>
                      <template v-if="VerifyLotteryConfig.rule === 'ymd'">
                        <option value="ymd">年月日</option>
                        <option value="flow">流水號</option>
                        <option value="mdis">多天一期</option>
                        <option value="odis">一天一期</option>
                      </template>
                      <template v-if="VerifyLotteryConfig.rule === 'mdis'">
                        <option value="mdis">多天一期</option>
                        <option value="ymd">年月日</option>
                        <option value="flow">流水號</option>
                        <option value="odis">一天一期</option>
                      </template>
                      <template v-if="VerifyLotteryConfig.rule === 'odis'">
                        <option value="odis">一天一期</option>
                        <option value="mdis">多天一期</option>
                        <option value="ymd">年月日</option>
                        <option value="flow">流水號</option>
                      </template>
                    </select>
                  </td>
                  <td>
                    <button
                      v-if="VerifyLotteryConfig.status === 'on'"
                      class="btn-pass"
                    >
                      ON
                    </button>
                    <button
                      v-else-if="VerifyLotteryConfig.status === 'off'"
                      class="btn-close-status"
                    >
                      OFF
                    </button>
                  </td>

                  <td>
                    <div class="management-container">
                      <button
                        class="btn-open"
                        @click="
                          controlVerifyLotteryConfig(
                            VerifyLotteryConfig.id,
                            'on'
                          )
                        "
                      >
                        打開
                      </button>
                      <button
                        class="btn-offline"
                        @click="
                          controlVerifyLotteryConfig(
                            VerifyLotteryConfig.id,
                            'off'
                          )
                        "
                      >
                        關閉
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <button class="btn-tips-close" @click="closeVerifyLotteryConfig">
              關閉
            </button>
            <button class="btn-tips-update" @click="settingVerifyLotteryConfig">
              更新
            </button>
            <button
              class="btn-tips-update"
              @click="displayShowAddVerifyLotteryConfig"
            >
              新增
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal-addShowVerifyLotteryConfig">
    <div class="modal" v-if="addShowVerifyLotteryConfig">
      <div>
        <div v-if="addShowVerifyLotteryConfig" class="modal">
          <div class="modal-content">
            <span
              @click="closeEditVerifyLotteryConfig"
              class="btn-right-top-close"
              >&times;</span
            >
            <h3>新增彩票驗證工具設定檔</h3>
            <div class="addPushPlan-container">
              <div class="filter">
                <label for="addLotteryConfig_lotteryCode">彩票代號</label>
                <input
                  class="search-box"
                  type="text"
                  id="addLotteryConfig_lotteryCode"
                  placeholder="1001"
                  v-model="addLotteryConfig_lotteryCode"
                />
              </div>
              <div class="filter">
                <label for="addLotteryConfig_issueRule">開獎規則</label>
                <select
                  class="type-box setting-width-206"
                  id="addLotteryConfig_issueRule"
                  v-model="addLotteryConfig_issueRule"
                >
                  <option value="flow">流水號</option>
                  <option value="ymd">年月日</option>
                  <option value="odis">一天一期</option>
                </select>
              </div>
              <div class="filter">
                <label for="addLotteryConfig_issueIndex">末位位數</label>
                <select
                  class="type-box setting-width-206"
                  id="addLotteryConfig_issueIndex"
                  v-model="addLotteryConfig_issueIndex"
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                </select>
              </div>
              <div class="filter">
                <label for="addLotteryConfig_endIssue">一天幾期</label>
                <input
                  class="search-box"
                  type="text"
                  id="addLotteryConfig_endIssue"
                  placeholder="1920"
                  v-model="addLotteryConfig_endIssue"
                />
              </div>
            </div>

            <button
              class="btn-tips-close"
              @click="closeEditVerifyLotteryConfig"
            >
              關閉
            </button>
            <button class="btn-tips-submit" @click="addVerifyLotteryConfig">
              確認
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <div class="loading-overlay-two" v-if="loading">
    <div class="spinner"></div>
  </div>
</template>

<script>
import Sidebar from "@/components/sidebar/SidebarCom";
import NavBar from "@/components/navBar/ComNavButtons";
import { getCurrentDomain } from "@/commonUtils.js";
import { submitFormRequest } from "@/commonUtils.js";
import Swal from "sweetalert2";
import {
  handelSuccessAlertUtil,
  handelErrorAlertUtil,
  handelFailAlertUtil,
} from "@/commonUtils.js";
export default {
  // 綁定元件
  components: { Sidebar, NavBar },
  data() {
    return {
      mode: "all",
      date_num: "",
      lottery_report_switch: "one",
      lose_issue: [],
      check_lottery_lose_issue_ls: [],
      limit: ["30", "50", "200"],
      international_lottery_code_dict: {
        "1401(SB45)": "1401",
        "1402(SB60)": "1402",
        "1403(SB90)": "1403",
        "1301(K45)": "1301",
        "1302(K60)": "1302",
        "1303(K90)": "1303",
        "1201(4D45)": "1201",
        "1202(4D60)": "1202",
        "1203(4D90)": "1203",
        "1101(THL45)": "1101",
        "1102(THL60)": "1102",
        "1103(THL90)": "1103",
        "1001(VN45)": "1001",
        "1002(VN60)": "1002",
        "1003(VN90)": "1003",
        "1304(加拿大KENO)": "1304",
        "1305(加拿大西KENO)": "1305",
        "1306(澳洲KENO)": "1306",
        "1307(斯洛伐克KENO)": "1307",
        "1404(北京SIC BO)": "1404",
        "1405(上海SIC BO)": "1405",
        "1406(香港SIC BO)": "1406",
        "1407(澳门SIC BO)": "1407",
        "1204(新加坡4D)": "1204",
        "1205(马来万能4D)": "1205",
        "1206(大马彩4D)": "1206",
        "1104(泰国乐透)": "1104",
        "1004(北部河内)": "1004",
        "1005(北部广宁)": "1005",
        "1006(北部北宁)": "1006",
        "1007(北部海防)": "1007",
        "1008(北部南定)": "1008",
        "1009(北部太平)": "1009",
        "1010(南部胡志明市)": "1010",
        "1011(南部金瓯)": "1011",
        "1012(南部同塔)": "1012",
        "1013(南部薄辽)": "1013",
        "1014(南部槟知)": "1014",
        "1015(南部头顿)": "1015",
        "1016(南部芹苴)": "1016",
        "1017(南部同奈)": "1017",
        "1018(南部朔庄)": "1018",
        "1019(南部安江)": "1019",
        "1020(南部平顺)": "1020",
        "1021(南部西宁)": "1021",
        "1022(南部平阳)": "1022",
        "1023(南部茶荣)": "1023",
        "1024(南部永隆)": "1024",
        "1025(南部平福)": "1025",
        "1026(南部后江)": "1026",
        "1027(南部隆安)": "1027",
        "1028(南部大叻)": "1028",
        "1029(南部坚江)": "1029",
        "1030(南部前江)": "1030",
        "1031(中部承天顺化)": "1031",
        "1032(中部富安)": "1032",
        "1033(中部得乐)": "1033",
        "1034(中部广南)": "1034",
        "1035(中部岘港)": "1035",
        "1036(中部庆和)": "1036",
        "1037(中部评定)": "1037",
        "1038(中部广平)": "1038",
        "1039(中部广治)": "1039",
        "1040(中部嘉莱)": "1040",
        "1041(中部宁顺)": "1041",
        "1042(中部得农)": "1042",
        "1043(中部广义)": "1043",
        "1044(中部昆嵩)": "1044",
        "1501(L45)": "1501",
        "1502(L60)": "1502",
        "1503(L90)": "1503",
        "1504(PowerBall)": "1504",
        "1505(MegaMillions)": "1505",
        "1506(Cash4Life)": "1506",
        "1507(EuroMillions)": "1507",
        "1508(SuperEnalotto)": "1508",
        "1408(SB300)": "1408",
        "1452(站长SB180)": "1452",
        "1453(站长SB300)": "1453",
        "1352(站长K180)": "1352",
        "1353(站长K300)": "1353",
        "1308(越南KENO)": "1308",
        "1601(TRX60)": "1601",
        "1602(TRX90)": "1602",
        "1603(TRX180)": "1603",
        "1604(TRX300)": "1604",
        "1450(站长SB60)": "1450",
        "1350(站长K60)": "1350",
        "1650(站长HASH60)": "1650",
        "1652(站长HASH180)": "1652",
        "1653(站长HASH300)": "1653",
      },
      domestic_lottery_code_ls: {
        "1000(欢乐生肖)": "1000",
        "1001(新疆时时彩)": "1001",
        "1003(天津时时彩)": "1003",
        "1008(大发时时彩)": "1008",
        "1009(广东11选5)": "1009",
        "1018(上海11选5)": "1018",
        "1019(山东11选5)": "1019",
        "1010(江西11选5)": "1010",
        "1011(福彩3D)": "1011",
        "1100(排列3)": "1100",
        "1101(大发六合彩)": "1101",
        "1102(香港六合彩)": "1102",
        "1103(北京快乐8)": "1103",
        "1104(北京PK10)": "1104",
        "1105(大发PK10)": "1105",
        "1106(江苏快3)": "1106",
        "1107(安徽快3)": "1107",
        "1108(广西快3)": "1108",
        "1202(吉林快3)": "1202",
        "1205(湖北快3)": "1205",
        "1206(北京快3)": "1206",
        "1209(大发快3)": "1209",
        "1210(河北快3)": "1210",
        "1201(贵州快3)": "1201",
        "1203(上海快3)": "1203",
        "1204(甘肃快3)": "1204",
        "1207(5分快3)": "1207",
        "1208(5分时时彩)": "1208",
        "1302(5分六合彩)": "1302",
        "1307(5分PK10)": "1307",
        "1308(5分快乐8)": "1308",
        "1315(大发快乐8)": "1315",
        "1319(5分11选5)": "1319",
        "1300(大发11选5)": "1300",
        "1301(5分3D)": "1301",
        "1305(大发3D)": "1305",
        "1316(5分排列3)": "1316",
        "1318(大发排列3)": "1318",
        "1321(幸运飞艇)": "1321",
        "1322(站长时时彩)": "1322",
        "1303(站长5分时时彩)": "1303",
        "1304(站长快3)": "1304",
        "1306(站长5分快3)": "1306",
        "1309(站长PK10)": "1309",
        "1312(站长5分PK10)": "1312",
        "1313(3分时时彩)": "1313",
        "1314(3分PK10)": "1314",
        "1317(3分快3)": "1317",
        "1323(北京28)": "1323",
        "1324(10分时时彩)": "1324",
        "1320(10分PK10)": "1320",
        "1325(10分快3)": "1325",
        "1401(10分11选5)": "1401",
        "1402(3分11选5)": "1402",
        "1403(10分3D)": "1403",
        "1404(3分3D)": "1404",
        "1405(10分排列3)": "1405",
        "1406(3分排列3)": "1406",
        "1407(3分六合彩)": "1407",
        "1408(10分六合彩)": "1408",
        "1409(10分快乐8)": "1409",
        "1410(3分快乐8)": "1410",
        "1411(江苏11选5)": "1411",
        "1412(站长3分快3)": "1412",
        "1418(站长10分快3)": "1418",
        "1419(澳门六合彩)": "1419",
        "1413(新澳门六合彩)": "1413",
        "1414(福彩快乐8)": "1414",
        "1420(极速飞艇)": "1420",
        "1421(极速赛车)": "1421",
      },
      loading: false,
      domain: "",
      username: "",
      password: "",
      showLotteryLoseIssue: false,
      showLotteryVerifyReport: false,
      check: "",
      lottery_code: "",
      addLotteryConfig_lotteryCode: "",
      addLotteryConfig_issueRule: "",
      addLotteryConfig_endIssue: "",
      addLotteryConfig_issueIndex: "",
      addShowVerifyLotteryConfig: false,
      showVerifyLotteryConfig: false,
      VerifyLotteryConfig_ls: [],
    };
  },
  created() {
    this.currentDomain = getCurrentDomain();
  },
  methods: {
    checkDomain() {
      if (this.domain === "") return;
      if (!/.*com$/.test(this.domain)) {
        Swal.fire({
          title: "warning",
          text: "domain 欄位內容必須是開頭 http:// , 結尾 com",
          icon: "warning",
          confirmButtonText: "確定",
        });
        // 重置 domain 的值為空字串
        this.domain = "";
        return;
      }
    },
    // 確認代理線狀態
    create_proxy_status() {
      const url = "/v1/api/create_proxy_status";
      const task_id = localStorage.getItem("task_id");
      this.axios
        .post(url, {
          task_id: task_id,
        })
        .then((response) => {
          const status = response.data.status;
          const task_id = response.data.task_id;

          // 在這裡處理成功回應的邏輯
          if (status === "1") {
            Swal.fire({
              title: "Done !",
              text: "創建代理線完成", // 顯示回應內容
              icon: "success",
              confirmButtonText: "OK",
            });
          } else if (status === "-1") {
            localStorage.setItem("task_id", task_id);
            Swal.fire({
              title: "Fail !",
              text: JSON.stringify(response.data), // 顯示回應內容
              icon: "error",
              confirmButtonText: "OK",
            });
          } else if (status === "0") {
            localStorage.setItem("task_id", task_id);
            Swal.fire({
              title: "Pending !",
              text: "處理中, 請稍候", // 顯示回應內容
              icon: "info",
              confirmButtonText: "OK",
            });
          } else {
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          handelErrorAlertUtil(error);
        });
    },
    handleVerifyLotteryReportHttpStatusCode(status, response) {
      // 在這裡處理成功回應的邏輯
      if (status === "1") {
        const result = response.data.result;

        this.check_lottery_lose_issue_ls = result;
        handelSuccessAlertUtil(response);
      }
      // 在這裡處理失敗回應的邏輯
      else if (status === "-1") {
        handelFailAlertUtil(response);
      } else {
        // localStorage.removeItem("task_id");
        handelErrorAlertUtil(response);
      }
    },
    // 驗證彩票開獎結果
    verifyLotteryOpenResultAll() {
      const url = "/v1/api/check_lottery_open_result";
      const dict = {
        domain: this.domain,
        mode: "all",
        date_num: this.date_num,
        lottery_username: this.username,
        lottery_password: this.password,
        check: this.check,
      };
      this.loading = true;
      this.axios
        .post(url, dict)
        .then((response) => {
          const status = response.data.status;
          this.handleVerifyLotteryReportHttpStatusCode(status, response);
        })
        .catch((error) => {
          handelErrorAlertUtil(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    verifyLotteryOpenResultSingel() {
      const url = "/v1/api/check_lottery_open_result";
      const dict = {
        domain: this.domain,
        mode: "single",
        lottery_username: this.username,
        lottery_password: this.password,
        lottery_code: this.lottery_code,
        date_num: this.date_num,
        check: this.check,
      };
      submitFormRequest(this, url, dict);
    },
    // 獲取彩票設定檔
    async fetchVerifyLotteryConfig() {
      this.loading = true;
      const url = "/v1/api/fetch_verify_lottery_open_result_config";
      const dict = {};

      try {
        const response = await this.axios.post(url, dict);
        const status = response.data.status;
        const result = response.data.result;

        // 在這裡處理成功回應的邏輯
        if (status === "1") {
          this.VerifyLotteryConfig_ls = result;
        }
        // 在這裡處理失敗回應的邏輯
        else if (status === "-1") {
          handelFailAlertUtil(response);
        } else {
          handelErrorAlertUtil(response);
        }
      } catch (error) {
        handelErrorAlertUtil(error);
      } finally {
        this.loading = false;
      }
    },
    // 設定彩票工具設定檔
    async settingVerifyLotteryConfig() {
      this.loading = true;
      const url = "/v1/api/update_verify_lottery_open_result_config";
      const dict = {
        update_info: this.VerifyLotteryConfig_ls,
      };

      try {
        const response = await this.axios.post(url, dict);
        const status = response.data.status;
        const result = response.data.result;

        // 在這裡處理成功回應的邏輯
        if (status === "1") {
          this.VerifyLotteryConfig_ls = result;
        }
        // 在這裡處理失敗回應的邏輯
        else if (status === "-1") {
          handelFailAlertUtil(response);
        } else {
          handelErrorAlertUtil(response);
        }
      } catch (error) {
        handelErrorAlertUtil(error);
      } finally {
        this.loading = false;
      }
    },
    async controlVerifyLotteryConfig(id, statement) {
      this.loading = true;
      const url = "/v1/api/control_verify_lottery_open_result_status_config";
      const dict = {
        id: id,
        status: statement,
      };

      try {
        const response = await this.axios.post(url, dict);
        const status = response.data.status;

        // 在這裡處理成功回應的邏輯
        if (status === "1") {
          // 使用for循环查找id等于1的对象
          for (let i = 0; i < this.VerifyLotteryConfig_ls.length; i++) {
            if (this.VerifyLotteryConfig_ls[i].id === id) {
              // 找到匹配的对象，更新status值
              console.log(statement);
              if (statement === "on") {
                this.VerifyLotteryConfig_ls[i].status = "on";
              } else {
                this.VerifyLotteryConfig_ls[i].status = "off";
              }
              break; // 找到后跳出循环
            }
          }
        }
        // 在這裡處理失敗回應的邏輯
        else if (status === "-1") {
          handelFailAlertUtil(response);
        } else {
          handelErrorAlertUtil(response);
        }
      } catch (error) {
        handelErrorAlertUtil(error);
      } finally {
        this.loading = false;
      }
    },
    async addVerifyLotteryConfig() {
      this.loading = true;
      const dict = {
        lottery_code: this.addLotteryConfig_lotteryCode,
        rule: this.addLotteryConfig_issueRule,
        issue_index: this.addLotteryConfig_issueIndex,
        end_issue: this.addLotteryConfig_endIssue,
      };
      const url = "/v1/api/add_verify_lottery_open_result_config";

      try {
        const response = await this.axios.post(url, dict);
        const status = response.data.status;

        // 在這裡處理成功回應的邏輯
        if (status === "1") {
          handelSuccessAlertUtil(response);
        }
        // 在這裡處理失敗回應的邏輯
        else if (status === "-1") {
          handelFailAlertUtil(response);
        } else {
          handelSuccessAlertUtil(response);
        }
      } catch (error) {
        handelErrorAlertUtil(error);
      } finally {
        this.loading = false;
      }
    },
    navLotteryTool() {
      this.$router.push({ path: "/lotteryTool" });
    },
    goToPushPlan() {
      this.$router.push({ path: "/pushPlanManagement" });
    },
    goToCreateProxy() {
      this.$router.push({ path: "/lotteryTool" });
    },
    goToDisbandSilder() {
      this.$router.push({ path: "/disbandSilderTool" });
    },
    goToVerifyLotteryOpenResult() {
      this.$router.push({ path: "/verifyLotteryOpenResult" });
    },
    gotoVerfiyOpenResultMode(mode) {
      if (mode === "all") {
        this.mode = "all";
      } else if (mode === "single") {
        this.mode = "single";
      }
    },
    gotoLotteryVerifyReport(lottery_report_switch) {
      if (lottery_report_switch === "one") {
        this.lottery_report_switch = "one";
      } else if (lottery_report_switch === "two") {
        this.lottery_report_switch = "two";
      }
    },
    displayShowLotteryVerifyReport() {
      // this.fetchProxyConfig();
      this.showLotteryVerifyReport = true;
    },
    async displayShowVerifyLotteryConfig() {
      await this.fetchVerifyLotteryConfig();
      this.showVerifyLotteryConfig = true;
    },
    async displayShowAddVerifyLotteryConfig() {
      this.addShowVerifyLotteryConfig = true;
    },
    // 關閉補漏設定視窗
    closeDisplayShowLotteryVerifyReport() {
      this.showLotteryVerifyReport = false;
    },
    closeVerifyLotteryConfig() {
      this.showVerifyLotteryConfig = false;
    },
    closeShowLotteryLoseIssue() {
      this.showLotteryLoseIssue = false;
    },
    closeEditVerifyLotteryConfig() {
      this.addShowVerifyLotteryConfig = false;
    },
    setFormFieldLoseIssue(lose_issue) {
      this.lose_issue = lose_issue;
      this.showLotteryLoseIssue = true;
    },
  },
};
</script>
