<template>
  <Sidebar />
  <!-- <span class="lottery-cycle-checkbox-tips">更新 Log 與任務狀態開關</span> -->
  <!-- 循環回歸 -->
  <template v-if="version === 'domestic'">
    <div class="card setting-mgn-top setting-height-470 setting-width-1310">
      <div class="title-h2-1">XX循環回歸</div>
      <!-- <div class="right-align-container">
          <p class="hiden">{{ LotteryCycleSeconds }}</p>
          <label class="lottery-cycle-switch">
            <input type="checkbox" @click="LottryCycleToggleTimer" />
            {{ LotteryCycleIsTimerRunning ? "Stop" : "Start" }}
            <span class="slider"></span>
          </label>
        </div> -->

      <div class="card-body">
        <div class="container-row">
          <div class="card setting-height-370">
            <div class="header">
              <div class="title-h2 setting-mgn-left-40">Test1 API</div>
              <button class="btn-gear" @click="watchEnvironmentConfig('test1')">
                <i class="fa fa-gear"></i>
              </button>
            </div>
            <div class="card-body">
              <button
                class="btn btn-primary setting-mgn-10-50-50-10"
                @click="displayLotteryCycleConfig('test1', 'api')"
              >
                啟動
              </button>
              <button
                class="btn btn-primary setting-mgn-10-50-50-10"
                @click="close_lottery_mode('test1')"
              >
                中斷測試
              </button>
              <button
                class="btn btn-secondary setting-mgn-10-50-50-10"
                :style="{ backgroundColor: test1APIcycleStatusColor }"
              >
                {{ test1APIcycleStatus }}
              </button>
              <button
                class="btn btn-secondary setting-mgn-10-50-50-10"
                :style="{ backgroundColor: test1APIcycleConfigStatusColor }"
              >
                {{ test1APIcycleConfigStatus }}
              </button>
              <button
                class="btn btn-secondary setting-mgn-10-50-50-10"
                @click="displayShowLotteryLoopReport('test1', 'lottery')"
              >
                報告
              </button>
            </div>
          </div>
          <div class="card setting-height-370">
            <div class="header">
              <div class="title-h2 setting-mgn-left-40">Test2 API</div>
              <button class="btn-gear" @click="watchEnvironmentConfig('test2')">
                <i class="fa fa-gear"></i>
              </button>
            </div>
            <div class="card-body">
              <button
                class="btn btn-primary setting-mgn-10-50-50-10"
                @click="displayLotteryCycleConfig('test2', 'api')"
              >
                啟動
              </button>
              <button
                class="btn btn-primary setting-mgn-10-50-50-10"
                @click="close_lottery_mode('test2')"
              >
                中斷測試
              </button>
              <button
                class="btn btn-secondary setting-mgn-10-50-50-10"
                :style="{ backgroundColor: test2APIcycleStatusColor }"
              >
                {{ test2APIcycleStatus }}
              </button>
              <button
                class="btn btn-secondary setting-mgn-10-50-50-10"
                :style="{ backgroundColor: test2APIcycleConfigStatusColor }"
              >
                {{ test2APIcycleConfigStatus }}
              </button>
              <button
                class="btn btn-secondary setting-mgn-10-50-50-10"
                @click="displayShowLotteryLoopReport('test2', 'lottery')"
              >
                報告
              </button>
            </div>
          </div>
          <div class="card setting-height-370">
            <div class="header">
              <div class="title-h2 setting-mgn-left-40">Pre API</div>
              <button class="btn-gear" @click="watchEnvironmentConfig('pre')">
                <i class="fa fa-gear"></i>
              </button>
            </div>
            <div class="card-body">
              <button
                class="btn btn-primary setting-mgn-10-50-50-10"
                @click="displayLotteryCycleConfig('pre', 'api')"
              >
                啟動
              </button>
              <button
                class="btn btn-primary setting-mgn-10-50-50-10"
                @click="close_lottery_mode('pre')"
              >
                中斷測試
              </button>
              <button
                class="btn btn-secondary setting-mgn-10-50-50-10"
                :style="{ backgroundColor: preAPIcycleStatusColor }"
              >
                {{ preAPIcycleStatus }}
              </button>
              <button
                class="btn btn-secondary setting-mgn-10-50-50-10"
                :style="{ backgroundColor: preAPIcycleConfigStatusColor }"
              >
                {{ preAPIcycleConfigStatus }}
              </button>
              <button
                class="btn btn-secondary setting-mgn-10-50-50-10"
                @click="displayShowLotteryLoopReport('pre', 'lottery')"
              >
                報告
              </button>
            </div>
          </div>
          <div class="card setting-height-370">
            <div class="header">
              <div class="title-h2 setting-mgn-left-40">Pro API</div>
              <button class="btn-gear" @click="watchEnvironmentConfig('pro')">
                <i class="fa fa-gear"></i>
              </button>
            </div>
            <div class="card-body">
              <button
                class="btn btn-primary setting-mgn-10-50-50-10"
                @click="displayLotteryCycleConfig('pro', 'api')"
              >
                啟動
              </button>
              <button
                class="btn btn-primary setting-mgn-10-50-50-10"
                @click="close_lottery_mode('pro')"
              >
                中斷測試
              </button>
              <button
                class="btn btn-secondary setting-mgn-10-50-50-10"
                :style="{ backgroundColor: proAPIcycleStatusColor }"
              >
                {{ proAPIcycleStatus }}
              </button>
              <button
                class="btn btn-secondary setting-mgn-10-50-50-10"
                :style="{ backgroundColor: proAPIcycleConfigStatusColor }"
              >
                {{ proAPIcycleConfigStatus }}
              </button>
              <button
                class="btn btn-secondary setting-mgn-10-50-50-10"
                @click="displayShowLotteryLoopReport('pro', 'lottery')"
              >
                報告
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

  <!-- 設定頁面 -->
  <transition name="modal-displayLotteryLoopTestConfig">
    <div class="modal" v-if="displayLotteryLoopTestConfig">
      <div>
        <div v-if="displayLotteryLoopTestConfig" class="modal">
          <div class="modal-setting-lottery-mode-content">
            <span
              @click="closeLotteryLoopTestConfig"
              class="btn-right-top-close"
              >&times;</span
            >
            <h2>選擇回歸模式</h2>
            <div class="lotterySwitch-container">
              <div class="container-row-center">
                <div
                  class="setting-mgn-bottom setting-mgn-right-0-5-0-5"
                  v-for="settingMode in settingModes"
                  :key="settingMode.id"
                >
                  <input
                    type="radio"
                    :id="settingMode.id"
                    :value="settingMode.name"
                    v-model="selectedMode"
                    class="rdobutton_radio"
                  />
                  <label :for="settingMode.id" class="rdobutton_label">{{
                    settingMode.name
                  }}</label>
                </div>
              </div>
              <template v-if="selectedMode === '手動'">
                <p>你選擇的模式: {{ selectedMode }} (需要手動中斷程式)</p>
              </template>
              <template v-if="selectedMode === '定時'">
                <p>你選擇的模式: {{ selectedMode }} (一段時間自動結束程式)</p>
                <div class="filter">
                  <label for="schedule_time">設定排程時間</label>
                  <input
                    class="schedule-time-box"
                    type="text"
                    id="schedule_time"
                    placeholder="分鐘"
                    v-model="schedule_time"
                  />
                  <label for="schedule_time">分</label>
                </div>
                <p>剩餘時間: {{ counter_time }} 分</p>
              </template>
            </div>
            <template v-if="selectedMode === '手動'">
              <button
                class="btn-tips-submit"
                @click="enable_lottery_manual_mode"
              >
                啟動
              </button>
            </template>
            <template v-if="selectedMode === '定時'">
              <button
                class="btn-tips-submit"
                @click="enable_lottery_cycle_mode"
              >
                啟動
              </button>
            </template>
            <button class="btn-tips-close" @click="closeLotteryLoopTestConfig">
              關閉
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <!-- 彩票循環回歸測試報告 -->
  <transition name="modal-showLotteryLoopReport">
    <div class="modal" v-if="showLotteryLoopReport">
      <div>
        <div v-if="showLotteryLoopReport" class="modal">
          <div class="modal3-content modal-content-size-36">
            <span
              @click="closeShowLotteryLoopReport"
              class="btn-right-top-close"
              >&times;</span
            >
            <h2>XX循環回歸測試報告</h2>
            <button class="btn-csv-tips" @click="exportToCSV">導出 CSV</button>
            <table class="lottery-cycle-report-table">
              <thead>
                <tr>
                  <th>測試項目</th>
                  <th>詳情</th>
                  <th>時間</th>
                  <th>結果</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="lottery_report in lottery_cycle_report[env]"
                  :key="lottery_report.id"
                >
                  <template
                    v-if="lottery_report.hasOwnProperty('testCaseName')"
                  >
                    <td>
                      {{ lottery_report.testCaseName }}
                    </td>
                    <td>
                      <div
                        v-if="lottery_report.status == 'fail'"
                        class="btn-detail setting-man-left-80 setting-width-30 setting-height-24"
                        @click="displayLotteryErrStep(lottery_report.errStep)"
                      >
                        查看
                      </div>
                      <div
                        v-if="lottery_report.status == 'pass'"
                        class="btn-detail setting-man-left-80 setting-width-30 setting-height-24"
                        @click="displayLotteryPassDetail(lottery_report.detail)"
                      >
                        查看
                      </div>
                    </td>
                    <td>
                      {{ lottery_report.datetime }}
                    </td>
                    <td>
                      <button
                        v-if="lottery_report.status === 'pass'"
                        class="btn-pass"
                      >
                        pass
                      </button>
                      <button
                        v-else-if="lottery_report.status === 'fail'"
                        class="btn-fail"
                      >
                        fail
                      </button>
                    </td>
                  </template>
                </tr>
              </tbody>
            </table>

            <button class="btn-tips-close" @click="closeShowLotteryLoopReport">
              關閉
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <!-- 錯誤原因視窗 -->
  <transition name="modal-showLotteryErrStep">
    <div class="modal" v-if="showLotteryErrStep">
      <div>
        <div v-if="showLotteryErrStep" class="modal">
          <div class="modal3-content modal-content-size-36">
            <span @click="closeShowLotteryErrStep" class="btn-right-top-close"
              >&times;</span
            >
            <h2>錯誤原因</h2>
            <div class="container-column">
              <textarea
                class="custom-textarea"
                rows="4"
                v-model="errStep"
              ></textarea>
              <button class="btn-tips-close" @click="closeShowLotteryErrStep">
                關閉
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal-showLotteryPassDetail">
    <div class="modal" v-if="showLotteryPassDetail">
      <div>
        <div v-if="showLotteryPassDetail" class="modal">
          <div class="modal3-content modal-content-size-36">
            <span
              @click="closeShowLotteryPassDetail"
              class="btn-right-top-close"
              >&times;</span
            >
            <h2>詳細資訊</h2>
            <div class="container-column">
              <textarea
                class="custom-textarea"
                v-model="stringifiedDetail"
                rows="15"
              ></textarea>
              <button
                class="btn-tips-close"
                @click="closeShowLotteryPassDetail"
              >
                關閉
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <!-- 彩票設定檔 -->
  <transition name="modal-displayEnvironmentConfig">
    <div class="modal" v-if="displayEnvironmentConfig">
      <div>
        <div v-if="displayEnvironmentConfig" class="modal">
          <div class="modal-content">
            <span @click="closeEnvironmentConfig" class="btn-right-top-close"
              >&times;</span
            >
            <h3>彩票環境參數</h3>
            <div class="lottery-setting-container">
              <div class="filter">
                <label for="platform_froestage_domain">彩票前台</label>
                <input
                  class="lottery-box"
                  type="text"
                  id="platform_front_domain"
                  v-model="platform_front_domain"
                />
              </div>
              <div class="filter">
                <label for="platform_background_domain">彩票後台</label>
                <input
                  class="lottery-box"
                  type="text"
                  id="platform_back_domain"
                  v-model="platform_back_domain"
                />
              </div>
              <div class="filter-invite">
                <label for="platform_background_domain">邀請碼</label>
                <input
                  class="lottery-box"
                  type="text"
                  id="platform_invite_code"
                  v-model="platform_invite_code"
                />
              </div>
            </div>

            <button class="btn-tips-close" @click="closeEnvironmentConfig">
              關閉
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <div class="loading-overlay-two" v-if="loading">
    <div class="spinner"></div>
  </div>
</template>

<script>
import Sidebar from "@/components/sidebar/SidebarCom";
import { MD5 } from "crypto-js";
import Papa from "papaparse";
import Swal from "sweetalert2";
import {
  RequestUtil,
  handelSuccessAlertUtil,
  handelErrorAlertUtil,
  handelFailAlertUtil,
} from "@/commonUtils.js";

export default {
  // 綁定元件
  components: { Sidebar },
  computed: {
    stringifiedDetail() {
      return JSON.stringify(this.passDetail, null, 2); // 美化輸出
    },
  },
  data() {
    return {
      loading: false,
      version: "domestic",
      intervalId: null,
      lotteryLoopIntervalId: null,
      lotteryManualIntervalId: null,
      errStep: false,
      switchState: false,
      isTimerRunning: false,
      LotteryCycleIsTimerRunning: false,
      showLotteryLoopReport: false,
      displayLotteryLoopTestConfig: false,
      seconds: 0,
      LotteryCycleSeconds: 0,
      total_time: 0,
      counter_time: 0,
      schedule_time: "",
      lotteryTaskKey: "",
      lotteryTaskId: "",
      passDetail: {},
      showLotteryErrStep: false,
      showLotteryPassDetail: false,
      displayEnvironmentConfig: false,
      lotteryConfigList: [],
      platform_front_domain: "",
      platform_back_domain: "",
      platform_invite_code: "",
      // 控制前端介面狀態顯示
      test1ApiStatus: "",
      test2ApiStatus: "",
      preApiStatus: "",
      proApiStatus: "",
      test1ApiStatusColor: "",
      test2ApiStatusColor: "",
      preApiStatusColor: "",
      proApiStatusColor: "",

      test1APIcycleStatus: "",
      test2APIcycleStatus: "",
      preAPIcycleStatus: "",
      proAPIcycleStatus: "",

      test1APIcycleConfigStatus: "設定檔關閉",
      test1APIcycleStatusColor: "",
      test1APIcycleConfigStatusColor: "gray",
      test2APIcycleConfigStatus: "設定檔關閉",
      test2APIcycleStatusColor: "",
      test2APIcycleConfigStatusColor: "gray",
      preAPIcycleConfigStatus: "設定檔關閉",
      preAPIcycleStatusColor: "",
      preAPIcycleConfigStatusColor: "gray",
      proAPIcycleConfigStatus: "設定檔關閉",
      proAPIcycleStatusColor: "",
      proAPIcycleConfigStatusColor: "gray",

      platform_env: "",
      platform_switch: "",
      platform_mode: "",
      count: "",

      selectedMode: "手動", // 默認手動
      settingModes: [
        { id: "1", name: "手動" },
        { id: "2", name: "定時" },
      ],
      envDict: {
        test1: "lotteryTaskKeyTest1",
        test2: "lotteryTaskKeyTest2",
        pre: "lotteryTaskKeyPre",
        pro: "lotteryTaskKeyPro",
      },
      lottery_cycle_report: { test1: [], test2: [], pre: [], pro: [] },
    };
  },
  // 進入 component 頁面則發送請求
  created() {
    this.checkPlatformStatus();
    this.requestLotteryConfigSatatus();
    this.fetchLotteryCycleTaskId();
    this.stopSchedule();
    this.checkVersion();
  },
  methods: {
    exportToCSV() {
      // 假设你有一个包含数据的数组
      const data = [["測試項目", "詳情", "時間", "結果"]];
      // lottery_cycle_report
      for (let i = 0; i < this.lottery_cycle_report.length; i++) {
        data.push([
          this.lottery_cycle_report[i].testCaseName,
          this.lottery_cycle_report[i].errStep,
          this.lottery_cycle_report[i].datetime,
          this.lottery_cycle_report[i].status,
        ]);
      }

      // 使用PapaParse将数据转换为CSV字符串
      const csv = Papa.unparse(data);

      // 创建一个Blob对象
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      // 创建一个下载链接并触发下载
      const link = document.createElement("a");
      const url = window.URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "testReport.csv");
      document.body.appendChild(link);
      link.click();

      // 清理资源
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    },
    toggleSwitch(status) {
      if (status === "on") {
        null;
      }
    },
    async watchEnvironmentConfig(env) {
      await this.fetchEnvironmentConfig(env);
      this.displayEnvironmentConfig = true;
    },
    async watchLotteryLoopTestConfig(env) {
      this.env = env;
      this.displayLotteryLoopTestConfig = true;
    },
    closeLotteryLoopTestConfig() {
      this.displayLotteryLoopTestConfig = false;
    },
    setStatus(name, status) {
      if (status === "running") {
        this[`${name}Status`] = "running";
        this[`${name}StatusColor`] = "green";
      } else if (status === "idle") {
        this[`${name}Status`] = "idle";
        this[`${name}StatusColor`] = "orange";
      }
    },
    setConfigStatus(name, status) {
      if (status === "on") {
        this[`${name}StatusColor`] = "green";
      } else if (status === "close") {
        this[`${name}StatusColor`] = "gray";
      }
    },
    // 確認當前環境
    checkVersion() {
      const url = "/v2/api/fetch_version";
      this.axios
        .post(url)
        .then((response) => {
          const status = response.data.status;

          // 在這裡處理成功回應的邏輯
          if (status === "1") {
            const version = response.data.version;
            this.version = version;
          } else if (status === "-1") {
            handelFailAlertUtil(response);
          } else {
            // localStorage.removeItem("task_id");
            handelFailAlertUtil(response);
          }
        })
        .catch((error) => {
          // 在這裡處理錯誤回應的邏輯
          handelErrorAlertUtil(error);
        });
    },
    // 確認平台自動化狀態
    checkPlatformStatus() {
      const url = "/v2/api/platform_report_status";
      this.axios
        .post(url)
        .then((response) => {
          // 在這裡處理成功回應的邏輯
          const result = response.data.result;
          this.setStatus("test1Api", "idle");
          this.setStatus("test2Api", result.test2API);
          this.setStatus("preApi", result.preAPI);
          this.setStatus("proApi", result.proAPI);
          this.setStatus("test1APIcycle", result.test1APIcycle);
          this.setStatus("test2APIcycle", result.test2APIcycle);
          this.setStatus("preAPIcycle", result.preAPIcycle);
          this.setStatus("proAPIcycle", result.proAPIcycle);
        })
        .catch((error) => {
          // 在這裡處理錯誤回應的邏輯
          error.data;
        });
    },
    async fetchEnvironmentConfig(env) {
      this.loading = true;
      const url = "/v1/api/fetch_lottery_task_config";
      const dict = { env: env };
      const response = await this.axios.post(url, dict);
      try {
        const status = response.data.status;

        // 在這裡處理成功回應的邏輯
        if (status === "1") {
          const result = response.data.result;
          this.platform_env = result.env;
          this.platform_switch = result.switch;
          this.platform_mode = result.mode;
          this.platform_front_domain = result.platform_front_domain;
          this.platform_back_domain = result.platform_back_domain;
          this.platform_invite_code = result.platform_invite_code;
        } else if (status === "-1") {
          handelFailAlertUtil(response);
        } else {
          handelErrorAlertUtil(response);
        }
      } catch (error) {
        handelErrorAlertUtil(error);
      } finally {
        this.loading = false;
      }
    },
    async fetchLotteryModeConfig(env) {
      this.loading = true;
      const url = "/v1/api/fetch_lottery_mode_setting";
      const dict = { env: env };
      const response = await this.axios.post(url, dict);
      try {
        const status = response.data.status;
        // 在這裡處理成功回應的邏輯
        if (status === "1") {
          const result = response.data.result;
          this.selectedMode = result.lottery_mode;
        } else if (status === "-1") {
          handelFailAlertUtil(response);
        } else {
          handelErrorAlertUtil(response);
        }
      } catch (error) {
        handelErrorAlertUtil(error);
      } finally {
        this.loading = false;
      }
    },
    async fetchLotteryCycleCount(env) {
      const url = "/v1/api/fetch_lottery_cycle_count";
      const dict = { env: env, mode: "cycle_api" };
      const response = await this.axios.post(url, dict);
      try {
        const status = response.data.status;
        // 在這裡處理成功回應的邏輯
        if (status === "1") {
          const result = response.data.result;
          this.counter_time = result.count;
        } else if (status === "-1") {
          handelFailAlertUtil(response);
        } else {
          handelErrorAlertUtil(response);
        }
      } catch (error) {
        handelErrorAlertUtil(error);
      }
    },
    fetchLotteryCycleTaskId() {
      const url = "/v2/api/lottery_cycle_task_id";
      this.axios
        .post(url)
        .then((response) => {
          // 在這裡處理成功回應的邏輯
          const result = response.data.result;
          this.envDict.test1 = result.test1_task_id;
          this.envDict.test2 = result.test2_task_id;
          this.envDict.pre = result.pre_task_id;
          this.envDict.pro = result.pro_task_id;
          localStorage.setItem("lotteryTaskKeyTest1", result.test1_task_id);
          localStorage.setItem("lotteryTaskKeyTest2", result.test2_task_id);
          localStorage.setItem("lotteryTaskKeyPre", result.pre_task_id);
          localStorage.setItem("lotteryTaskKeyPro", result.pro_task_id);
        })
        .catch((error) => {
          // 在這裡處理錯誤回應的邏輯
          error.data;
        });
    },
    // 關閉排程
    stopSchedule() {
      const task = localStorage.getItem("task");
      clearInterval(task);
      this.intervalId = null;
      this.switchState = false;
      this.isTimerRunning = false;
    },
    // 關閉排程
    stopLotteryCycleSchedule() {
      const LotteryCycleTask = localStorage.getItem("LotteryCycleTask");
      clearInterval(LotteryCycleTask);
      this.lotteryLoopIntervalId = null;
      this.switchState = false;
      this.LotteryCycleIsTimerRunning = false;
    },
    // 呈現報告內容
    showReport(env, mode, platform) {
      const url = "/v1/api/fetch_report";
      const dict = {
        env: env,
        mode: mode,
        platform: platform,
      };
      this.axios
        .post(url, dict)
        .then((response) => {
          const status = response.data.status;
          const content = response.data.content;

          // 在這裡處理成功回應的邏輯
          if (status === "1") {
            Swal.fire({
              title: "報告",
              html: "<div style='text-align: left;'>" + content + "</div>",
              customClass: "text-left",
              icon: "",
              width: "800px", // 設定彈窗寬度
              padding: "3em", // 設定彈窗內容的 padding
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "關閉",
            });
          } else {
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          // 在這裡處理錯誤回應的邏輯
          handelErrorAlertUtil(error);
        });
    },
    fetchRedisReport(env, platform, task_id) {
      const url = "/v2/api/lottery_cycle_report";
      const dict = {
        env: env,
        platform: platform,
        task_id: task_id,
      };
      this.axios
        .post(url, dict)
        .then((response) => {
          const status = response.data.status;

          // 在這裡處理成功回應的邏輯
          if (status === "1") {
            this.lottery_cycle_report[env] = response.data.report;
          } else {
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          // 在這裡處理錯誤回應的邏輯
          handelErrorAlertUtil(error);
        });
    },
    updateLotteryCycleReport(env, platform) {
      if (env === "test1") {
        const taskKey = this.envDict.test1;
        this.fetchRedisReport(env, platform, taskKey);
      } else if (env === "test2") {
        const taskKey = this.envDict.test2;
        this.fetchRedisReport(env, platform, taskKey);
      } else if (env === "pre") {
        const taskKey = this.envDict.pre;
        this.fetchRedisReport(env, platform, taskKey);
      } else if (env === "pro") {
        const taskKey = this.envDict.pro;
        this.fetchRedisReport(env, platform, taskKey);
      }
    },
    createLotteryTaskKey() {
      // 获取当前时间戳（以毫秒为单位）
      const timestamp = Date.now();
      // 将时间戳转换为字符串
      const timestampString = timestamp.toString();
      this.lotteryTaskKey = MD5(timestampString).toString();
      if (this.env === "test1") {
        localStorage.setItem("lotteryTaskKeyTest1", this.lotteryTaskKey);
      } else if (this.env === "test2") {
        localStorage.setItem("lotteryTaskKeyTest2", this.lotteryTaskKey);
      } else if (this.env === "pre") {
        localStorage.setItem("lotteryTaskKeyPre", this.lotteryTaskKey);
      } else if (this.env === "pro") {
        localStorage.setItem("lotteryTaskKeyPro", this.lotteryTaskKey);
      }
    },
    async updateLotteryModeSetting(lottery_mode) {
      const url = "/v1/api/update_lottery_mode_setting";
      const dict = {
        env: this.env,
        lottery_mode: lottery_mode,
      };
      const response = await this.axios.post(url, dict);
      try {
        const status = response.data.status;
        // 在這裡處理成功回應的邏輯
        if (status === "1") {
          null;
        } else if (status === "-1") {
          handelFailAlertUtil(response);
        } else {
          handelErrorAlertUtil(response);
        }
      } catch (error) {
        handelErrorAlertUtil(error);
      }
    },
    enable_lottery_manual_mode() {
      this.createLotteryTaskKey();
      const url = "/v1/api/lottery_task";
      const dict = {
        env: this.env,
        mode: "manual_api",
        switch: "on",
        task_key: this.lotteryTaskKey,
      };
      RequestUtil(this, url, dict);
      // 重新獲取 task id
      this.fetchLotteryCycleTaskId();
      this.updateLotteryModeSetting("手動");
    },
    enable_lottery_cycle_mode() {
      this.createLotteryTaskKey();
      const url = "/v1/api/lottery_task";
      const dict = {
        env: this.env,
        mode: "cycle_api",
        switch: "on",
        task_key: this.lotteryTaskKey,
        schedule_time: this.schedule_time,
      };
      this.counter_time = this.schedule_time;
      RequestUtil(this, url, dict);
      this.updateLotteryModeSetting("定時");
    },
    close_lottery_mode(env) {
      this.close_lottery_mode_request(env);
    },
    // 關閉循環模式
    close_lottery_mode_request(env) {
      const url = "/v1/api/lottery_task";
      const dict = {
        env: env,
        switch: "off",
      };
      this.axios
        .post(url, dict)
        .then((response) => {
          const status = response.data.status;

          // 在這裡處理成功回應的邏輯
          if (status === "1") {
            handelSuccessAlertUtil(response);
          } else if (status === "-1") {
            // 例外則關閉排程
            this.close_lottery_loop_mode();
            handelFailAlertUtil(response);
          } else {
            this.close_lottery_loop_mode();
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          this.close_lottery_loop_mode();
          handelErrorAlertUtil(error);
        });
    },
    closeEnvironmentConfig() {
      this.displayEnvironmentConfig = false;
    },
    async displayLotteryCycleConfig(env, mode) {
      this.mode = mode;
      this.env = env;
      this.watchLotteryLoopTestConfig(env);
      await this.fetchLotteryCycleCount(env);
      await this.fetchLotteryModeConfig(env);
    },
    enable_lottery_mode(mode) {
      const url = "/v1/api/lottery_task";
      // mode: "cycle_api",
      // mode: "manual_api",
      const dict = {
        env: this.env,
        mode: mode,
        task_key: this.lotteryTaskKey,
      };
      this.axios
        .post(url, dict)
        .then((response) => {
          const status = response.data.status;

          // 在這裡處理成功回應的邏輯
          if (status === "1") {
            const report = response.data.report;
            this.lottery_cycle_report = report;
          } else if (status === "-1") {
            // 例外則關閉排程
            this.close_lottery_loop_mode();
            handelFailAlertUtil(response);
          } else {
            this.close_lottery_loop_mode();
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          handelErrorAlertUtil(error);
        });
    },
    enable_single_lottery(env, mode) {
      const url = "/v1/api/lottery_task";
      const dict = {
        env: env,
        mode: mode,
        task_key: "",
        switch: "",
        schedule_time: "",
      };
      this.axios
        .post(url, dict)
        .then((response) => {
          const status = response.data.status;

          // 在這裡處理成功回應的邏輯
          if (status === "1") {
            handelSuccessAlertUtil(response);
          } else if (status === "-1") {
            handelFailAlertUtil(response);
          } else {
            handelFailAlertUtil(response);
          }
        })
        .catch((error) => {
          // 在這裡處理錯誤回應的邏輯
          handelErrorAlertUtil(error);
        });
    },
    health_check(env, platform) {
      const url = "/v1/api/check_environment_health";
      this.axios
        .post(url, {
          env: env,
          platform: platform,
        })
        .then((response) => {
          const status = response.data.status;
          if (status == "1") {
            handelSuccessAlertUtil(response);
          } else if (status == "-1") {
            handelFailAlertUtil(response);
          } else {
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          handelErrorAlertUtil(error);
        });
    },
    updateUiSatatus() {
      const url = "/v2/api/platform_report_status";
      this.axios
        .post(url)
        .then((response) => {
          const result = response.data.result;
          result;
          this.setStatus("test1APIcycle", "idel");
          this.setStatus("test2APIcycle", "idel");
          this.setStatus("preAPIcycle", "idel");
          this.setStatus("proAPIcycle", "idel");
        })
        .catch((error) => {
          // 在這裡處理錯誤回應的邏輯
          error.data;
          this.stopLotteryCycleSchedule(); // 關閉排程
        });
    },
    updateLotteryConfigStatus() {
      this.lotteryConfigList.forEach((item) => {
        if (
          item.env == "test1" &&
          item.mode == "cycle_api" &&
          item.status == "on"
        ) {
          this.setConfigStatus("test1APIcycleConfig", "on");
          this.test1APIcycleConfigStatus = "計時模式開啟中";
        }
        if (
          item.env == "test1" &&
          item.mode == "manual_api" &&
          item.status == "on"
        ) {
          this.setConfigStatus("test1APIcycleConfig", "on");
          this.test1APIcycleConfigStatus = "手動模式開啟中";
        }
        if (
          item.env == "test2" &&
          item.mode == "cycle_api" &&
          item.status == "on"
        ) {
          this.setConfigStatus("test2APIcycleConfig", "on");
          this.test2APIcycleConfigStatus = "計時模式開啟中";
        }
        if (
          item.env == "test2" &&
          item.mode == "manual_api" &&
          item.status == "on"
        ) {
          this.setConfigStatus("test2APIcycleConfig", "on");
          this.test2APIcycleConfigStatus = "手動模式開啟中";
        }
        if (
          item.env == "pre" &&
          item.mode == "cycle_api" &&
          item.status == "on"
        ) {
          this.setConfigStatus("preAPIcycleConfig", "on");
          this.preAPIcycleConfigStatus = "計時模式開啟中";
        }
        if (
          item.env == "pre" &&
          item.mode == "manual_api" &&
          item.status == "on"
        ) {
          this.setConfigStatus("preAPIcycleConfig", "on");
          this.preAPIcycleConfigStatus = "手動模式開啟中";
        }
        if (
          item.env == "pro" &&
          item.mode == "cycle_api" &&
          item.status == "on"
        ) {
          this.setConfigStatus("proAPIcycleConfig", "on");
          this.proAPIcycleConfigStatus = "計時模式開啟中";
        }
        if (
          item.env == "pro" &&
          item.mode == "manual_api" &&
          item.status == "on"
        ) {
          this.setConfigStatus("proAPIcycleConfig", "on");
          this.proAPIcycleConfigStatus = "手動模式開啟中";
        }
      });
    },
    // 獲取彩票爬蟲設定檔狀態
    requestLotteryConfigSatatus() {
      const url = "/v2/api/platform_config_status";
      this.axios
        .post(url)
        .then((response) => {
          this.lotteryConfigList = response.data.result;
          this.updateLotteryConfigStatus();
        })
        .catch((error) => {
          // 在這裡處理錯誤回應的邏輯
          error.data;
          this.stopLotteryCycleSchedule(); // 關閉排程
        });
    },
    toggleTimer() {
      this.isTimerRunning = !this.isTimerRunning;
      if (this.isTimerRunning) {
        this.intervalId = setInterval(() => {
          this.seconds++;
          this.axios
            .post("/v2/api/lottery_report_status")
            .then((response) => {
              const result = response.data.result;
              const test1API = result.test1API;
              const test2API = result.test2API;
              const preAPI = result.preAPI;
              const proAPI = result.proAPI;

              if (test1API == "running") {
                this.test1ApiStatus = "running";
                this.test1ApiStatusColor = "green";
              }
              if (test1API == "idle") {
                this.test1ApiStatus = "idle";
                this.test1ApiStatusColor = "orange";
              }
              if (test2API == "running") {
                this.test2ApiStatus = "running";
                this.test2ApiStatusColor = "green";
              }
              if (test2API == "idle") {
                this.test2ApiStatus = "idle";
                this.test2ApiStatusColor = "orange";
              }
              if (preAPI == "running") {
                this.preApiStatus = "running";
                this.preApiStatusColor = "green";
              }
              if (preAPI == "idle") {
                this.preApiStatus = "idle";
                this.preApiStatusColor = "orange";
              }
              if (proAPI == "running") {
                this.proApiStatus = "running";
                this.proApiStatusColor = "green";
              }
              if (proAPI == "idle") {
                this.proApiStatus = "idle";
                this.proApiStatusColor = "orange";
              }
            })
            .catch((error) => {
              // 在這裡處理錯誤回應的邏輯
              error.data;
              this.stopSchedule(); // 關閉排程
            });
        }, 5000);
        localStorage.setItem("task", this.intervalId);
      } else {
        this.stopSchedule(); // 關閉排程
      }
    },
    toggleLotterSetting() {
      this.isTimerRunning = !this.isTimerRunning;
      if (this.isTimerRunning) {
        null;
      }
    },
    LottryCycleToggleTimer() {
      this.LotteryCycleIsTimerRunning = !this.LotteryCycleIsTimerRunning;
      const trigger_time = 10 * 1000;
      const platform = "lottery";
      if (this.LotteryCycleIsTimerRunning) {
        this.lotteryLoopIntervalId = setInterval(() => {
          this.LotteryCycleSeconds++;
          this.fetchLotteryCycleTaskId();
          this.updateUiSatatus();
          this.requestLotteryConfigSatatus();
          this.updateLotteryCycleReport("test1", platform);
          this.updateLotteryCycleReport("test2", platform);
          this.updateLotteryCycleReport("pre", platform);
          this.updateLotteryCycleReport("pro", platform);
        }, trigger_time);
        localStorage.setItem("LotteryCycleTask", this.lotteryLoopIntervalId);
      } else {
        this.stopLotteryCycleSchedule(); // 關閉排程
      }
    },
    displayShowLotteryLoopReport(env, platform) {
      this.updateLotteryCycleReport(env, platform);
      this.env = env;
      this.showLotteryLoopReport = true;
    },
    closeShowLotteryLoopReport() {
      this.showLotteryLoopReport = false;
    },
    closeShowLotteryErrStep() {
      this.showLotteryErrStep = false;
    },
    displayLotteryErrStep(errStep) {
      this.errStep = errStep;
      this.showLotteryErrStep = true;
    },
    displayLotteryPassDetail(passDetail) {
      this.passDetail = passDetail;
      this.showLotteryPassDetail = true;
    },
    closeShowLotteryPassDetail(passDetail) {
      this.passDetail = passDetail;
      this.showLotteryPassDetail = false;
    },
  },
};
</script>
