<template>
  <Sidebar />
  <!-- <div>
    <p class="hiden">{{ seconds }}</p>
    <label class="switch">
      <input type="checkbox" @click="toggleTimer" />
      {{ isTimerRunning ? "Stop" : "Start" }}
      <span class="slider"></span>
    </label>
  </div> -->
  <span class="checkbox-tips">任務狀態開關</span>
  <div class="card setting-mgn-top setting-height-550 setting-width-1310">
    <div class="title-h2-1">加密貨幣自動化</div>
    <div class="card-body">
      <div class="container-column-left">
        <ul class="nav nav-tabs">
          <li :class="{ active: activeTab === 'api' }">
            <a @click="changeTab('api')">API測試</a>
          </li>
          <li :class="{ active: activeTab === 'ui' }">
            <a @click="changeTab('ui')">UI測試</a>
          </li>
          <li :class="{ active: activeTab === 'andriod' }">
            <a @click="changeTab('andriod')">Android測試</a>
          </li>
        </ul>
        <div class="tab-content">
          <div
            class="tab-pane fade"
            :class="{ in: activeTab === 'api' }"
            id="api"
          >
            <div class="container-row">
              <div class="card setting-height-340">
                <div class="header">
                  <div class="title-h2 setting-mgn-top-50">Test1 API</div>
                  <button
                    class="btn-gear"
                    @click="watchEnvironmentConfig('test1')"
                  >
                    <i class="fa fa-gear"></i>
                  </button>
                </div>
                <div class="card-body">
                  <button
                    class="btn btn-primary setting-mgn-10-50-50-10"
                    @click="enable_weline_task('test1', 'api')"
                  >
                    啟動
                  </button>
                  <button
                    class="btn btn-primary setting-mgn-10-50-50-10"
                    @click="health_check('test1')"
                  >
                    健康檢查
                  </button>
                  <button
                    class="btn btn-secondary setting-mgn-10-50-50-10"
                    :style="{ backgroundColor: test1ApiStatusColor }"
                  >
                    {{ test1ApiStatus }}
                  </button>
                  <button
                    class="btn btn-secondary setting-mgn-10-50-50-10"
                    @click="displayShowWelineReport('test1', 'weline')"
                  >
                    報告
                  </button>
                </div>
              </div>
              <div class="card setting-height-340">
                <div class="header">
                  <div class="title-h2 setting-mgn-left-50">Test2 API</div>
                  <button
                    class="btn-gear"
                    @click="watchEnvironmentConfig('test2')"
                  >
                    <i class="fa fa-gear"></i>
                  </button>
                </div>
                <div class="card-body">
                  <button
                    class="btn btn-primary setting-mgn-10-50-50-10"
                    @click="enable_weline_task('test2', 'api')"
                  >
                    啟動
                  </button>
                  <button
                    class="btn btn-primary setting-mgn-10-50-50-10"
                    @click="health_check('test2')"
                  >
                    健康檢查
                  </button>
                  <button
                    class="btn btn-secondary setting-mgn-10-50-50-10"
                    :style="{ backgroundColor: test2ApiStatusColor }"
                  >
                    {{ test2ApiStatus }}
                  </button>
                  <button
                    class="btn btn-secondary setting-mgn-10-50-50-10"
                    @click="displayShowWelineReport('test2', 'weline')"
                  >
                    報告
                  </button>
                </div>
              </div>
              <div class="card setting-height-340">
                <div class="header">
                  <div class="title-h2 setting-mgn-left-50">Pre API</div>
                  <button
                    class="btn-gear"
                    @click="watchEnvironmentConfig('pre')"
                  >
                    <i class="fa fa-gear"></i>
                  </button>
                </div>
                <div class="card-body">
                  <button
                    class="btn btn-primary setting-mgn-10-50-50-10"
                    @click="enable_weline_task('pre', 'api')"
                  >
                    啟動
                  </button>
                  <button
                    class="btn btn-primary setting-mgn-10-50-50-10"
                    @click="health_check('pre')"
                  >
                    健康檢查
                  </button>
                  <button
                    class="btn btn-secondary setting-mgn-10-50-50-10"
                    :style="{ backgroundColor: preApiStatusColor }"
                  >
                    {{ preApiStatus }}
                  </button>
                  <button
                    class="btn btn-secondary setting-mgn-10-50-50-10"
                    @click="displayShowWelineReport('pre', 'weline')"
                  >
                    報告
                  </button>
                </div>
              </div>
              <div class="card setting-height-340">
                <div class="header">
                  <div class="title-h2 setting-mgn-left-50">Pro API</div>
                  <button
                    class="btn-gear"
                    @click="watchEnvironmentConfig('pro')"
                  >
                    <i class="fa fa-gear"></i>
                  </button>
                </div>
                <div class="card-body">
                  <button
                    class="btn btn-primary setting-mgn-10-50-50-10"
                    @click="enable_weline_task('pro', 'api')"
                  >
                    啟動
                  </button>
                  <button
                    class="btn btn-primary setting-mgn-10-50-50-10"
                    @click="health_check('pro')"
                  >
                    健康檢查
                  </button>
                  <button
                    class="btn btn-secondary setting-mgn-10-50-50-10"
                    :style="{ backgroundColor: proApiStatusColor }"
                  >
                    {{ proApiStatus }}
                  </button>
                  <button
                    class="btn btn-secondary setting-mgn-10-50-50-10"
                    @click="displayShowWelineReport('pro', 'weline')"
                  >
                    報告
                  </button>
                </div>
              </div>
              <div class="loading-overlay-two" v-if="loading">
                <div class="spinner"></div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            :class="{ in: activeTab === 'ui' }"
            id="ui"
          >
            <div class="container-row">
              <div class="card">
                <div class="header">
                  <div class="title-h2 setting-mgn-left-50">Test1 UI</div>
                  <button
                    class="btn-gear"
                    @click="watchEnvironmentConfig('test1')"
                  >
                    <i class="fa fa-gear"></i>
                  </button>
                </div>
                <div class="card-body">
                  <button
                    class="btn btn-primary setting-mgn-10-50-50-10"
                    @click="enable('test1', 'ui')"
                  >
                    啟動
                  </button>
                  <button
                    class="btn btn-secondary setting-mgn-10-50-50-10"
                    :style="{ backgroundColor: test1UiStatusColor }"
                  >
                    {{ test1UiStatus }}
                  </button>
                  <button
                    class="btn btn-secondary setting-mgn-10-50-50-10"
                    @click="showReport('test1', 'browser', 'weline')"
                  >
                    報告
                  </button>
                </div>
              </div>
              <div class="card">
                <div class="header">
                  <div class="title-h2 setting-mgn-left-50">Test2 UI</div>
                  <button
                    class="btn-gear"
                    @click="watchEnvironmentConfig('test2')"
                  >
                    <i class="fa fa-gear"></i>
                  </button>
                </div>
                <div class="card-body">
                  <button
                    class="btn btn-primary setting-mgn-10-50-50-10"
                    @click="enable('test2', 'ui')"
                  >
                    啟動
                  </button>
                  <button
                    class="btn btn-secondary setting-mgn-10-50-50-10"
                    :style="{ backgroundColor: test2UiStatusColor }"
                  >
                    {{ test2UiStatus }}
                  </button>
                  <button
                    class="btn btn-secondary setting-mgn-10-50-50-10"
                    @click="showReport('test2', 'browser', 'weline')"
                  >
                    報告
                  </button>
                </div>
              </div>
              <div class="card">
                <div class="header">
                  <div class="title-h2 setting-mgn-left-50">Pre UI</div>
                  <button
                    class="btn-gear"
                    @click="watchEnvironmentConfig('pre')"
                  >
                    <i class="fa fa-gear"></i>
                  </button>
                </div>
                <div class="card-body">
                  <button
                    class="btn btn-primary setting-mgn-10-50-50-10"
                    @click="enable('pre', 'ui')"
                  >
                    啟動
                  </button>
                  <button
                    class="btn btn-secondary setting-mgn-10-50-50-10"
                    :style="{ backgroundColor: preUiStatusColor }"
                  >
                    {{ preUiStatus }}
                  </button>
                  <button
                    class="btn btn-secondary setting-mgn-10-50-50-10"
                    @click="showReport('pre', 'browser', 'weline')"
                  >
                    報告
                  </button>
                </div>
              </div>
              <div class="card">
                <div class="header">
                  <div class="title-h2 setting-mgn-left-50">Pro UI</div>
                  <button
                    class="btn-gear"
                    @click="watchEnvironmentConfig('pro')"
                  >
                    <i class="fa fa-gear"></i>
                  </button>
                </div>
                <div class="card-body">
                  <button
                    class="btn btn-primary setting-mgn-10-50-50-10"
                    @click="enable('pro', 'ui')"
                  >
                    啟動
                  </button>
                  <button
                    class="btn btn-secondary setting-mgn-10-50-50-10"
                    :style="{ backgroundColor: proUiStatusColor }"
                  >
                    {{ proUiStatus }}
                  </button>
                  <button
                    class="btn btn-secondary setting-mgn-10-50-50-10"
                    @click="showReport('pro', 'browser', 'weline')"
                  >
                    報告
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <transition name="modal-displayEnvironmentConfig">
    <div class="modal" v-if="displayEnvironmentConfig">
      <div>
        <div v-if="displayEnvironmentConfig" class="modal">
          <div class="modal-content">
            <span @click="closeEnvironmentConfig" class="btn-right-top-close"
              >&times;</span
            >
            <h3>加密貨幣環境參數</h3>
            <div class="addPushPlan-container">
              <div class="filter">
                <label for="platform_froestage_domain">加密貨幣前台</label>
                <input
                  class="search-box"
                  type="text"
                  id="platform_froestage_domain"
                  v-model="platform_froestage_domain"
                />
              </div>
              <div class="filter">
                <label for="platform_background_domain">加密貨幣後台</label>
                <input
                  class="search-box"
                  type="text"
                  id="platform_background_domain"
                  v-model="platform_background_domain"
                />
              </div>
            </div>

            <button class="btn-tips-close" @click="closeEnvironmentConfig">
              關閉
            </button>
            <!-- <button class="btn-submit" @click="submitDisplayEnvironmentConfig">
              編輯
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </transition>
  <!-- 微聊循環回歸測試報告 -->
  <transition name="modal-showWelineReport">
    <div class="modal" v-if="showWelineReport">
      <div>
        <div v-if="showWelineReport" class="modal">
          <div class="modal3-content modal-content-size-36">
            <span @click="closeShowWelineReport" class="btn-right-top-close"
              >&times;</span
            >
            <h2>加密貨幣回歸測試報告</h2>
            <button class="btn-csv-tips" @click="exportToCSV">導出 CSV</button>
            <table class="lottery-cycle-report-table">
              <thead>
                <tr>
                  <th>測試項目</th>
                  <th>詳情</th>
                  <th>時間</th>
                  <th>結果</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="weline_report in weline_cycle_report[env]"
                  :key="weline_report.id"
                >
                  <template v-if="weline_report.hasOwnProperty('testCaseName')">
                    <td>
                      {{ weline_report.testCaseName }}
                    </td>
                    <td>
                      <div
                        v-if="weline_report.status == 'fail'"
                        class="btn-detail setting-man-left-80 setting-width-30 setting-height-24"
                        @click="displayWelineErrStep(weline_report.detail)"
                      >
                        查看
                      </div>
                      <div
                        v-if="weline_report.status == 'pass'"
                        class="btn-detail setting-man-left-80 setting-width-30 setting-height-24"
                        @click="displayWelinePassDetail(weline_report.detail)"
                      >
                        查看
                      </div>
                    </td>
                    <td>
                      {{ weline_report.datetime }}
                    </td>
                    <td>
                      <button
                        v-if="weline_report.status === 'pass'"
                        class="btn-pass"
                      >
                        pass
                      </button>
                      <button
                        v-else-if="weline_report.status === 'fail'"
                        class="btn-fail"
                      >
                        fail
                      </button>
                    </td>
                  </template>
                </tr>
              </tbody>
            </table>

            <button class="btn-tips-close" @click="closeShowWelineReport">
              關閉
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <!-- 錯誤原因視窗 -->
  <transition name="modal-showWelineErrStep">
    <div class="modal" v-if="showWelineErrStep">
      <div>
        <div v-if="showWelineErrStep" class="modal">
          <div class="modal3-content modal-content-size-36">
            <span @click="closeShowWelineErrStep" class="btn-right-top-close"
              >&times;</span
            >
            <h2>錯誤原因</h2>
            <div class="container-column">
              <textarea
                class="custom-textarea"
                rows="15"
                v-model="stringifiedDetail"
              ></textarea>
              <button class="btn-tips-close" @click="closeShowWelineErrStep">
                關閉
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal-showWelinePassDetail">
    <div class="modal" v-if="showWelinePassDetail">
      <div>
        <div v-if="showWelinePassDetail" class="modal">
          <div class="modal3-content modal-content-size-36">
            <span @click="closeShowWelinePassDetail" class="btn-right-top-close"
              >&times;</span
            >
            <h2>詳細資訊</h2>
            <div class="container-column">
              <textarea
                class="custom-textarea"
                v-model="stringifiedDetail"
                rows="15"
              ></textarea>
              <button class="btn-tips-close" @click="closeShowWelinePassDetail">
                關閉
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Swal from "sweetalert2";
import { MD5 } from "crypto-js";
import Sidebar from "@/components/sidebar/SidebarCom";
import {
  RequestUtil,
  handelSuccessAlertUtil,
  handelErrorAlertUtil,
  handelFailAlertUtil,
} from "@/commonUtils.js";

export default {
  // 綁定元件
  components: { Sidebar },
  computed: {
    stringifiedDetail() {
      return JSON.stringify(this.passDetail, null, 2); // 美化輸出
    },
  },
  data() {
    return {
      intervalId: null,
      loading: false,
      switchState: false,
      isTimerRunning: false,
      displayEnvironmentConfig: false,
      platform_froestage_domain: "",
      platform_background_domain: "",
      weline_froestage_domain: "",
      weline_background_domain: "",
      seconds: 0,
      activeTab: "api",
      errStep: false,
      showWelineReport: false,
      showWelineErrStep: false,
      showWelinePassDetail: false,
      welineTaskKey: "",
      passDetail: {},
      detail: {},
      envDict: {
        test1: "welineTaskKeyTest1",
        test2: "welineTaskKeyTest2",
        pre: "welineTaskKeyPre",
        pro: "welineTaskKeyPro",
      },
      weline_cycle_report: { test1: [], test2: [], pre: [], pro: [] },

      test1ApiStatus: "",
      test2ApiStatus: "",
      preApiStatus: "",
      proApiStatus: "",
      test1ApiStatusColor: "",
      test2ApiStatusColor: "",
      preApiStatusColor: "",
      proApiStatusColor: "",

      test1UiStatus: "",
      test2UiStatus: "",
      preUiStatus: "",
      proUiStatus: "",
      test1UiStatusColor: "",
      test2UiStatusColor: "",
      preUiStatusColor: "",
      proUiStatusColor: "",

      androidTest1TaskId: "",
      androidTest2TaskId: "",
      androidPreTaskId: "",
      androidProTaskId: "",

      androidTest1TaskStatus: "",
      androidTest2TaskStatus: "",
      androidPreTaskStatus: "",
      androidProTaskStatus: "",

      androidTest1CompleteRate: "NA",
      androidTest2CompleteRate: "NA",
      androidPreCompleteRate: "NA",
      androidProCompleteRate: "NA",

      androidTest1StatusColor: "",
      androidTest2StatusColor: "",
      androidPreStatusColor: "",
      androidProStatusColor: "",

      androidFileName: "",
    };
  },
  // 進入 component 頁面則發送請求
  created() {
    this.checkStatus();
    this.stopSchedule();
    this.fetchWelineCycleTaskId();
    const currentDomain = window.location.hostname;
    // 目前只實作國內版，之後國際版實作完畢把這個判斷式拔掉
    if (currentDomain === "localhost" || currentDomain === "0.0.0.0") {
      // this.androidUpdateTaskIdAndStatus(); // 更新安卓狀態
      null;
    }
  },
  methods: {
    SendRequestUtil(url, dict) {
      this.axios
        .post(url, dict)
        .then((response) => {
          const status = response.data.status;

          // 在這裡處理成功回應的邏輯
          if (status === "1") {
            handelSuccessAlertUtil(response);
          } else if (status === "-1") {
            handelFailAlertUtil(response);
          } else {
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          handelErrorAlertUtil(error);
        });
    },
    watchEnvironmentConfig() {
      // this.fetchEnvironmentConfig(env);
      this.displayEnvironmentConfig = true;
    },
    closeEnvironmentConfig() {
      this.displayEnvironmentConfig = false;
    },
    fetchEnvironmentConfig(env) {
      const url = "/v1/api/fetch_weline_auto_test_environment_config";
      const dict = { env: env };
      this.axios
        .post(url, dict)
        .then((response) => {
          const status = response.data.status;

          // 在這裡處理成功回應的邏輯
          if (status === "1") {
            const result = response.data.result;
            this.platform_froestage_domain = result.platform_front_domain;
            this.platform_background_domain = result.platform_back_domain;
            this.weline_froestage_domain = result.weline_domain;
            this.weline_background_domain = result.wl_backstage_domain;
          } else if (status === "-1") {
            handelFailAlertUtil(response);
          } else {
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          handelErrorAlertUtil(error);
        });
    },
    changeTab(tab) {
      this.activeTab = tab;
    },
    setStatus(apiName, apiStatus, uiName, uiStatus) {
      if (apiStatus === "running") {
        this[`${apiName}Status`] = "running";
        this[`${apiName}StatusColor`] = "green";
      }
      if (apiStatus === "idle") {
        this[`${apiName}Status`] = "idle";
        this[`${apiName}StatusColor`] = "orange";
      }
      if (uiStatus === "running") {
        this[`${uiName}Status`] = "running";
        this[`${uiName}StatusColor`] = "green";
      }
      if (uiStatus === "idle") {
        this[`${uiName}Status`] = "idle";
        this[`${uiName}StatusColor`] = "orange";
      }
    },
    // 確認微聊自動化狀態
    checkStatus() {
      const url = "/v2/api/weline_report_status";
      this.axios
        .post(url)
        .then((response) => {
          const result = response.data.result;
          console.log(result.test1API);
          this.setStatus("test1Api", "idle", "test1Ui", "idle");
          this.setStatus("test2Api", "idle", "test2Ui", "idle");
          this.setStatus("preApi", "idle", "preUi", "idle");
          this.setStatus("proApi", "idle", "proUi", "idle");
          // 在這裡處理成功回應的邏輯
        })
        .catch((error) => {
          // 在這裡處理錯誤回應的邏輯
          this.Test1isChecked = false;
          error.data;
        });
    },
    // 關閉排程
    stopSchedule() {
      const task = localStorage.getItem("task");
      clearInterval(task);
      this.intervalId = null;
      this.switchState = false;
      this.isTimerRunning = false;
    },
    // 呈現報告內容
    showReport(env, mode, platform) {
      let task_id;
      const url = "/v1/api/fetch_report";
      if (env == "test1") {
        task_id = this.androidTest1TaskId;
      } else if (env == "test2") {
        task_id = this.androidTest2TaskId;
      } else if (env == "pre") {
        task_id = this.androidPreTaskId;
      } else if (env == "pro") {
        task_id = this.androidProTaskId;
      }
      this.axios
        .post(url, {
          env: env,
          mode: mode,
          platform: platform,
          task_id: task_id, // android 任務的選項，不填也可以
        })
        .then((response) => {
          const status = response.data.status;
          const content = response.data.content;

          // 在這裡處理成功回應的邏輯
          if (status === "1") {
            Swal.fire({
              title: "報告",
              html: "<div style='text-align: left;'>" + content + "</div>",
              customClass: "text-left",
              icon: "",
              width: "800px", // 設定彈窗寬度
              padding: "3em", // 設定彈窗內容的 padding
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "關閉",
            });
          } else {
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          handelErrorAlertUtil(error);
        });

      // const content =
    },
    enable(env, mode) {
      this.axios
        .post("/v1/api/weline_task", {
          env: env,
          mode: mode,
        })
        .then((response) => {
          const status = response.data.status;
          if (status == "1") {
            Swal.fire({
              title: "Success!",
              text: "收到任務", // 显示响应数据
              icon: "success",
              confirmButtonText: "OK",
            });
          } else {
            Swal.fire({
              title: "Failed!",
              text: JSON.stringify(response.data), // 显示响应数据
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        })
        .catch((error) => {
          // 在這裡處理錯誤回應的邏輯
          Swal.fire({
            title: "Failed!",
            text: JSON.stringify(error.data), // 显示响应数据
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    },
    enable_weline_task(env, mode) {
      this.createWelineTaskKey();
      const url = "/v1/api/weline_task";
      const dict = {
        env: env,
        mode: mode,
        task_id: this.welineTaskKey,
      };
      RequestUtil(this, url, dict);
      // 重新獲取 task id
      this.fetchWelineCycleTaskId();
    },
    health_check(env) {
      const url = "/v1/api/check_environment_health";
      this.loading = true;
      this.axios
        .post(url, {
          env: env,
        })
        .then((response) => {
          const status = response.data.status;
          if (status == "1") {
            Swal.fire({
              title: "Success !",
              text: "環境正常", // 显示响应数据
              icon: "success",
              confirmButtonText: "OK",
            });
          } else {
            Swal.fire({
              title: "Fail !",
              text: JSON.stringify(response.data), // 显示响应数据
              icon: "warning",
              confirmButtonText: "OK",
            });
          }
        })
        .catch((error) => {
          handelErrorAlertUtil(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    toggleTimer() {
      const url = "/v1/api/weline_report_status";
      this.isTimerRunning = !this.isTimerRunning;
      if (this.isTimerRunning) {
        this.intervalId = setInterval(() => {
          this.seconds++;
          this.axios
            .post(url)
            .then((response) => {
              const result = response.data.result;
              const test1API = result.test1API;
              const test2API = result.test2API;
              const preAPI = result.preAPI;
              const proAPI = result.proAPI;

              const test1Ui = result.test1Ui;
              const test2Ui = result.test2Ui;
              const preUi = result.preUi;
              const proUi = result.proUi;

              if (test1API == "running") {
                this.test1ApiStatus = "running";
                this.test1ApiStatusColor = "green";
              }
              if (test1API == "idle") {
                this.test1ApiStatus = "idle";
                this.test1ApiStatusColor = "orange";
              }
              if (test2API == "running") {
                this.test2ApiStatus = "running";
                this.test2ApiStatusColor = "green";
              }
              if (test2API == "idle") {
                this.test2ApiStatus = "idle";
                this.test2ApiStatusColor = "orange";
              }
              if (preAPI == "running") {
                this.preApiStatus = "running";
                this.preApiStatusColor = "green";
              }
              if (preAPI == "idle") {
                this.preApiStatus = "idle";
                this.preApiStatusColor = "orange";
              }
              if (proAPI == "running") {
                this.proApiStatus = "running";
                this.proApiStatusColor = "green";
              }
              if (proAPI == "idle") {
                this.proApiStatus = "idle";
                this.proApiStatusColor = "orange";
              }
              if (test1Ui == "running") {
                this.test1UiStatus = "running";
                this.test1UiStatusColor = "green";
              }
              if (test1Ui == "idle") {
                this.test1UiStatus = "idle";
                this.test1UiStatusColor = "orange";
              }
              if (test2Ui == "running") {
                this.test2UiStatus = "running";
                this.test2UiStatusColor = "green";
              }
              if (test2Ui == "idle") {
                this.test2UiStatus = "idle";
                this.test2UiStatusColor = "orange";
              }
              if (preUi == "running") {
                this.preUiStatus = "running";
                this.preUiStatusColor = "green";
              }
              if (preUi == "idle") {
                this.preUiStatus = "idle";
                this.preUiStatusColor = "orange";
              }
              if (proUi == "running") {
                this.proUiStatus = "running";
                this.proUiStatusColor = "green";
              }
              if (proUi == "idle") {
                this.proUiStatus = "idle";
                this.proUiStatusColor = "orange";
              }
            })
            .catch((error) => {
              // 在這裡處理錯誤回應的邏輯
              error.data;
              this.stopSchedule(); // 關閉排程
            });
          const currentDomain = window.location.hostname;
          // 目前只實作國內版，之後國際版實作完畢把這個判斷式拔掉
          if (
            currentDomain === "172.31.44.19" ||
            currentDomain === "localhost" ||
            currentDomain === "0.0.0.0"
          ) {
            this.androidUpdateTaskIdAndStatus(); // 更新安卓
          }
          this.fetchWelineCycleTaskId(); // 更新當前 task id
        }, 5000);
        localStorage.setItem("task", this.intervalId);
      } else {
        this.stopSchedule(); // 關閉排程
      }
    },
    androidUpdateTaskIdAndStatus() {
      const url = "/v2/api/weline_android_status";
      this.axios
        .post(url)
        .then((response) => {
          this.androidUpdateAllInfo(response.data.results);
          this.androidFileName = response.data.file_name;
        })
        .catch((response) => {
          handelErrorAlertUtil(response);
        });
    },
    // 更新所有環境安卓 TaskStatus Color TaskId
    androidUpdateAllInfo(results) {
      results.forEach((result) => {
        this.androidSetTaskStatus(result.env, result.task_status);
        this.androidSetColor(result.env, result.task_status);
        this.androidSetTaskId(result.env, result.task_id);
        this.androidSetCompleteRate(result.env, result.complete_rate);
      });
    },
    // 更新單一環境安卓 TaskStatus Color TaskId
    androidUpdateInfo(result) {
      this.androidSetTaskStatus(result.env, result.task_status);
      this.androidSetColor(result.env, result.task_status);
      this.androidSetTaskId(result.env, result.task_id);
    },
    androidSetTaskStatus(env, taskStatus) {
      if (env == "test1") {
        this.androidTest1TaskStatus = taskStatus;
      } else if (env == "test2") {
        this.androidTest2TaskStatus = taskStatus;
      } else if (env == "pre") {
        this.androidPreTaskStatus = taskStatus;
      } else if (env == "pro") {
        this.androidProTaskStatus = taskStatus;
      }
    },
    androidSetColor(env, taskStatus) {
      let color = "";
      if (taskStatus == "running") {
        color = "green";
      } else if (taskStatus == "idle") {
        color = "orange";
      } else if (taskStatus == "task failed") {
        color = "red";
      }

      if (env == "test1") {
        this.androidTest1StatusColor = color;
      } else if (env == "test2") {
        this.androidTest2StatusColor = color;
      } else if (env == "pre") {
        this.androidPreStatusColor = color;
      } else if (env == "pro") {
        this.androidProStatusColor = color;
      }
    },
    androidSetTaskId(env, taskId) {
      if (env == "test1") {
        this.androidTest1TaskId = taskId;
      } else if (env == "test2") {
        this.androidTest2TaskId = taskId;
      } else if (env == "pre") {
        this.androidPreTaskId = taskId;
      } else if (env == "pro") {
        this.androidProTaskId = taskId;
      }
    },
    androidTaskStart(env) {
      this.axios
        .post("/v1/api/start_weline_android", {
          env: env,
        })
        .then((response) => {
          if (response.data.result == "task exist") {
            Swal.fire({
              title: `[Android ${env}] 任務已存在，且尚未執行結束`,
              text: `task id: ${response.data.task_id} 任務已存在，且尚未執行結束`, // 顯示回應內容
              icon: "success",
              confirmButtonText: "OK",
            });
            this.androidUpdateInfo({
              env: env,
              task_id: response.data.task_id,
              task_status: "running",
            });
          } else if (response.data.result == "task created") {
            Swal.fire({
              title: `[Android ${env}] 任務開始成功`,
              text: `task id: ${response.data.task_id} 任務已新增`, // 顯示回應內容
              icon: "success",
              confirmButtonText: "OK",
            });
            this.androidUpdateInfo({
              env: env,
              task_id: response.data.task_id,
              task_status: "running",
            });
          } else {
            Swal.fire({
              title: `[Android ${env}] 任務開始失敗`,
              text: JSON.stringify(response.data.fail_reason), // 顯示回應內容
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        })
        .catch((error) => {
          alert(`[Android ${env}] 任務開始時發生錯誤 ${error}`);
          Swal.fire({
            title: `[Android ${env}] 上傳發生錯誤`,
            text: JSON.stringify(error), // 顯示回應內容
            icon: "error",
            confirmButtonText: "OK",
          });
        });
      this.androidTaskStatus = env; //debug
    },
    androidSetCompleteRate(env, completeRate) {
      if (env == "test1") {
        this.androidTest1CompleteRate = completeRate;
      } else if (env == "test2") {
        this.androidTest2CompleteRate = completeRate;
      } else if (env == "pre") {
        this.androidPreCompleteRate = completeRate;
      } else if (env == "pro") {
        this.androidProCompleteRate = completeRate;
      }
    },
    handleFileChange(event) {
      this.file = event.target.files[0];
    },
    androidUploadFile() {
      if (this.file) {
        const url = "/v1/api/upload_weline_android";
        const formData = new FormData();
        formData.append("file", this.file);
        this.axios
          .post(url, formData)
          .then((response) => {
            if (response.data.status == 1) {
              Swal.fire({
                title: "檔案上傳成功",
                text: "檔名：" + response.data.result + " 上傳成功", // 显示响应数据
                icon: "success",
                confirmButtonText: "OK",
              });
              this.androidFileName = response.data.result;
            } else {
              // 在這裡處理錯誤回應的邏輯
              Swal.fire({
                title: "檔案上傳失敗",
                text: JSON.stringify(response.data.result), // 显示响应数据
                icon: "error",
                confirmButtonText: "OK",
              });
            }
          })
          .catch((error) => {
            // 在這裡處理錯誤回應的邏輯
            Swal.fire({
              title: "Failed!",
              text: JSON.stringify(error.data), // 显示响应数据
              icon: "error",
              confirmButtonText: "OK",
            });
          });
      } else {
        Swal.fire({
          title: "請選擇檔案",
          text: "請選擇檔案", // 显示响应数据
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    },
    androidGetFileName() {
      const url = "/v1/api/get_weline_android_apk_file_name";
      this.axios
        .post(url)
        .then((response) => {
          if (response.data.status == 1) {
            this.androidFileName = response.data.result;
            console.log(response.data.result);
          } else {
            this.androidFileName = "cat not get right file name";
          }
        })
        .catch((error) => {
          // 在這裡處理錯誤回應的邏輯
          Swal.fire({
            title: "Failed!",
            text: JSON.stringify(error.data), // 显示响应数据
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    },
    createWelineTaskKey() {
      // 获取当前时间戳（以毫秒为单位）
      const timestamp = Date.now();
      // 将时间戳转换为字符串
      const timestampString = timestamp.toString();
      this.welineTaskKey = MD5(timestampString).toString();
      if (this.env === "test1") {
        localStorage.setItem("welineTaskKeyTest1", this.welineTaskKey);
      } else if (this.env === "test2") {
        localStorage.setItem("welineTaskKeyTest2", this.welineTaskKey);
      } else if (this.env === "pre") {
        localStorage.setItem("welineTaskKeyPre", this.welineTaskKey);
      } else if (this.env === "pro") {
        localStorage.setItem("welineTaskKeyPro", this.welineTaskKey);
      }
    },
    fetchWelineCycleTaskId() {
      const url = "/v2/api/weline_cycle_task_id";
      this.axios
        .post(url)
        .then((response) => {
          // 在這裡處理成功回應的邏輯
          const result = response.data.result;
          this.envDict.test1 = result.test1_task_id;
          this.envDict.test2 = result.test2_task_id;
          this.envDict.pre = result.pre_task_id;
          this.envDict.pro = result.pro_task_id;
          localStorage.setItem("welineTaskKeyTest1", result.test1_task_id);
          localStorage.setItem("welineTaskKeyTest2", result.test2_task_id);
          localStorage.setItem("welineTaskKeyPre", result.pre_task_id);
          localStorage.setItem("welineTaskKeyPro", result.pro_task_id);
        })
        .catch((error) => {
          // 在這裡處理錯誤回應的邏輯
          error.data;
        });
    },
    fetchWelineDBReport(env, weline, task_id) {
      const url = "/v2/api/weline_cycle_report";
      const dict = {
        env: env,
        weline: weline,
        task_id: task_id,
      };
      this.axios
        .post(url, dict)
        .then((response) => {
          const status = response.data.status;

          // 在這裡處理成功回應的邏輯
          if (status === "1") {
            this.weline_cycle_report[env] = response.data.report;
          } else {
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          // 在這裡處理錯誤回應的邏輯
          handelErrorAlertUtil(error);
        });
    },
    updateWelineReport(env, weline) {
      if (env === "test1") {
        const taskKey = this.envDict.test1;
        this.fetchWelineDBReport(env, weline, taskKey);
      } else if (env === "test2") {
        const taskKey = this.envDict.test2;
        this.fetchWelineDBReport(env, weline, taskKey);
      } else if (env === "pre") {
        const taskKey = this.envDict.pre;
        this.fetchWelineDBReport(env, weline, taskKey);
      } else if (env === "pro") {
        const taskKey = this.envDict.pro;
        this.fetchWelineDBReport(env, weline, taskKey);
      }
    },
    closeShowWelineReport() {
      this.showWelineReport = false;
    },
    closeShowWelineErrStep() {
      this.showWelineErrStep = false;
    },
    closeShowWelinePassDetail() {
      this.showWelinePassDetail = false;
      this.showLotteryPassDetail = false;
    },
    displayShowWelineReport(env, weline) {
      this.updateWelineReport(env, weline);
      this.env = env;
      this.showWelineReport = true;
    },
    displayWelinePassDetail(passDetail) {
      this.passDetail = passDetail;
      this.showWelinePassDetail = true;
    },
    displayWelineErrStep(passDetail) {
      this.passDetail = passDetail;
      this.showWelineErrStep = true;
    },
  },
};
</script>

<head>
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css">
</head>
