<template>
  <Sidebar />
  <div>
    <p class="hiden">{{ seconds }}</p>
    <label class="switch">
      <input type="checkbox" @click="toggleTimer" />
      {{ isTimerRunning ? "Stop" : "Start" }}
      <span class="slider"></span>
    </label>
  </div>
  <div class="title">遊戲自動化</div>
  <span class="checkbox-tips">任務狀態開關</span>
  <div class="container-row">
    <div class="card setting-height-340">
      <div class="header">
        <div class="title-h2 setting-mgn-left-50">Test1</div>
        <button class="btn-gear" @click="watchGameConfig('test1')">
          <i class="fa fa-gear"></i>
        </button>
      </div>
      <div class="card-body">
        <button
          class="btn btn-primary setting-mgn-10-50-50-10"
          @click="startGameTask('test1')"
        >
          啟動
        </button>
        <button
          class="btn btn-secondary setting-mgn-10-50-50-10"
          :style="{ backgroundColor: test1StatusColor }"
        >
          {{ test1Status }}
        </button>
        <button
          class="btn btn-secondary setting-mgn-10-50-50-10"
          @click="
            fetchReport('test1', this.test1TaskId);
            fetchLastTask('test1');
          "
        >
          報告
        </button>
      </div>
    </div>
  </div>
  <transition name="modal-displayGameConfig">
    <div class="modal" v-if="displayGameConfig">
      <div>
        <div v-if="displayGameConfig" class="modal">
          <div class="modal-content">
            <span
              @click="
                {
                  displayGameConfig = false;
                }
              "
              class="btn-right-top-close"
              >&times;</span
            >
            <h3>遊戲參數</h3>
            <div>
              如果有選擇，只跑有選擇的遊戲:
              <div class="android-card" style="max-width: 150px; margin: auto">
                <label v-for="code in defaultGameCodes" :key="code">
                  <input
                    type="checkbox"
                    :value="code"
                    v-model="choosenGameCodes"
                  />
                  {{ code }}
                </label>
              </div>
              <button
                class="btn-reset"
                @click="
                  {
                    choosenGameCodes = [];
                  }
                "
              >
                重置選項
              </button>
            </div>
            <div><br /></div>
            <button
              class="btn-tips-close"
              @click="
                {
                  displayGameConfig = false;
                }
              "
            >
              關閉
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Sidebar from "@/components/sidebar/SidebarCom";
import {
  handelSuccessAlertUtil,
  handelErrorAlertUtil,
  handelFailAlertUtil,
} from "@/commonUtils.js";
import Swal from "sweetalert2";

export default {
  // 綁定元件
  components: { Sidebar },
  data() {
    return {
      isTimerRunning: false,
      intervalId: null,
      switchState: false,
      displayGameConfig: false,

      test1TaskId: "",
      test2TaskId: "",
      preTaskId: "",
      proTaskId: "",

      test1Status: "",
      test2Status: "",
      test3Status: "",
      test4Status: "",
      preStatus: "",

      test1StatusColor: "",
      test2StatusColor: "",
      test3StatusColor: "",
      test4StatusColor: "",
      preStatusColor: "",
      defaultGameCodes: [
        2001, // 百人牛牛
        2002, // 红黑大戰
        2003, // 龍虎
        2004, // 百家樂
        2005, // 奔馳寶馬
        2006, // 骰寶
        2007, // 番攤
        2008, // 色碟
        2009, // 魚蝦蟹
        2010, // 森林舞會
        2011, // 泰式比高低
        2012, // 安達巴哈
        2013, // 印度炸金花
        4001, // Crash
        3001, // 象神
        3002, // 水果機
        4002, // Mines
        4003, // Plinko
      ],
      choosenGameCodes: [],
    };
  },
  // 進入 component 頁面則發送請求
  created() {
    this.fetchLastTask("test1");
  },
  methods: {
    toggleTimer() {
      this.isTimerRunning = !this.isTimerRunning;
      if (this.isTimerRunning) {
        this.intervalId = setInterval(() => {
          this.seconds++;
          this.fetchLastTask("test1");
        }, 5000);
        localStorage.setItem("task", this.intervalId);
      } else {
        this.stopSchedule(); // 關閉排程
      }
    },
    // 關閉排程
    stopSchedule() {
      const task = localStorage.getItem("task");
      clearInterval(task);
      this.intervalId = null;
      this.switchState = false;
      this.isTimerRunning = false;
    },
    setTaskId(env, taskId) {
      if (env == "test1") {
        this.test1TaskId = taskId;
      } else if (env == "test2") {
        this.test2TaskId = taskId;
      } else if (env == "pre") {
        this.preTaskId = taskId;
      } else if (env == "pro") {
        this.proTaskId = taskId;
      }
    },
    fetchLastTask(env) {
      const url = "/v1/api/fetch_game_last_task";
      this.axios
        .get(url, {
          params: {
            env: env,
          },
        })
        .then((response) => {
          const status = response.data.status;
          // 在這裡處理成功回應的邏輯
          if (status === "1") {
            const taskId = response.data.task.id;
            this.setTaskId(env, taskId);
            const taskStatus = response.data.taskStatus;
            this[`${env}Status`] = taskStatus;
            this.setColor(env);
          } else if (status === "-1") {
            handelFailAlertUtil(response);
          } else {
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          handelErrorAlertUtil(error);
        });
    },
    startGameTask(env) {
      const url = "/v1/api/start_game_test";
      const codesToUse =
        this.choosenGameCodes.length !== 0
          ? this.choosenGameCodes
          : this.defaultGameCodes;
      this.axios
        .post(url, {
          env: env,
          game_codes: codesToUse,
        })
        .then((response) => {
          const taskId = response.data.task_id;
          this.setTaskId(env, taskId);
          const status = response.data.status;
          // 在這裡處理成功回應的邏輯
          if (status === "1") {
            handelSuccessAlertUtil(response);
          } else if (status === "-1") {
            handelFailAlertUtil(response);
          } else {
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          handelErrorAlertUtil(error);
        });
    },
    setColor(env) {
      if (this[`${env}Status`] === "running") {
        this[`${env}StatusColor`] = "green";
      }
      if (this[`${env}Status`] === "idle") {
        this[`${env}StatusColor`] = "orange";
      }
    },
    formatReport(rawData) {
      // 深層複製 rawData，以避免修改原始數據
      let newData = JSON.parse(rawData);
      // 遍歷 result 中的每個數字
      for (let key in newData.result) {
        if (Array.isArray(newData.result[key])) {
          if (newData.result[key].length > 0) {
            // 如果不是空陣列，檢查是否全部都是 PASS
            let allPass = newData.result[key].every(
              (item) => item.status === "PASS"
            );

            if (allPass) {
              // 如果全部都是 PASS，修改成 { "status": "PASS" }
              newData.result[key] = { status: "PASS" };
            } else {
              // 如果有 FAIL，保留 FAIL 的部分
              newData.result[key] = newData.result[key].filter(
                (item) => item.status === "FAIL"
              );
            }
          }
          // 如果是空陣列，保持為空
        }
      }

      // 返回修改後的 newData
      return newData;
    },
    fetchReport(env, taskId) {
      const url = "/v1/api/fetch_game_report_by_task_id";
      this.axios
        .get(url, {
          params: {
            env: env,
            taskId: taskId,
          },
        })
        .then((response) => {
          const content = response.data.report.report;
          const status = response.data.status;

          // 在這裡處理成功回應的邏輯
          if (status === "1") {
            let parsedContent = this.formatReport(content);
            // 使用 JSON.stringify 重新格式化並添加縮排
            const formattedContent = JSON.stringify(parsedContent, null, 2);

            Swal.fire({
              title: "報告",
              html: `<pre style='text-align: left;'>${formattedContent}</pre>`,
              customClass: "text-left",
              icon: "",
              width: "800px", // 設定彈窗寬度
              padding: "3em", // 設定彈窗內容的 padding
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "關閉",
            });
          } else if (status === "-1") {
            handelFailAlertUtil(response);
          } else {
            handelErrorAlertUtil(response);
          }
        })
        .catch((error) => {
          handelErrorAlertUtil(error);
        });
    },
    watchGameConfig() {
      this.displayGameConfig = true;
    },
  },
};
</script>
