<template>
  <div class="container-column">
    <div class="login-image">
      <img
        src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
        class="img-fluid"
        alt="Sample image"
      />
    </div>
    <div class="login">
      <form>
        <div class="form-login">
          <input
            type="text"
            id="username"
            v-model="username"
            class="form-control form-control-lg"
            placeholder="Enter username"
            @keyup.enter="login"
          />
        </div>

        <!-- Password input -->
        <div class="form-login">
          <input
            type="password"
            id="password"
            v-model="password"
            class="form-control form-control-lg"
            placeholder="Enter password"
            @keyup.enter="login"
          />
        </div>
        <button
          type="button"
          class="btn-submit setting-length-500 setting-mgn-btn-0-60-0-20"
          @click.prevent="login"
        >
          <span v-if="!loading">Login</span>
          <span v-else>Loading...</span>
        </button>

        <!-- Email input -->
      </form>
      <!-- 載入動畫 -->
      <div class="loading-overlay" v-if="loading">
        <div class="spinner"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
export default {
  name: "LoginPage",
  data() {
    return {
      username: "",
      password: "",
      loading: false,
    };
  },
  created() {
    localStorage.removeItem("token");
  },
  methods: {
    login() {
      const url = "/v2/api/login";
      const dict = { username: this.username, password: this.password };
      this.loading = true;
      this.axios
        .post(url, dict)
        .then((response) => {
          const result = response.data.result;
          const token = response.data.token;
          if (result === "success") {
            localStorage.setItem("token", token);
            this.$router.push({ path: "/home" });
          }
          // 在這裡處理成功回應的邏輯
          else if (result === "fail") {
            localStorage.setItem("token", token);
            Swal.fire({
              title: "Fail!",
              text: "帳號密碼錯誤", // 显示响应数据
              icon: "error",
              confirmButtonText: "OK",
            });
          } else {
            localStorage.removeItem("token");
            this.$router.push({ path: "/login" });
          }
        })
        .catch((error) => {
          // 在這裡處理錯誤回應的邏輯
          error.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
