<script>
import SidebarLink from "./SidebarLink";
import { collapsed, toggleSidebar, sidebarWidth, TITLE } from "./state";
export default {
  props: {},
  components: { SidebarLink },
  setup() {
    const currentDomain = window.location.hostname;
    const showLotteryLink = currentDomain === "localhost"; // 根據當前域名設定是否顯示彩票連結
    return { collapsed, toggleSidebar, sidebarWidth, TITLE, showLotteryLink };
  },
};
</script>

<template>
  <div class="sidebar" :style="{ width: sidebarWidth }">
    <h1>
      <span v-if="collapsed"></span>
      <span v-else>{{ TITLE }}</span>
    </h1>
    <!-- icon 參考網站 -->
    <!-- https://fontawesome.com/icons/hammer?s=solid&f=sharp -->
    <!-- <i class="fa-solid fa-person-skating"></i> -->
    <SidebarLink to="/home" icon="fas fa-home">首頁</SidebarLink>
    <SidebarLink to="/lottery" icon="fas fa-hammer">XX自動化</SidebarLink>
    <SidebarLink to="/weline" icon="fas fa-hammer">加密貨幣自動化</SidebarLink>
    <!-- 遊戲測試目前只實作國際版 -->
    <!-- <SidebarLink
      to="/game"
      icon="fas fa-hammer"
      v-if="
        TITLE === '國際' ||
        currentDomain === '0.0.0.0' ||
        currentDomain === 'localhost'
      "
      >遊戲自動化</SidebarLink
    > -->
    <!-- <SidebarLink to="/lotteryKill" icon="fa-solid fa-trowel"
      >XX殺率</SidebarLink
    >
    <SidebarLink to="/pushPlanManagement" icon="fas fa-wrench"
      >XX工具</SidebarLink
    > -->
    <SidebarLink to="/welineTool" icon="fas fa-wrench">微聊工具</SidebarLink>
    <SidebarLink to="/management" icon="fas fa-chart-bar">管理列表</SidebarLink>
    <SidebarLink to="/addManager" icon="fas fa-person-skating"
      >新增管理</SidebarLink
    >
    <!-- <SidebarLink to="/errorImage" icon="fas fa-font-awesome"
      >微聊錯誤日誌</SidebarLink
    > -->
    <!-- <SidebarLink
      v-if="showLotteryLink"
      to="/welineLog"
      icon="fas fa-chalkboard-user"
      >彩票後台</SidebarLink
    >
    <SidebarLink
      v-if="showLotteryLink"
      to="/welineLog"
      icon="fas fa-paper-plane"
      >微聊後台</SidebarLink
    > -->
    <!-- <SidebarLink to="/image" icon="fas fa-image">Images</SidebarLink> -->
    <SidebarLink to="/logout" icon="fas fa-right-from-bracket"
      >登出</SidebarLink
    >

    <span
      class="collapse-icon"
      :class="{ 'rotate-180': collapsed }"
      @click="toggleSidebar"
    >
      <i class="fa-solid fa-bars" />
    </span>
  </div>
</template>

<style>
:root {
  --sidebar-bg-color: #2f855a;
  --sidebar-item-hover: #38a169;
  --sidebar-item-active: #276749;
}
</style>

<style scoped>
.sidebar {
  color: white;
  background-color: var(--sidebar-bg-color);

  float: left;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 0.5em;
  margin-left: 0px;
  transition: 0.3s ease;

  display: flex;
  flex-direction: column;
}

.sidebar h1 {
  height: 2.5em;
}

.collapse-icon {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0.75em;
  margin-bottom: 800px;
  margin-left: 8px;

  color: rgba(255, 255, 255, 0.7);

  transition: 0.2s linear;
}

.rotate-180 {
  transform: rotate(180deg);
  transition: 0.2s linear;
}
</style>
