<template>
  <div class="center">
    <img
      src="https://img.freepik.com/free-vector/404-error-lost-space-concept-illustration_114360-7871.jpg?w=1060&t=st=1678345853~exp=1678346453~hmac=7ad592363d7f922ad645b963e013fda00bb2761eab3d24e7d8dc9b627a791ae8"
    />
  </div>
</template>

<style scoped>
.center {
  display: block;
  margin-top: 80px;
  margin-right: 200px;
  /* margin: 0 auto; */
}
</style>
