<template>
  <!-- 導航列表 -->
  <div class="layout-center">
    <div class="block">
      <div class="menu-bar-layout">
        <div class="logo-block">
          <img src="https://fakeimg.pl/100x50" alt="Logo" class="logo" />
        </div>
        <div class="menu-bar">
          <div class="h-6" />
          <el-menu class="el-menu-demo" mode="horizontal">
            <el-menu-item index="1">
              <router-link to="/">首頁</router-link>
            </el-menu-item>
            <el-sub-menu index="2">
              <template #title>我們的工作</template>
              <el-menu-item index="2-1">
                <router-link to="/">認養五分港溪</router-link>
              </el-menu-item>
              <el-menu-item index="2-2">
                <router-link to="/">認養如意湖</router-link>
              </el-menu-item>
              <el-menu-item index="2-3">微生物組工法應用</el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="3">
              <template #title>參與行動</template>
              <el-menu-item index="2-1">定期巡視ㄧ</el-menu-item>
              <el-menu-item index="2-2">定期巡視二</el-menu-item>
              <el-menu-item index="2-3">定期巡視三</el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="4">
              <template #title>歷年故事</template>
              <el-menu-item index="2-1">2024</el-menu-item>
              <el-menu-item index="2-2">2023</el-menu-item>
              <el-menu-item index="2-3">2022</el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="5">
              <template #title>關於我們</template>
              <el-menu-item index="2-1">一</el-menu-item>
              <el-menu-item index="2-2">二</el-menu-item>
              <el-menu-item index="2-3">三</el-menu-item>
              <el-menu-item index="2-4">四</el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="6">
              <template #title>捐贈</template>
              <el-menu-item index="2-1">item one</el-menu-item>
              <el-menu-item index="2-2">item two</el-menu-item>
              <el-menu-item index="2-3">item three</el-menu-item>
            </el-sub-menu>
            <el-menu-item>其他</el-menu-item>
          </el-menu>
        </div>
        <div class="search-label">
          <input
            type="text"
            id="username"
            v-model="username"
            placeholder="搜尋文章"
          />
        </div>
      </div>
    </div>

    <!-- 上方圖片區塊 -->
    <div class="splide-size">
      <Splide :options="splideOptions">
        <SplideSlide v-for="(image, index) in images" :key="index">
          <img :src="image" :alt="'Slide ' + (index + 1)" />
        </SplideSlide>
      </Splide>
    </div>

    <div>
      <h1 :style="{ fontFamily: selectedFont }">大标题</h1>
    </div>
    <div class="container-row-center margin-top-80">
      <div class="card-size1 padding-10">
        <Card
          :style="{
            width: '100%',
            height: '97%',
            overflow: 'hidden',
            fontFamily: selectedFont,
          }"
        >
          <template #title>Advanced Card</template>
          <template #content>
            <p class="m-0">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Inventore sed consequuntur error repudiandae numquam deserunt
              quisquam repellat libero asperiores earum nam nobis, culpa ratione
              quam perferendis esse, cupiditate neque quas! Lorem ipsum dolor
              sit amet, consectetur adipisicing elit. Inventore sed consequuntur
              error repudiandae numquam deserunt quisquam repellat libero
              asperiores earum nam nobis, culpa ratione quam perferendis esse,
              cupiditate neque quas! Lorem ipsum dolor sit amet, consectetur
              adipisicing elit. Inventore sed consequuntur error repudiandae
              numquam deserunt quisquam repellat libero asperiores earum nam
              nobis, culpa ratione quam perferendis esse, cupiditate neque
            </p>
          </template>
        </Card>
      </div>
      <div class="card-size2 padding-10">
        <Card :style="{ fontFamily: selectedFont }">
          <template #header>
            <img
              alt="user header"
              class="image-size"
              src="https://fakeimg.pl/600x310"
            />
          </template>
          <template #title>Advanced Card</template>
          <template #content>
            <p class="m-0">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Inventore sed consequuntur error repudiandae numquam deserunt
              quisquam repellat libero asperiores earum nam nobis, culpa ratione
              quam perferendis esse, cupiditate neque quas!
            </p>
          </template>
          <template #footer>
            <div class="flex gap-3 mt-1">
              <Button label="更多" />
            </div>
          </template>
        </Card>
      </div>
    </div>
    <Image src="https://fakeimg.pl/1180x250" alt="Image" width="1180" />
  </div>
  <!-- 文章區塊 -->
  <div class="container-row-center margin-top-80">
    <div class="card-size3 padding-10">
      <Card :style="{ fontFamily: selectedFont }">
        <template #header>
          <img
            alt="user header"
            class="image-size"
            src="https://fakeimg.pl/400x250"
          />
        </template>
        <template #title>Advanced Card</template>
        <template #content>
          <p class="m-0">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore
            sed consequuntur error repudiandae numquam deserunt quisquam
            repellat libero asperiores earum nam nobis, culpa ratione quam
            perferendis esse, cupiditate neque quas!
          </p>
        </template>
        <template #footer>
          <div class="flex gap-3 mt-1">
            <Button label="更多" />
          </div>
        </template>
      </Card>
    </div>
    <div class="card-size3 padding-10">
      <Card :style="{ fontFamily: selectedFont }">
        <template #header>
          <img
            alt="user header"
            class="image-size"
            src="https://fakeimg.pl/400x250"
          />
        </template>
        <template #title>Advanced Card</template>
        <template #content>
          <p class="m-0">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore
            sed consequuntur error repudiandae numquam deserunt quisquam
            repellat libero asperiores earum nam nobis, culpa ratione quam
            perferendis esse, cupiditate neque quas!
          </p>
        </template>
        <template #footer>
          <div class="flex gap-3 mt-1">
            <Button label="更多" />
          </div>
        </template>
      </Card>
    </div>
    <div class="card-size3 padding-10">
      <Card :style="{ fontFamily: selectedFont }">
        <template #header>
          <img
            alt="user header"
            class="image-size"
            src="https://fakeimg.pl/400x250"
          />
        </template>
        <template #title>Advanced Card</template>
        <template #content>
          <p class="m-0">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore
            sed consequuntur error repudiandae numquam deserunt quisquam
            repellat libero asperiores earum nam nobis, culpa ratione quam
            perferendis esse, cupiditate neque quas!
          </p>
        </template>
        <template #footer>
          <div class="flex gap-3 mt-1">
            <Button label="更多" />
          </div>
        </template>
      </Card>
    </div>
  </div>
  <div class="container-row-center margin-top-80">
    <div class="card-size3 padding-10">
      <Card :style="{ fontFamily: selectedFont }">
        <template #header>
          <img
            alt="user header"
            class="image-size"
            src="https://fakeimg.pl/400x250"
          />
        </template>
        <template #title>Advanced Card</template>
        <template #content>
          <p class="m-0">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore
            sed consequuntur error repudiandae numquam deserunt quisquam
            repellat libero asperiores earum nam nobis, culpa ratione quam
            perferendis esse, cupiditate neque quas!
          </p>
        </template>
        <template #footer>
          <div class="flex gap-3 mt-1">
            <Button label="更多" />
          </div>
        </template>
      </Card>
    </div>
    <div class="card-size3 padding-10">
      <Card :style="{ fontFamily: selectedFont }">
        <template #header>
          <img
            alt="user header"
            class="image-size"
            src="https://fakeimg.pl/400x250"
          />
        </template>
        <template #title>Advanced Card</template>
        <template #content>
          <p class="m-0">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore
            sed consequuntur error repudiandae numquam deserunt quisquam
            repellat libero asperiores earum nam nobis, culpa ratione quam
            perferendis esse, cupiditate neque quas!
          </p>
        </template>
        <template #footer>
          <div class="flex gap-3 mt-1">
            <Button label="更多" />
          </div>
        </template>
      </Card>
    </div>
    <div class="card-size3 padding-10">
      <Card :style="{ fontFamily: selectedFont }">
        <template #header>
          <img
            alt="user header"
            class="image-size"
            src="https://fakeimg.pl/400x250"
          />
        </template>
        <template #title>Advanced Card</template>
        <template #content>
          <p class="m-0">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore
            sed consequuntur error repudiandae numquam deserunt quisquam
            repellat libero asperiores earum nam nobis, culpa ratione quam
            perferendis esse, cupiditate neque quas!
          </p>
        </template>
        <template #footer>
          <div class="flex gap-3 mt-1">
            <Button label="更多" />
          </div>
        </template>
      </Card>
    </div>
  </div>
  <!-- 頁底區塊 -->
  <div class="footer-container-row-center margin-top-80">
    <div class="card-size4 padding-10">
      <Image
        src="https://fakeimg.pl/600x410"
        alt="Image"
        width="600"
        height="410"
      />
    </div>
    <div class="card-size4 padding-10">
      <Card
        :style="{
          width: '100%',
          height: '400px',
          overflow: 'hidden',
          fontFamily: selectedFont,
        }"
      >
        <template #title>Advanced Card</template>
        <template #content>
          <p class="m-0">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Inventore
            sed consequuntur error repudiandae numquam deserunt quisquam
            repellat libero asperiores earum nam nobis, culpa ratione quam
            perferendis esse, cupiditate neque quas! Lorem ipㄇsum dolor sit
            amet, consectetur adipisicing elit. Inventore sed consequuntur error
            repudiandae numquam deserunt quisquam repellat libero asperiores
            earum nam nobis, culpa ratione quam perferendis esse, cupiditate
            neque quas!
          </p>
          <Button label="更多" severity="info" />
        </template>
      </Card>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import "@splidejs/vue-splide/css";
import Button from "primevue/button";
import Card from "primevue/card";
import Image from "primevue/image";

// 引入 PrimeVue 样式
import "primevue/resources/themes/saga-blue/theme.css"; // 主题
import "primevue/resources/primevue.min.css"; // 核心样式

import "@splidejs/vue-splide/css";
// 引入 Element Plus 菜单相关组件
import { ElMenu, ElMenuItem, ElSubMenu } from "element-plus";

import { onMounted, onBeforeUnmount } from "vue";

onMounted(() => {
  document.title = "最美河川";
  const metaDescription = document.createElement("meta");
  metaDescription.name = "最美河川";
  metaDescription.content = "五分港溪最美河川與你攜手保護環境";
  document.head.appendChild(metaDescription);

  // 存储 metaDescription 以便稍后移除
  return () => {
    document.head.removeChild(metaDescription);
  };
});

onBeforeUnmount(() => {
  // 移除 meta 标签的逻辑（如果有）
});

// 使用Composition API的响应式引用
const selectedFont = ref("华文黑体");

// Arial：无衬线字体，广泛支持，视觉效果清晰。
// Verdana：无衬线字体，易于阅读，特别是在小尺寸下。
// Tahoma：无衬线字体，类似于Verdana，但更紧凑。
// Georgia：衬线字体，适用于正文文本，给人以传统印刷体验。
// Times New Roman：衬线字体，经典的文本字体，常用于正式文档。
// Courier New：等宽字体，常用于显示代码或机打文稿。
// 宋体（SimSun）：中文衬线字体，Windows系统中常见。
// 微软雅黑（Microsoft YaHei）：中文无衬线字体，适合屏幕显示。
// 黑体（SimHei）：中文无衬线字体，常用于标题或强调文本。
// 楷体（KaiTi）：一种模仿楷书书写的中文衬线字体，常用于正式文档。
// 仿宋（FangSong）：一种模仿宋朝时期印刷品的中文衬线字体。
// 苹方（PingFang）：苹果操作系统中的默认中文无衬线字体，设计现代，适用于多种场合。
// 华文细黑（STHeiti Light [STXihei]）：Mac OS中常见的一种无衬线字体。
// 华文黑体（STHeiti）：另一种Mac OS中的无衬线字体，比华文细黑稍粗。

const username = ref(""); // 用户名的响应式变量

const images = ref([
  "https://fakeimg.pl/1200x400?text=Slide1",
  "https://fakeimg.pl/1200x400?text=Slide2",
  "https://fakeimg.pl/1200x400?text=Slide3",
]);

const splideOptions = ref({
  type: "loop",
  perPage: 1,
  pagination: true, // 启用导航点
  // 其他 Splide 配置选项
});
</script>
